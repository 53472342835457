import "./assets/Styles/style.css";
import "./assets/Styles/table.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "react-modern-drawer/dist/index.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import "react-clock/dist/Clock.css";
import React, { Suspense, lazy, useEffect, useLayoutEffect } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ProtectedRouter from "./Helper/ProtectedRoute";
import BeforeLoginRoute from "./Helper/BeforeLoginRoute";
import { Loader } from "./Component/Loader";
import MyProfile from "./pages/MyProfile";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { apiUrl, BaseURL } from "./config/apiUrl";
import { Beforeunload } from "react-beforeunload";
import Notifications from "./pages/Notifications";
import {
  saveChatCounter,
  saveNotiData,
  setAllNeighborhoods,
  setAllPackages,
  setAllProvinces,
  setAllTowns,
} from "./store/common/commonSlice";
import { Get } from "./Axios/AxiosFunctions";
import { updateUser } from "./store/auth/authSlice";
import CompaniesManagement from "./pages/CompaniesManagement";
import PropertiesManagement from "./pages/PropertiesManagement";
import ProjectsManagement from "./pages/ProjectsManagement";
import EventsManagement from "./pages/EventsManagement";
import BannersManagement from "./pages/BannersManagement";
import BankManagement from "./pages/BankManagement";
import BlogManagement from "./pages/BlogManagement";
import CreateBlogForm from "./pages/CreateBlogForm";
import AddCompany from "./pages/AddCompany";
import FAQS from "./pages/FAQS";
import BlogDetail from "./pages/BlogDetail";
import TypesCrud from "./pages/TypesCrud";
import AmenitiesCrud from "./pages/AmenitiesCrud";
import { useTranslation } from "react-i18next";
import {
  useLazyGetAllNeighborhoodsQuery,
  useLazyGetAllPackagesQuery,
  useLazyGetAllProvincesQuery,
  useLazyGetAllTownsQuery,
} from "./store/apiSlice/apiSlice";
import ProvincesCrud from "./pages/ProvincesCrud";
import TownsCrud from "./pages/TownsCrud";
import NeighborhoodsCrud from "./pages/NeighborhoodsCrud";
import MembershipManagement from "./pages/MembershipManagement";
import ForgotPassword from "./pages/ForgotPassword";
import OtpScreen from "./pages/OtpScreen";
import ResetPassword from "./pages/ResetPassword";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";

const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const NotFound = lazy(() => import("./pages/NotFound"));
const OurPartnerCRUD = lazy(() =>
  import("./pages/OurPartnerCRUD/OurPartnerCRUD")
);

export let socket = null;
function App() {
  const {
    user,
    isLogin,
    access_token: accessToken,
  } = useSelector((state) => state?.authReducer);
  const userDataState = useSelector((state) => state?.authReducer?.user);
  const c = useSelector((state) => state?.commonReducer);
  console.log({ c });

  const { ready, i18n } = useTranslation();
  const { selectedLanguage, direction } = useSelector(
    (state) => state?.languageReducer
  );

  const dispatch = useDispatch();

  const [
    getAllProvinces,
    {
      isLoading: isGettingProvinces,
      isFetching: isFetchProvinces,
      currentData: updatedProvinces,
    },
  ] = useLazyGetAllProvincesQuery();
  const [
    getAllTowns,
    {
      isLoading: isGettingTowns,
      isFetching: isFetchTowns,
      currentData: updatedTowns,
    },
  ] = useLazyGetAllTownsQuery();
  const [
    getAllNeighborhoods,
    {
      isLoading: isGettingNeighborhoods,
      isFetching: isFetchNeighborhoods,
      currentData: updatedNeighborhood,
    },
  ] = useLazyGetAllNeighborhoodsQuery();
  const [
    getAllPackages,
    {
      isLoading: isGettingPackages,
      isFetching: isFetchPackages,
      currentData: updatedPackages,
    },
  ] = useLazyGetAllPackagesQuery();

  const runTowns = isFetchTowns || isGettingTowns;
  const runProvinces = isFetchProvinces || isGettingProvinces;
  const runNeighborhoods = isGettingNeighborhoods || isFetchNeighborhoods;
  const runPackages = isGettingPackages || isFetchPackages;

  useEffect(() => {
    if (!runProvinces) {
      console.log("Update", runProvinces, updatedProvinces, "All");

      dispatch(setAllProvinces(updatedProvinces));
    }
  }, [runProvinces]);
  useEffect(() => {
    if (!runTowns) {
      dispatch(setAllTowns(updatedTowns));
    }
  }, [runTowns]);

  useEffect(() => {
    if (!runNeighborhoods) {
      dispatch(setAllNeighborhoods(updatedNeighborhood));
    }
  }, [runNeighborhoods]);
  useEffect(() => {
    if (!runPackages) {
      dispatch(setAllPackages(updatedPackages));
    }
  }, [runPackages]);

  const getData = async () => {
    const [allProvinces, allTowns, allNeighborhoods, allPackages] =
      await Promise.allSettled([
        getAllProvinces(),
        getAllTowns(),
        getAllNeighborhoods(),
        getAllPackages(),
      ]);
    if (allProvinces?.value?.data) {
      dispatch(setAllProvinces(allProvinces?.value?.data));
    }
    if (allTowns?.value?.data) {
      dispatch(setAllTowns(allTowns?.value?.data));
    }
    if (allNeighborhoods?.value?.data) {
      dispatch(setAllNeighborhoods(allNeighborhoods?.value?.data));
    }
    if (allPackages?.value?.data) {
      dispatch(setAllPackages(allPackages?.value?.data));
    }
  };

  const fetchNotificationsForCount = async () => {
    const url = BaseURL("notifications/all?page=1&limit=5&noCountUpdate=true");
    let response = await Get(url, accessToken);

    if (response !== undefined) {
      response?.data?.newNotifications > 0 &&
        dispatch(saveNotiData(response?.data?.newNotifications));
    }
  };

  const getMe = async () => {
    const url = BaseURL("users/me");
    const response = await Get(url, accessToken);

    if (response !== undefined) {
      const apiUser = response?.data?.data?.user;
      dispatch(updateUser(apiUser));
    }
  };

  useEffect(() => {
    if (isLogin) {
      socket = io(apiUrl, {
        transports: ["websocket"],
        reconnection: true,
        reconnectionDelay: 180000,
        reconnectionDelayMax: 300000,
      });
      socket?.emit("join", userDataState?._id);
      // fetchNotificationsForCount();
      // getMe();
      getData();
    }
  }, [isLogin]);

  useEffect(() => {
    socket?.on("new-notification", (notification, number) => {
      //something went wrong here
      if (
        notification?.receiver == userDataState?._id &&
        window.location.pathname !== "/notifications" &&
        notification?.flag == "project"
      ) {
        dispatch(saveNotiData());
      }
      //something went wrong here

      if (
        notification?.receiver == userDataState?._id &&
        notification?.flag == "chat" &&
        window.location.pathname !== "/chat"
      ) {
        dispatch(saveChatCounter(notification));
        dispatch(saveNotiData());
      }
    });
    return () => {
      socket?.off("new-notification");
    };
  }, []);

  // For Language
  useLayoutEffect(() => {
    // setShowPage(true);
    if (direction) {
      document.querySelector("body").dir = direction;
    }
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, [direction, selectedLanguage]);

  // For Language
  if (!ready) return <Loader className={"mvh-100"} />;

  return (
    <>
      <ToastContainer />
      <Beforeunload
        onBeforeunload={() => {
          if (isLogin) {
            socket?.emit("disconnected", user?._id);
          }
        }}
      />
      <BrowserRouter>
        <Suspense fallback={<Loader className={"mvh-100"} />}>
          <Routes>
            <Route
              path="/"
              exact
              element={<BeforeLoginRoute element={<Login />} />}
            />

            <Route
              path="/forgot-password"
              exact
              element={<BeforeLoginRoute element={<ForgotPassword />} />}
            />
            <Route
              path="/otp"
              exact
              element={<BeforeLoginRoute element={<OtpScreen />} />}
            />
            <Route
              path="/reset-password"
              exact
              element={<BeforeLoginRoute element={<ResetPassword />} />}
            />

            <Route
              path="/dashboard"
              exact
              element={<ProtectedRouter element={<Dashboard />} />}
            />

            <Route
              path="/profile-settings"
              exact
              element={<ProtectedRouter element={<MyProfile />} />}
            />
            <Route
              path="/companies-management"
              exact
              element={<ProtectedRouter element={<CompaniesManagement />} />}
            />
            <Route
              path="/companies-management/new-company"
              exact
              element={<ProtectedRouter element={<AddCompany />} />}
            />
            <Route
              path="/companies-management/edit-company/:slug"
              exact
              element={<ProtectedRouter element={<AddCompany />} />}
            />
            <Route
              path="/properties-management"
              exact
              element={<ProtectedRouter element={<PropertiesManagement />} />}
            />

            <Route
              path="/projects-management"
              exact
              element={<ProtectedRouter element={<ProjectsManagement />} />}
            />
            <Route
              path="/events-management"
              exact
              element={<ProtectedRouter element={<EventsManagement />} />}
            />
            <Route
              path="/banners-management"
              exact
              element={<ProtectedRouter element={<BannersManagement />} />}
            />
            <Route
              path="/banks-management"
              exact
              element={<ProtectedRouter element={<BankManagement />} />}
            />
            <Route
              path="/blog-management"
              exact
              element={<ProtectedRouter element={<BlogManagement />} />}
            />
            <Route
              path="/blog-management/create"
              exact
              element={<ProtectedRouter element={<CreateBlogForm />} />}
            />
            <Route
              path="/blog-management/edit/:slug"
              exact
              element={<ProtectedRouter element={<CreateBlogForm />} />}
            />
            <Route
              path="/blog-management/:slug"
              exact
              element={<ProtectedRouter element={<BlogDetail />} />}
            />
            <Route
              path="/membership-management"
              exact
              element={<ProtectedRouter element={<MembershipManagement />} />}
            />
            <Route
              path="/faqs"
              exact
              element={<ProtectedRouter element={<FAQS />} />}
            />
            <Route
              path="/types"
              exact
              element={<ProtectedRouter element={<TypesCrud />} />}
            />
            <Route
              path="/amenities"
              exact
              element={<ProtectedRouter element={<AmenitiesCrud />} />}
            />
            {/* Locations */}
            <Route
              path="/provinces"
              exact
              element={<ProtectedRouter element={<ProvincesCrud />} />}
            />
            <Route
              path="/towns"
              exact
              element={<ProtectedRouter element={<TownsCrud />} />}
            />
            <Route
              path="/neighborhoods"
              exact
              element={<ProtectedRouter element={<NeighborhoodsCrud />} />}
            />
            <Route
              path="/privacy-policy"
              exact
              element={<ProtectedRouter element={<PrivacyPolicy />} />}
            />
            <Route
              path="/terms-and-conditions"
              exact
              element={<ProtectedRouter element={<TermsAndConditions />} />}
            />

            <Route
              path="/our-partners"
              exact
              element={<ProtectedRouter element={<OurPartnerCRUD />} />}
            />
            {/* Locations */}
            <Route
              path="/notifications"
              exact
              element={<ProtectedRouter element={<Notifications />} />}
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
