/* eslint-disable */

import React, { useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./AddOrEditFAQModal.module.css";
import { Col, Row } from "react-bootstrap";
import { Input } from "../../Component/Input/Input";
import { Button } from "../../Component/Button/Button";
import { toast } from "react-toastify";
import TextArea from "../../Component/TextArea";
import { useAddOrEditFAQMutation } from "../../store/apiSlice/apiSlice";
import { languagesOptions } from "../../constant/commonData";
import FormLanguages from "../../Component/FormLanguages";
import { capitalizeFirstLetter, languagesKeysObject } from "../../config/apiUrl";

const AddOrEditFAQModal = ({ show, setShow, data }) => {
  const [question, setQuestion] = useState(
    data?.question ?? languagesKeysObject
  );
  const [answer, setAnswer] = useState(data?.answer ?? languagesKeysObject);
  const [language, setLanguage] = useState(languagesOptions[0]);

  const [addEditFAQ, { isLoading }] = useAddOrEditFAQMutation();

  function onClear() {
    setQuestion(data?.question);
    setAnswer(data?.answer);
  }

  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      modalClass={classes.main}
      borderRadius={"0px"}
      width={"1150px"}
      header={true}
      showCloseIcon={false}
      headerClass={classes.header}
    >
      <div className={classes.main}>
        <Row
          className={[
            classes.row,
            classes?.paddingContainer,
            classes?.previewContainer,
          ].join(" ")}
        >
          <Row className={`${classes?.row}`}>
            <Col md={12} className={classes.col1}>
              <div className={classes?.spBtw}>
                <h4>{data ? "Edit" : "Create"} FAQ</h4>
                <FormLanguages value={language} setter={setLanguage} />
              </div>
            </Col>
            <Col md={12} className={classes?.col1}>
              <Input
                variant="dashboard"
                label={"Question"}
                placeholder={"Enter Question"}
                value={question?.[language?.value]}
                setValue={(e) =>
                  setQuestion((prev) => ({ ...prev, [language?.value]: e }))
                }
                type={"text"}
              />
            </Col>
            <Col md={12} className={classes?.col1}>
              <TextArea
                variant="dashboard"
                label={"Answer"}
                placeholder={"Enter Answer"}
                value={answer?.[language?.value]}
                setValue={(e) =>
                  setAnswer((prev) => ({ ...prev, [language?.value]: e }))
                }
              />
            </Col>
          </Row>

          <div className={classes?.buttonsContainer}>
            <Button
              label={"Clear"}
              onClick={() => {
                onClear();
              }}
              disabled={isLoading}
            />
            <Button
              label={isLoading ? "Wait..." : data ? "Edit" : "Create"}
              onClick={async () => {
                const body = {
                  question,
                  answer,
                };
                for (let lang in languagesKeysObject) {
                  for (let key in body) {
                    if (!body[key]?.[lang]) {
                      return toast.error(
                        `Please fill the ${key} in language: ${capitalizeFirstLetter(lang)}`
                      );
                    }
                  }
                }

                const res = await addEditFAQ({
                  data: body,
                  ...(data ? { _id: data?._id } : {}),
                });
                if (res?.data) {
                  toast.success(
                    `FAQ ${data ? "edited" : "created"} successfully`
                  );
                  setShow(false);
                }
              }}
              disabled={isLoading}
            />
          </div>
        </Row>
      </div>
    </ModalSkeleton>
  );
};

export default AddOrEditFAQModal;

const bannerTypeOptions = [
  { label: "Vertical", value: "vertical" },
  { label: "Horizontal", value: "horizontal" },
];
