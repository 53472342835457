/* eslint-disable */

import React, { useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./AddOrEditNeighborhoodModal.module.css";
import { Col, Row } from "react-bootstrap";
import { Input } from "../../Component/Input/Input";
import { Button } from "../../Component/Button/Button";
import { toast } from "react-toastify";
import { useAddOrEditLocationWithTypeMutation } from "../../store/apiSlice/apiSlice";
import { languagesOptions } from "../../constant/commonData";
import FormLanguages from "../../Component/FormLanguages";
import { languagesKeysObject } from "../../config/apiUrl";
import { useSelector } from "react-redux";
import DropDown from "../../Component/DropDown/DropDown";

const AddOrEditNeighborhoodModal = ({ show, setShow, data }) => {
  const { provinces, towns } = useSelector((state) => state?.commonReducer);

  console.log({ provinces, towns });
  const [language, setLanguage] = useState(languagesOptions[0]);
  const [name, setName] = useState(data?.name ?? languagesKeysObject);
  const [province, setProvince] = useState(data?.ref ?? null);
  const [town, setTown] = useState(data?.ref?.ref ?? null);

  const [addEditNeighborhood, { isLoading }] =
    useAddOrEditLocationWithTypeMutation();

  function onClear() {
    setName(data?.name);
  }

  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      modalClass={classes.main}
      borderRadius={"0px"}
      width={"1100px"}
      header={true}
      showCloseIcon={false}
      headerClass={classes.header}
    >
      <div className={classes.main}>
        <Row
          className={[
            classes.row,
            classes?.paddingContainer,
            classes?.previewContainer,
          ].join(" ")}
        >
          <Row className={`${classes?.row}`}>
            <Col lg={6} className={classes.col1}>
              <h4>{data ? "Edit" : "Create"} Neighborhood</h4>
            </Col>
            <Col lg={6} className={["jEnd"].join(" ")}>
              <FormLanguages value={language} setter={setLanguage} />
            </Col>
            <Col md={12} className={classes?.col1}>
              <DropDown
                variant="dashboard"
                label={"Province"}
                placeholder={"Please Select Province"}
                options={provinces}
                value={province}
                optionValue={"_id"}
                getOptionLabel={(e) => {
                  return e?.name?.[language?.value];
                }}
                setValue={(e) => setProvince(e)}
              />
            </Col>
            <Col md={12} className={classes?.col1}>
              <DropDown
                variant="dashboard"
                label={"Town"}
                placeholder={"Please Select Town"}
                options={towns?.filter((e) => e?.ref?._id === province?._id)}
                value={town}
                optionValue={"_id"}
                getOptionLabel={(e) => {
                  return e?.name?.[language?.value];
                }}
                setValue={(e) => setTown(e)}
              />
            </Col>
            <Col md={12} className={classes?.col1}>
              <Input
                variant="dashboard"
                label={"Name"}
                placeholder={"Enter name"}
                value={name?.[language?.value]}
                setValue={(e) => setName({ ...name, [language?.value]: e })}
                type={"text"}
              />
            </Col>
          </Row>

          <div className={classes?.buttonsContainer}>
            <Button
              label={"Clear"}
              onClick={() => {
                onClear();
              }}
              disabled={isLoading}
            />
            <Button
              label={isLoading ? "Wait..." : data ? "Edit" : "Create"}
              onClick={async () => {
                let body = {
                  name,
                  type: "neighborhood",
                };
                if (!province) {
                  return toast.error(`Please select province`);
                }
                if (!town) {
                  return toast.error(`Please select town`);
                }
                for (let key in languagesKeysObject) {
                  body = {
                    ...body,
                    name: { ...body.name, [key]: body.name?.[key]?.trim() },
                  };
                  if (!body?.name[key]) {
                    return toast.error(
                      `Please fill the Name field in language "${key}"`
                    );
                  }
                }
                const res = await addEditNeighborhood({
                  data: {
                    ...body,
                    ...(data
                      ? {}
                      : {
                          ref: town?._id,
                        }),
                  },
                  type: "Neighborhood",
                  ...(data ? { id: data?._id } : {}),
                });
                if (res?.data) {
                  toast.success(
                    `Neighborhood ${data ? "edited" : "created"} successfully`
                  );
                  setShow(false);
                }
              }}
              disabled={isLoading}
            />
          </div>
        </Row>
      </div>
    </ModalSkeleton>
  );
};

export default AddOrEditNeighborhoodModal;
