import React from "react";
import { BiSearch } from "react-icons/bi";
import { Input } from "../Input/Input";

function SearchInput({
  value,
  setter,
  placeholder = "Search",
  customStyle = {
    height: "48px",
    border: "none",
    borderRadius: "8px",
    width: "280px",
    padding: "0px",
  },
  inputStyle = {
    padding: "8px 14px",
    fontSize: "14px",
  },
}) {
  return (
    <Input
      setValue={setter}
      value={value}
      customStyle={customStyle}
      inputStyle={inputStyle}
      placeholder={placeholder}
      leftIcon={<BiSearch size={22} color={"var(--text-black-clr)"} />}
      onEnterClick={() => {}}
    />
  );
}

export default SearchInput;
