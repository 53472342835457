import React from "react";
import classes from "./CustomPhoneInput.module.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CustomPhoneInput = ({
  //  label = "Contact",
  value,
  setter,
  placeholder = "Phone",
  disabled,
  label,
  inputClass='',
  labelClass='',
  variant=''
}) => {
  return (
    <>
      <style>{`
    .react-tel-input .flag-dropdown{
      border:none !important;
    }
   
    `}</style>
      <div className={classes.phoneMain} data-variant={variant}>
        {label && (
          <label
            className={[classes.phoneLabel,labelClass, disabled && classes.labelDisabled].join(' ')}
          >
            {label}
          </label>
        )}
        <PhoneInput
          inputClass={[classes.phoneInput,inputClass].join(' ')}
          containerClass={[classes.phoneInputContainer]}
          placeholder={placeholder}
          enableSearch={true}
          value={value}
          onChange={(phone) => {
            setter(phone);
          }}
          disabled={disabled}
          inputStyle={{
            ...(disabled && { background: "var(--disabled-input-clr)" }),
          }}
        />
      </div>
    </>
  );
};

export default CustomPhoneInput;
