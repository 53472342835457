import React from "react";
import classes from "./BlogCard.module.css";
import { Button } from "../Button/Button";
import { BiSolidEdit } from "react-icons/bi";
import { BlogImg } from "../../constant/imagePath";
import { imageUrl } from "../../config/apiUrl";
import Parser from "html-react-parser";
import moment from "moment";
import { useSelector } from "react-redux";

function BlogCard({
  data = {
    title: "Blog Title",
    description:
      "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.",
    createdAt: "10/02/2023",
    images: [BlogImg],
  },
  onEdit = () => {},
  onView = () => {},
}) {
  const { selectedLanguage } = useSelector((state) => state.languageReducer);

  return (
    <div className={classes.card}>
      <div className={classes.imgDiv}>
        <img src={imageUrl(data?.image)} alt="blog" />
        <div className={classes.editIcon} onClick={onEdit}>
          <BiSolidEdit color="var(--primary-clr)" size={20} />
        </div>
      </div>
      <div className={classes.content}>
        <h6>{data?.title?.[selectedLanguage]}</h6>
        <p>{Parser(data?.description?.[selectedLanguage] ??'<p></p>')}</p>
        <div className={classes.dateAndBtn}>
          <span>{moment(data?.createdAt).format('DD MMM YYYY hh:mm A')}</span>
          <Button onClick={onView}>View</Button>
        </div>
      </div>
    </div>
  );
}

export default BlogCard;
