import React, { useState } from "react";
import Style from "./AfterLoginHeader.module.css";
import { Container, OverlayTrigger } from "react-bootstrap";
import HeaderNotification from "../HeaderNotification/HeaderNotification";
import { useDispatch, useSelector } from "react-redux";
import { imageUrl } from "../../config/apiUrl";
import { Logo, bannerAd } from "../../constant/imagePath";
import { IoNotifications } from "react-icons/io5";
import { languagesOptions } from "../../constant/commonData";
import DropDown from "../DropDown/DropDown";
import {
  setDirection,
  setSelectLanguage,
} from "../../store/language/languageSlice";
import { components } from "react-select";
import { useTranslation } from "react-i18next";

export const AfterLoginHeader = ({ className }) => {
  const dispatch = useDispatch();
  const [showNotificationOverlay, setShowNotificationOverlay] = useState(false);
  const { user } = useSelector((state) => state?.authReducer);
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState(
    languagesOptions?.find((e) => e?.value == selectedLanguage)
  );

  const Option = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        <span className="t-t-c">
          <img
            src={props?.options?.find((e) => e?.value == props?.value)?.flag}
            width={30}
            height={20}
            style={{ marginRight: "5px" }}
            alt={"flag"}
          />{" "}
          {props?.options?.find((e) => e?.value == props?.value)?.value}
        </span>
      </components.Option>
    );
  };
  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue className="d-flex" {...props}>
        <span className="t-t-c">
          <img
            src={language?.flag}
            width={30}
            height={20}
            style={{ marginRight: "5px" }}
            alt={"flag"}
          />{" "}
          {language.value}
        </span>
      </components.SingleValue>
    );
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    dispatch(setSelectLanguage(lng));
    if (["ar", "fa"].includes(lng)) {
      dispatch(setDirection("rtl"));

      // document.body.dir = i18n.dir();
    } else {
      dispatch(setDirection("ltr"));

      // document.body.dir = i18n.dir("ltr");
    }
  };

  return (
    <Container className={`${[Style.navbarContainer, className].join(" ")}`}>
      <div className={Style.aCenter}>
        <div className={Style.logoDiv}>
          {/* <img src={Logo} alt="..." /> */}
          <h3>Demo</h3>
        </div>

        <DropDown
          value={languagesOptions?.find((e) => e?.value == selectedLanguage)}
          setValue={(e) => {
            setLanguage(e);
            changeLanguage(e?.value);
          }}
          options={languagesOptions}
          optionValue={"value"}
          placeholder={""}
          Components={{
            Option,
            //  Control
            SingleValue,
          }}
          customStyle={{ minWidth: 80 }}
        />
      </div>
      <div className={Style.aCenter}>
        {/* <div className={Style.notiIcon}>
          <MdEmail color={"var(--d8d-clr)"} size="30px" />
        </div> */}

        <div className={[Style.iconDiv].join(" ")}>
          <OverlayTrigger
            placement={"bottom"}
            show={showNotificationOverlay}
            trigger={["click"]}
            rootClose={"true"}
            overlay={
              <div className={[Style.notifyoverlayDiv]}>
                <HeaderNotification />
              </div>
            }
            onToggle={() =>
              setShowNotificationOverlay(!showNotificationOverlay)
            }
          >
            <div className={Style.notiIcon}>
              <IoNotifications color={"var(--d8d-clr)"} size="30px" />
            </div>
          </OverlayTrigger>
        </div>

        <div className={[Style.profileImg]}>
          <img
            src={`${imageUrl(user?.photo)}`}
            alt="..."
            onError={(e) => {
              e.target.onError = null;
              // e.target.src = bannerAd;
            }}
          />
        </div>
      </div>
    </Container>
  );
};
