/* eslint-disable */

import React, { useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./AddOrEditProvinceModal.module.css";
import { Col, Row } from "react-bootstrap";
import { Input } from "../../Component/Input/Input";
import { Button } from "../../Component/Button/Button";
import { toast } from "react-toastify";
import { useAddOrEditLocationWithTypeMutation } from "../../store/apiSlice/apiSlice";
import { languagesOptions } from "../../constant/commonData";
import FormLanguages from "../../Component/FormLanguages";
import {
  languagesKeysObject,
} from "../../config/apiUrl";

const AddOrEditProvinceModal = ({ show, setShow, data, type = "" }) => {
  const [language, setLanguage] = useState(languagesOptions[0]);
  const [name, setName] = useState(data?.name ?? languagesKeysObject);

  const [addEditProvince, { isLoading }] = useAddOrEditLocationWithTypeMutation();

  function onClear() {
    setName(data?.name);
  }

  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      modalClass={classes.main}
      borderRadius={"0px"}
      width={"1100px"}
      header={true}
      showCloseIcon={false}
      headerClass={classes.header}
    >
      <div className={classes.main}>
        <Row
          className={[
            classes.row,
            classes?.paddingContainer,
            classes?.previewContainer,
          ].join(" ")}
        >
          <Row className={`${classes?.row}`}>
            <Col lg={6} className={classes.col1}>
              <h4>{data ? "Edit" : "Create"} Province</h4>
            </Col>
            <Col lg={6} className={["jEnd"].join(" ")}>
              <FormLanguages value={language} setter={setLanguage} />
            </Col>
            <Col md={12} className={classes?.col1}>
              <Input
                variant="dashboard"
                label={"Name"}
                placeholder={"Enter name"}
                value={name?.[language?.value]}
                setValue={(e) => setName({ ...name, [language?.value]: e })}
                type={"text"}
              />
            </Col>
          </Row>

          <div className={classes?.buttonsContainer}>
            <Button
              label={"Clear"}
              onClick={() => {
                onClear();
              }}
              disabled={isLoading}
            />
            <Button
              label={isLoading ? "Wait..." : data ? "Edit" : "Create"}
              onClick={async () => {
                let body = {
                  name,
                  type: "province",
                };
                for (let key in languagesKeysObject) {
                  body = {
                    ...body,
                    name: { ...body.name, [key]: body.name?.[key]?.trim() },
                  };
                  if (!body?.name[key]) {
                    return toast.error(
                      `Please fill the Name field in language "${key}"`
                    );
                  }
                }
                const res = await addEditProvince({
                  data: body,
                  type: 'Province',
                  ...(data ? { id: data?._id } : {}),
                });
                if (res?.data) {
                  toast.success(
                    `Province ${data ? "edited" : "created"} successfully`
                  );
                  setShow(false);
                }
              }}
              disabled={isLoading}
            />
          </div>
        </Row>
      </div>
    </ModalSkeleton>
  );
};

export default AddOrEditProvinceModal;

const bannerTypeOptions = [
  { label: "Vertical", value: "vertical" },
  { label: "Horizontal", value: "horizontal" },
];
