import { languagesOptions } from "../constant/commonData";
// export const apiUrl = "https://turegu-backend-2f2f64e039d0.herokuapp.com";
export const apiUrl = "https://turegu-backend-dem-21eae6875e8a.herokuapp.com";
// export const apiUrl = "https://43fe2e53e0ec1987d94bb10167023409.serveo.net";
// export const apiUrl = 'https://a278-39-51-68-32.ngrok-free.app';
// export const apiUrl = 'https://bce0aef79ae4fa600267235652b3a480.serveo.net';

export const google_places_key = "AIzaSyB30wsHtA_DHmPf0LG_gnPZn-fmJeZE9v0";

export const webLiveURL = "https://terugu-web.vercel.app/";

export const socketURL = `${apiUrl}`;
export const stripe_public_key = `pk_test_51L8FqNL51LXb45sopLMv0wOiEZvg9NhshZWh0vWC7Okl7Alm2n01MGG89jdvovL41Fw98xNs1I0VqIn29KCD9JQc00acfYgJBk`;

export const imageUrl = (url) => `${apiUrl}/api/images/${url}`;
export const locationHelper = window.location.origin;

export const BaseURL = (link) => {
  return `${apiUrl}/api/v1/${link}`;
};

export const apiHeader = (token, isFormData) => {
  if (token && !isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "true",
      },
    };
  }
  if (token && isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
  }
  if (!token && !isFormData) {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  if (!token && isFormData) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const CreateFormData = (data) => {
  const formData = new FormData();
  for (let key in data) {
    if (Array.isArray(data[key])) {
      for (let d in data[key]) {
        if (typeof data[key][d] == "string") {
          formData.append(key, data[key][d]);
        } else if (
          data[key][d] instanceof File ||
          data[key][d] instanceof Date
        ) {
          formData.append(key, data[key][d]);
        } else {
          formData.append(key, JSON.stringify(data[key][d]));
        }
      }
    } else if (typeof data[key] == "object") {
      if (data[key] instanceof File) {
        formData.append(key, data[key]);
      } else {
        formData.append(key, JSON.stringify(data[key]));
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  return formData;
};

export const firebaseVapidObject = {
  vapidKey:
    "BKlmZ2T6iSDR1mOAaQOzBEbwHdlYX71OqZOwvvja_oDliCf9eRrb8Rgo_dasRs9WCpgSppH0O0kvtIOc959bO0U",
};
export const capitalizeFirstLetter = (l) =>
  l.charAt(0).toUpperCase() + l.slice(1);

export const formRegEx = /([a-z])([A-Z])/g;
export const formRegExReplacer = "$1 $2";

export var recordsLimit = 10;

export const convertArrayKeysToObject = (array) => {
  let languageDict = {};

  for (let i = 0; i < array.length; i++) {
    languageDict[array[i]] = "";
  }

  return languageDict;
};

export const languagesKeysObject = convertArrayKeysToObject(
  languagesOptions?.map((e) => e?.value)
);
