/* eslint-disable */

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import classes from "./CreateBlogForm.module.css";
import { IoChevronBackOutline } from "react-icons/io5";
import QuillInput from "../../Component/QuillInput";
import FileUpload from "../../Component/FileUpload";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../../Component/Input/Input";
import { Button } from "../../Component/Button/Button";
import {
  useAddOrEditBlogMutation,
  useLazyGetSingleBlogQuery,
} from "../../store/apiSlice/apiSlice";
import { CreateFormData, languagesKeysObject } from "../../config/apiUrl";
import { toast } from "react-toastify";
import UploadImageBox from "../../Component/UploadImageBox";
import { Loader } from "../../Component/Loader";
import { languagesOptions } from "../../constant/commonData";
import FormLanguages from "../../Component/FormLanguages";
import { useSelector } from "react-redux";

function CreateBlogForm() {
  const [language, setLanguage] = useState(languagesOptions[0]);
  const slug = useParams()?.slug;
  const navigate = useNavigate();
  const [getData, { isLoading: isGetting }] = useLazyGetSingleBlogQuery();
  const [title, setTitle] = useState(languagesKeysObject);
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState(languagesKeysObject);
  const [addEditBlog, { isLoading }] = useAddOrEditBlogMutation();

  const handleGetData = async () => {
    console.log('calling...')
    const res = await getData({slug});
    if (res?.data) {
      setTitle(res?.data?.data?.title);
      setDescription(res?.data?.data?.description);
      setImage(res?.data?.data?.image);
    }
  };

  useEffect(() => {
    if (slug) {
      handleGetData();
    }
  }, [isGetting]);

  async function blogApi() {
    const body = {
      image: image,
      title,
      description,
    };
    for (let key in body) {
      if (!body[key]) {
        return toast.error("Please fill all the fields");
      }
    }

    for (let key in languagesKeysObject) {
      if (title?.[key]?.length < 60) {
        return toast.error(
          `Title of language ${key} must be at least 60 characters`
        );
      }
      if (title?.[key]?.length > 80) {
        return toast.error(
          `Title of language ${key} must be at most 80 characters`
        );
      }
      if (description?.[key]?.length < 500) {
        return toast.error(
          `Please enter the description more than 500 characters of language ${key}`
        );
      }
    }

    const formData = await CreateFormData(body);
    const res = await addEditBlog({
      data: formData,
      ...(slug ? { slug: slug } : {}),
    });
    if (res?.data) {
      toast.success(`Blog ${slug ? "edited" : "created"} successfully`);
      navigate("/blog-management");
    }
  }

  console.log({ description });

  return (
    <SideBarSkeleton>
      <Container fluid className={classes.page}>
        {isGetting ? (
          <Loader className={classes?.loader} />
        ) : (
          <Row className={['ar', 'fa']?.includes(language?.value) ? 'rtl' : 'ltr'} >
            <Col md={6} className={classes.mb30}>
              <Button
                leftIcon={<IoChevronBackOutline />}
                variant=""
                className={classes.backBtn}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back to Blog Management
              </Button>
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-end align-items-start"
            >
              <Button
                className={classes.btn}
                onClick={blogApi}
                disabled={isLoading}
              >
                {isLoading
                  ? "Please Wait..."
                  : `${slug ? "Update" : "Publish"} Blog`}
              </Button>
            </Col>
            <Col md={12}>
              <div className={classes.formBg}>
                <Container>
                  <Row className={classes.formRow}>
                    <Col md={12} className={classes?.spBtw}>
                      <h3>New Blog</h3>
                      <FormLanguages value={language} setter={setLanguage} />
                    </Col>
                    <Col md={12}>
                      <h4>Information</h4>
                    </Col>
                    <Col md={12}>
                      <Input
                        label={"Blog Title*"}
                        placeholder={"Writer Blog Title (60-80 Characters)"}
                        variant="dashboard"
                        value={title?.[language?.value]}
                        setValue={(e) => {
                          setTitle((prev) => {
                            return { ...prev, [language?.value]: e };
                          });
                        }}
                      />
                    </Col>
                    <Col md={12}>
                      {/* <FileUpload
                      maxFiles={1}
                      label={"Image"}
                      files={image}
                      setFiles={setImage}
                    /> */}
                      <UploadImageBox
                        maxFiles={1}
                        label={"Image"}
                        value={image}
                        setValue={setImage}
                      />
                    </Col>
                    <Col md={12}>
                      <QuillInput
                        label={"Description"}
                        value={description?.[language?.value]}
                        setter={(e) => {
                          setDescription((prev) => ({
                            ...prev,
                            [language?.value]: e,
                          }));
                        }}
                        placeholder="Write your blog here..."
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </SideBarSkeleton>
  );
}

export default CreateBlogForm;
