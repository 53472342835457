import { createApi } from "@reduxjs/toolkit/query/react";
import { apiHeader, BaseURL, recordsLimit } from "../../config/apiUrl";
import axios from "axios";
import { toast } from "react-toastify";

const axiosBaseQuery = async ({
  baseUrl,
  accessToken,
  url,
  method = "GET",
  data,
}) => {
 

  try {
    const customHeaders = apiHeader(accessToken);
    return await axios({
      url: baseUrl + url,
      method,
      data,
      ...customHeaders,
    });
    
  } catch (axiosError) {
    let errorMessage = axiosError?.response?.data?.message;
    let errorObj;
    if (axiosError?.message === "Network Error") {
      toast.error(
        `${axiosError?.message} : Please Check Your Network Connection`,
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      errorObj = {
        status: 403,
        data: "Newtwork Error",
      };
    } else {
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_CENTER,
      });
      errorObj = {
        status: axiosError.response?.data?.status || axiosError?.code,
        data: errorMessage ?? axiosError?.message,
      };
    }

    return {
      error: errorObj,
    };
  }
};

export const apiSlice = createApi({
  reducerPath: "apiSlice",

  baseQuery: async (e, { getState }) =>
    await axiosBaseQuery({
      baseUrl: BaseURL(""),
      accessToken: getState()?.authReducer?.accessToken,
      ...e,
    }),

  // baseQuery: fetchBaseQuery({
  //   baseUrl: BaseURL(""),
  //   prepareHeaders: (headers, { getState }) => {
  //     const token = getState()?.authReducer?.access_token;
  //     if (token) {
  //       headers.set("authorization", `Bearer ${token}`);
  //     }
  //     return headers;
  //   },
  // }),
  // refetchOnFocus: true,
  // refetchOnReconnect: true,
  // refetchOnMountOrArgChange: true,
  // keepUnusedDataFor: 2,
  tagTypes: [
    "Company",
    "Banks",
    "Property",
    "Project",
    "Events",
    "FAQ",
    "Blog",
    "Types",
    "Amenities",
    // Location,
    "Province",
    "Town",
    "Neighborhood",
    "Package",
    "OurPartners",
  ],
  endpoints: (builder) => {
    return {
      //Forgot Password API
      forgotPassword: builder.mutation({
        query: ({ email }) => ({
          url: `users/forgotPassword`,
          method: "POST",
          data: { email },
        }),
      }),
      // Verify OTP API
      verifyOTP: builder.mutation({
        query: ({ email, otp }) => ({
          url: `users/verify-user-otp`,
          method: "POST",
          data: { otpCode: otp, email, fromForgotPassword: true },
        }),
      }),
      // Reset Password API
      resetPassword: builder.mutation({
        query: ({ email, password, confirmPassword }) => ({
          url: `users/resetPassword`,
          method: "POST",
          data: {
            email,
            password: password,
            confirmPassword: confirmPassword,
            fromWeb: true,
          },
        }),
      }),

      // Login API
      login: builder.mutation({
        query: (params) => ({
          url: `users/admin-login`,
          method: "POST",
          data: params,
        }),
      }),
      //   get all cats
      getDashboardStats: builder.query({
        query: () => ({
          url: `admin/stats`,
        }),
      }),

      // addOrEditCats
      addOrEditCats: builder.mutation({
        query: ({ slug, data }) => {
          console.log("data", data);
          return {
            url: slug ? `category/${slug}` : `category`,
            method: slug ? "PATCH" : "POST",
            data,
          };
        },
        invalidatesTags: ["Cats"],
      }),

      // *******************************************************Shaam*******************************************************
      // get all banks
      getAllBanks: builder.query({
        query: ({
          page,
          status = "-1",
          limit = recordsLimit,
          search,
          lan = "",
        }) => ({
          url: `bank/all?page=${page}&limit=${limit}&status=${status}&search=${search}&lan=${lan}`,
        }),
        providesTags: ["Banks"],
      }),
      // add or edit bank
      addOrEditBank: builder.mutation({
        query: ({ _id, data }) => {
          console.log(data, "123 312");
          return {
            url: _id ? `bank/${_id}` : `bank/create`,
            method: _id ? "PATCH" : "POST",
            data,
          };
        },
        invalidatesTags: ["Banks"],
      }),
      // delete banks
      deleteBank: builder.mutation({
        query: (ids) => ({
          url: `bank`,
          method: "DELETE",
          data: { banks: ids },
        }),
        invalidatesTags: ["Banks"],
      }),

      // *******************************************************Bila lal*******************************************************
      // post and patch banner
      addOrEditBanner: builder.mutation({
        query: ({ _id, data }) => ({
          url: _id ? `banner/${_id}` : `banner/create`,
          method: _id ? "PATCH" : "POST",
          data,
        }),
        invalidatesTags: ["Banner"],
      }),

      // get all banner
      getAllBanner: builder.query({
        query: ({ page, search, limit = recordsLimit }) => {
          return {
            url: `banner/admin/all?page=${page}&limit=${limit}&search=${search}`,
          };
        },
        providesTags: ["Banner"],
      }),
      // delete banner
      deleteBanners: builder.mutation({
        query: (ids) => ({
          url: `banner`,
          data: { banners: ids },
          method: "DELETE",
        }),
        invalidatesTags: ["Banner"],
      }),

      // *******************************************************Ramiz*******************************************************
      // Company CRUD
      getAllCompanies: builder.query({
        query: ({ page, limit, search, sortBy, lan = "" }) => ({
          url: `admin/getAllCompanies?page=${page}&limit=${limit}&sortBy=${sortBy}&search=${search}&lan=${lan}`,
        }),
        providesTags: ["Company"],
      }),
      deleteCompanies: builder.mutation({
        query: (ids) => ({
          url: `admin/deleteCompanies`,
          data: { ids },
          method: "DELETE",
        }),
        invalidatesTags: ["Company"],
      }),
      // editCompany: builder.mutation({
      //   query: ({slug,data}) => ({
      //     url: `admin/`,
      //   }),
      //   invalidatesTags: ["Company"],
      // }),
      getCompanyAgents: builder.query({
        query: ({ slug }) => ({
          url: `admin/company/agents/${slug}`,
        }),
        invalidatesTags: ["Company"],
      }),
      addCreditCoins: builder.mutation({
        query: ({ slug, data }) => ({
          url: `admin/credit/${slug}`,
          data: data,
          method: "PATCH",
        }),
        invalidatesTags: ["Company"],
      }),
      upgradeCompanyPackage: builder.mutation({
        query: ({ slug, data }) => ({
          url: `admin/update/package/${slug}`,
          data: data,
          method: "PATCH",
        }),
        invalidatesTags: ["Company"],
      }),
      // Company CRUD

      // Properties
      getAllProperties: builder.query({
        query: ({ page, limit, search, sortBy, lan }) => ({
          url: `admin/property/all?page=${page}&limit=${limit}&sortBy=${sortBy}&search=${search}&lan=${lan}`,
        }),
        providesTags: ["Property"],
      }),
      deleteProperties: builder.mutation({
        query: (ids) => ({
          url: `admin/property/deleteProperties`,
          data: { ids },
          method: "DELETE",
        }),
        invalidatesTags: ["Property"],
      }),

      displayPropertyOnHomePage: builder.mutation({
        query: ({ slug, status }) => ({
          url: `admin/property/displayOnHome/${slug}`,
          data: { status },
          method: "PATCH",
        }),
        invalidatesTags: ["Property"],
      }),
      activeDeactiveProperty: builder.mutation({
        query: ({ slug, status }) => ({
          url: `admin/property/active-deactivate/${slug}`,
          data: { status },
          method: "PATCH",
        }),
        invalidatesTags: ["Property"],
      }),
      refreshProperty: builder.mutation({
        query: ({ slug }) => ({
          url: `admin/property/refresh/${slug}`,
          data: {},
          method: "PATCH",
        }),
        invalidatesTags: ["Property"],
      }),
      // Properties

      // Projects
      getAllProjects: builder.query({
        query: ({ page, limit, search, sortBy, lan = "" }) => ({
          url: `admin/project/all?page=${page}&limit=${limit}&sortBy=${sortBy}&search=${search}&lan=${lan}`,
        }),
        providesTags: ["Project"],
      }),
      deleteProjects: builder.mutation({
        query: (ids) => ({
          url: `admin/project/deleteProjects`,
          data: { ids },
          method: "DELETE",
        }),
        invalidatesTags: ["Project"],
      }),
      activeDeactiveProject: builder.mutation({
        query: ({ slug, status }) => ({
          url: `admin/project/active-deactivate/${slug}`,
          data: { status },
          method: "PATCH",
        }),
        invalidatesTags: ["Project"],
      }),
      refreshProject: builder.mutation({
        query: ({ slug }) => ({
          url: `admin/project/refresh/${slug}`,
          data: {},
          method: "PATCH",
        }),
        invalidatesTags: ["Project"],
      }),

      displayProjectOnHomePage: builder.mutation({
        query: ({ slug, status }) => ({
          url: `admin/project/displayOnHome/${slug}`,
          data: { status },
          method: "PATCH",
        }),
        invalidatesTags: ["Project"],
      }),
      // Projects

      // Events
      getAllEvents: builder.query({
        query: ({ page, limit, search, sortBy, lan = "" }) => ({
          url: `admin/event/all?page=${page}&limit=${limit}&sortBy=${sortBy}&search=${search}&lan=${lan}`,
        }),
        providesTags: ["Events"],
      }),
      deleteEvents: builder.mutation({
        query: (ids) => ({
          url: `admin/event/deleteEvents`,
          data: { ids },
          method: "DELETE",
        }),
        invalidatesTags: ["Events"],
      }),
      activeDeactiveEvent: builder.mutation({
        query: ({ slug, status }) => ({
          url: `admin/event/active-deactivate/${slug}`,
          data: { status },
          method: "PATCH",
        }),
        invalidatesTags: ["Events"],
      }),
      refreshEvent: builder.mutation({
        query: ({ slug }) => ({
          url: `admin/event/refresh/${slug}`,
          data: {},
          method: "PATCH",
        }),
        invalidatesTags: ["Events"],
      }),
      // Events

      // FAQS
      getAllFaqs: builder.query({
        query: ({ page, limit, search, sortBy, lan = "" }) => ({
          url: `faqs?page=${page}&limit=${limit}&sortBy=${sortBy}&search=${search}&lan=${lan}`,
        }),
        providesTags: ["FAQ"],
      }),
      deleteFaqs: builder.mutation({
        query: (ids) => ({
          url: `faqs/deleteFaqs`,
          data: { ids },
          method: "DELETE",
        }),
        invalidatesTags: ["FAQ"],
      }),
      // add or edit FAQ
      addOrEditFAQ: builder.mutation({
        query: ({ _id, data }) => {
          return {
            url: _id ? `faqs/${_id}` : `faqs`,
            method: _id ? "PATCH" : "POST",
            data,
          };
        },
        invalidatesTags: ["FAQ"],
      }),
      activeDeactiveFaq: builder.mutation({
        query: ({ _id, status }) => ({
          url: `faqs/activate-deactivate/${_id}`,
          data: { status },
          method: "PATCH",
        }),
        invalidatesTags: ["FAQ"],
      }),
      // FAQS

      // Blogs
      getAllBlogs: builder.query({
        query: ({ page, limit, search, lan }) => ({
          url: `blog/admin/all?page=${page}&limit=${limit}&search=${search}&lan=${lan}`,
        }),
        providesTags: ["Blog"],
      }),
      getSingleBlog: builder.query({
        query: ({ slug }) => ({
          url: `blog/${slug}`,
        }),
      }),
      deleteBlogs: builder.mutation({
        query: (ids) => ({
          url: `blog`,
          data: { blogs: ids },
          method: "DELETE",
        }),
        invalidatesTags: ["Blog"],
      }),
      // add or edit Blog
      addOrEditBlog: builder.mutation({
        query: ({ slug, data }) => {
          return {
            url: slug ? `blog/${slug}` : `blog/create`,
            method: slug ? "PATCH" : "POST",
            data,
          };
        },
        invalidatesTags: ["Blog"],
      }),

      // Blogs

      // Types CRUD
      getAllTypes: builder.query({
        query: ({ page, limit, search, type, lan = "" }) => ({
          url: `${type}/admin/all?page=${page}&limit=${limit}&search=${search}&lan=${lan}`,
        }),
        providesTags: ["Types"],
      }),
      deleteTypes: builder.mutation({
        query: ({ type, ids }) => ({
          url: `${type}/delete`,
          data: { ids },
          method: "DELETE",
        }),
        invalidatesTags: ["Types"],
      }),
      // add or edit Types
      addOrEditType: builder.mutation({
        query: ({ slug, data, type }) => {
          return {
            url: slug ? `${type}/${slug}` : `${type}/create`,
            method: slug ? "PATCH" : "POST",
            data,
          };
        },
        invalidatesTags: ["Types"],
      }),
      activeDeactiveType: builder.mutation({
        query: ({ slug, status, type }) => ({
          url: `${type}/activeDeactivate/${slug}`,
          data: { status },
          method: "PATCH",
        }),
        invalidatesTags: ["Types"],
      }),
      // Types CRUD

      // Amenities CRUD
      getAllAmenities: builder.query({
        query: ({ page, limit, search, type, lan }) => {
          return {
            url: `amenities/admin/all?page=${page}&limit=${limit}&search=${search}&type=${type}&lan=${lan}`,
          };
        },
        providesTags: ["Amenities"],
      }),
      deleteAmenities: builder.mutation({
        query: ({ ids }) => ({
          url: `amenities/delete`,
          data: { ids },
          method: "DELETE",
        }),
        invalidatesTags: ["Amenities"],
      }),
      // add or edit Amenity
      addOrEditAmenity: builder.mutation({
        query: ({ slug, data }) => {
          return {
            url: slug ? `amenities/${slug}` : `amenities/create`,
            method: slug ? "PATCH" : "POST",
            data,
          };
        },
        invalidatesTags: ["Amenities"],
      }),
      activeDeactiveAmenity: builder.mutation({
        query: ({ slug, status }) => ({
          url: `amenities/activeDeactivate/${slug}`,
          data: { status },
          method: "PATCH",
        }),
        invalidatesTags: ["Amenities"],
      }),
      // Amenities CRUD

      // Location CRUD
      getAllProvinces: builder.query({
        query: (query) => {
          const searchParams = new URLSearchParams(query)?.toString();
          return {
            url: `location/admin/all?type=province${
              query ? "&" + searchParams : ""
            }`,
          };
        },
        providesTags: ["Province"],
      }),
      // add or edit Province, Town, Neighborhood
      addOrEditLocationWithType: builder.mutation({
        query: ({ id, data }) => {
          return {
            url: id
              ? `location/${id}?type=${data?.type?.toLowerCase()}`
              : `location`,
            method: id ? "PATCH" : "POST",
            data,
          };
        },
        invalidatesTags: (res, err, arg) => [arg?.type],
      }),
      // delete Province, Town, Neighborhood

      deleteLocationWithType: builder.mutation({
        query: ({ ids, type }) => {
          return {
            url: `location?type=${type?.toLowerCase()}`,
            method: "DELETE",
            data: { ids },
          };
        },
        invalidatesTags: (res, err, arg) => [arg?.type],
      }),

      activeDeactiveLocationWithType: builder.mutation({
        query: ({ id, status, type }) => ({
          url: `location/active-deactivate/${id}?type=${type?.toLowerCase()}`,
          data: { status, type: type?.toLowerCase() },
          method: "PATCH",
        }),
        invalidatesTags: (res, err, arg) => [arg?.type],
      }),

      getAllOurPartners: builder.query({
        query: (query) => {
          const searchParams = new URLSearchParams(query)?.toString();
          return {
            url: `partner/getAllPartners?${query ? searchParams : ""}`,
          };
        },
        providesTags: ["OurPartners"],
      }),

      getAllTowns: builder.query({
        query: (query) => {
          const searchParams = new URLSearchParams(query)?.toString();
          return {
            url: `location/admin/all?type=town${
              query ? "&" + searchParams : ""
            }`,
          };
        },
        providesTags: ["Town"],
      }),

      getAllNeighborhoods: builder.query({
        query: (query) => {
          const searchParams = new URLSearchParams(query)?.toString();
          return {
            url: `location/admin/all?type=neighborhood${
              query ? "&" + searchParams : ""
            }`,
          };
        },

        providesTags: ["Neighborhood"],
      }),
      // create uploadLocationCSV mutation for form data
      uploadLocationCSV: builder.mutation({
        query: (formData) => ({
          // url: `location/csv-upload`,
          url: `location/file-upload`,
          data: formData,
          method: "POST",
          formData: true,
        }),
      }),
      getLocationCSVByType: builder.query({
        query: ({ type = "" }) => ({
          url: `location/download/csv?type=${type}`,
        }),
      }),
      // Location CRUD

      //Packages CRUD
      getAllPackages: builder.query({
        query: (query) => {
          const searchParams = new URLSearchParams(query)?.toString();
          return {
            url: `package/all${query ? "?" + searchParams : ""}`,
          };
        },
        providesTags: ["Package"],
      }),
      updatePackage: builder.mutation({
        query: ({ id, data }) => {
          return {
            url: `package/${id}`,
            method: "PATCH",
            data,
          };
        },
        invalidatesTags: ["Package"],
      }),
      //Packages CRUD

      // Create Company
      addOrEditCompany: builder.mutation({
        query: ({ data, slug }) => ({
          url: slug ? `admin/updateCompany/${slug}` : `admin/createCompany`,
          method: slug ? "PATCH" : "POST",
          data,
        }),
        invalidatesTags: ["Company"],
      }),
      getSingleCompany: builder.query({
        query: ({ slug }) => ({
          url: `admin/getCompany/${slug}`,
        }),
      }),

      addOrEditOurPartner: builder.mutation({
        query: ({ data, id }) => ({
          url: id ? `partner/${id}` : `partner/create`,
          method: id ? "PATCH" : "POST",
          data,
        }),
        invalidatesTags: ["OurPartners"],
      }),

      deleteAllOurPartners: builder.mutation({
        query: ({ ids, type }) => {
          return {
            url: `partner/delete`,
            method: "DELETE",
            data: { ids },
          };
        },
        invalidatesTags: ["OurPartners"],
      }),

      // Create Company
    };
  },
});

export const {
  useAddOrEditOurPartnerMutation,
  useDeleteAllOurPartnersMutation,
  useGetAllOurPartnersQuery,

  // Mutation Sample
  useLoginMutation,
  useGetDashboardStatsQuery,
  // *******************************************************Shaam*******************************************************
  // Mutation
  useAddOrEditBankMutation,
  useDeleteBankMutation,

  // Get
  useGetAllBanksQuery,

  // *******************************************************Bila lal*******************************************************
  // Mutation
  // Get
  useGetAllBannerQuery,
  useAddOrEditBannerMutation,
  useDeleteBannersMutation,

  // *******************************************************Ramiz*******************************************************
  // Mutation
  // Get
  useGetAllCompaniesQuery,
  useDeleteCompaniesMutation,
  useAddCreditCoinsMutation,
  useUpgradeCompanyPackageMutation,
  useGetCompanyAgentsQuery,

  // Properties API
  useGetAllPropertiesQuery,
  useDeletePropertiesMutation,
  useActiveDeactivePropertyMutation,
  useRefreshPropertyMutation,
  useDisplayPropertyOnHomePageMutation,
  // Properties API
  // Projects API
  useGetAllProjectsQuery,
  useDeleteProjectsMutation,
  useActiveDeactiveProjectMutation,
  useRefreshProjectMutation,
  useDisplayProjectOnHomePageMutation,

  // Projects API
  // Events API
  useGetAllEventsQuery,
  useDeleteEventsMutation,
  useActiveDeactiveEventMutation,
  useRefreshEventMutation,
  // Events API
  // FAQS API
  useGetAllFaqsQuery,
  useDeleteFaqsMutation,
  useAddOrEditFAQMutation,
  useActiveDeactiveFaqMutation,
  // FAQS API

  // Blogs API
  useGetAllBlogsQuery,
  useDeleteBlogsMutation,
  useAddOrEditBlogMutation,
  useGetSingleBlogQuery,
  useLazyGetSingleBlogQuery,
  // Blogs API

  // Type CRUD
  useGetAllTypesQuery,
  useAddOrEditTypeMutation,
  useActiveDeactiveTypeMutation,
  useDeleteTypesMutation,
  // Type CRUD
  // Type CRUD
  useGetAllAmenitiesQuery,
  useAddOrEditAmenityMutation,
  useActiveDeactiveAmenityMutation,
  useDeleteAmenitiesMutation,

  // Location
  useGetAllProvincesQuery,
  useLazyGetAllProvincesQuery,
  useGetAllTownsQuery,
  useLazyGetAllTownsQuery,
  useGetAllNeighborhoodsQuery,
  useLazyGetAllNeighborhoodsQuery,
  useAddOrEditLocationWithTypeMutation,
  useDeleteLocationWithTypeMutation,
  useActiveDeactiveLocationWithTypeMutation,
  useUploadLocationCSVMutation,
  useLazyGetLocationCSVByTypeQuery,

  // Location

  // Type CRUD
  // Packages
  useLazyGetAllPackagesQuery,
  useUpdatePackageMutation,
  // Packages

  // Create Company
  useAddOrEditCompanyMutation,
  useLazyGetSingleCompanyQuery,
  // Create Company

  useForgotPasswordMutation,
  useVerifyOTPMutation,
  useResetPasswordMutation,
} = apiSlice;
