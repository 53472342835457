/* eslint-disable */

import React, { useState } from "react";
import classes from "./BankManagement.module.css";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import CustomMaterialTable from "../../Component/CustomMaterialTable";
import { AiFillEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { Button } from "../../Component/Button/Button";
import SearchInput from "../../Component/SearchInput";
import { useNavigate } from "react-router-dom";
import DropDown from "../../Component/DropDown/DropDown";
import { dateFilterOptions } from "../../constant/commonData";
import BankCreationModal from "../../modals/BankCreationModal/BankCreationModal";
import BankViewModal from "../../modals/BankViewModal/BankViewModal";
import {
  useDeleteBankMutation,
  useGetAllBanksQuery,
} from "../../store/apiSlice/apiSlice";
import { recordsLimit } from "../../config/apiUrl";
import moment from "moment";
import { toast } from "react-toastify";
import useDebounce from "../../CustomHooks/useDebounce";
import { useSelector } from "react-redux";

const tableData = Array(10)
  .fill({
    id: "5645",
    date: "2020.11.12",
    bankName: "Industrial And Commercial Bank Of XYZ Limited",
  })
  ?.map((item) => ({ ...item, id: Math.floor(Math.random() * 10000) }));

function BankManagement() {
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState("");

  const [dateFilter, setDateFilter] = useState(dateFilterOptions[0]);
  const headings = [
    {
      id: "bankName",
      label: "BANK NAME",
      renderValue: (item, col) => item[col?.id]?.[selectedLanguage],
    },
    {
      id: "createdAt",
      label: "CREATION DATE",
      renderValue: (item, col) => {
        return moment(item[col?.id]).format("DD/MM/YYYY hh:mm A");
      },
    },
    { id: "options", label: "OPTIONS", align: "center" },
  ];

  const debounceSearch = useDebounce(searchText, 500);

  const { data, isLoading, isFetching } = useGetAllBanksQuery({
    page,
    status: dateFilter?.value,
    limit: recordsLimit,
    search: debounceSearch,
    lan: selectedLanguage,
  });

  const [deleteBanks, { isLoading: isDeleting }] = useDeleteBankMutation();

  const deleteMultiple = async () => {
    const res = await deleteBanks(selectedRows?.map((item) => item._id));
    if (res?.data) {
      toast.success(
        `${selectedRows?.length > 1 ? "Banks" : "Bank"} deleted successfully`
      );
      setSelectedRows([]);
    }
  };

  return (
    <SideBarSkeleton>
      <Container fluid className={classes.page}>
        <Row>
          <Col md={12} className={classes.mb58}>
            <div className={classes.header}>
              <div className={classes.left}>
                <SearchInput
                  placeholder="Search by name"
                  setter={setSearchText}
                  value={searchText}
                  variant="dashboard"
                  containerStyle={{ width: "300px" }}
                />
                <DropDown
                  label={"Sort By Date"}
                  containerClassName={classes.dropDownContainer}
                  customStyle={{
                    padding: "0px 6px",
                    minHeight: "40px",
                    minWidth: "200px",
                  }}
                  options={dateFilterOptions}
                  setValue={(e) => {
                    setDateFilter(e);
                  }}
                  value={dateFilter}
                  placeholder={"Select Date"}
                  // isClearable={true}
                />
              </div>
              <div className={classes.headerRight}>
                <Button
                  borderRadius={"8px"}
                  className={classes.deleteBtn}
                  disabled={selectedRows?.length == 0}
                  onClick={deleteMultiple}
                >
                  {isDeleting ? "Deleting..." : "Delete"}
                </Button>
                <Button
                  borderRadius={"8px"}
                  variant="primary"
                  onClick={() => {
                    setSelectedItem(null);
                    setIsModalOpen("create-edit");
                  }}
                >
                  Create Bank
                </Button>
              </div>
            </div>
          </Col>
          <Col md={12}>
            <h5 className={classes.heading}>BANKS</h5>
          </Col>
          <Col md={12}>
            <CustomMaterialTable
              headings={headings}
              data={data?.data}
              noDataText="No Bank Found"
              showOptionsColumn
              isLoading={isLoading || isFetching}
              options={[
                {
                  label: "View",
                  icon: <AiFillEye size={18} />,
                  onClick: (_, e) => {
                    setSelectedItem(e);
                    setIsModalOpen("view");
                  },
                },
                {
                  label: "Edit",
                  icon: <FiEdit size={18} />,
                  onClick: (_, e) => {
                    setSelectedItem(e);
                    setIsModalOpen("create-edit");
                  },
                },
              ]}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              allowMultipleSelection
              totalRecords={Math.ceil(data?.totalRecords / recordsLimit)}
              setPage={setPage}
              page={page}
            />
          </Col>
        </Row>
      </Container>

      {isModalOpen == "create-edit" && (
        <BankCreationModal
          show={isModalOpen == "create-edit"}
          setShow={() => setIsModalOpen("")}
          data={selectedItem}
        />
      )}

      {isModalOpen == "view" && (
        <BankViewModal
          show={isModalOpen == "view"}
          setShow={() => setIsModalOpen("")}
          data={selectedItem}
        />
      )}
    </SideBarSkeleton>
  );
}

export default BankManagement;
