/* eslint-disable */

import React, { useEffect, useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./BankCreationModal.module.css";
import { Col, Row } from "react-bootstrap";
import { Input } from "../../Component/Input/Input";
import { Button } from "../../Component/Button/Button";
import { ProfileWithEditButton } from "../../Component/ProfileWithEditButton";
import { toast } from "react-toastify";
import { useAddOrEditBankMutation } from "../../store/apiSlice/apiSlice";
import {
  CreateFormData,
  capitalizeFirstLetter,
  formRegEx,
  formRegExReplacer,
  languagesKeysObject,
} from "../../config/apiUrl";
import validator from "validator";
import { languagesOptions } from "../../constant/commonData";
import FormLanguages from "../../Component/FormLanguages";
import { useSelector } from "react-redux";

const BankCreationModal = ({ show, setShow, data }) => {
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const [language, setLanguage] = useState(languagesOptions[0]);
  const [bankImage, setBankImage] = useState(data?.image ?? null);
  const [bankName, setBankName] = useState(
    data?.bankName ?? languagesKeysObject
  );
  const [interestRate, setInterestRate] = useState(data?.interestRate ?? 0);
  const [financeAmountPercentage, setFinanceAmountPercentage] = useState(
    data?.financialAmountPercentage ?? 0
  );
  const [maximumAmount, setMaximumAmount] = useState(data?.maximumAmount ?? 0);
  const [maximumDuration, setMaximumDuration] = useState(
    data?.maximumDuration ?? 0
  );
  const [downPayment, setDownPayment] = useState(data?.downPayment ?? 0);
  const [finalPayment, setFinalPayment] = useState(data?.finalPayment ?? 0);
  const [bankInformationLink, setBankInformationLink] = useState(
    data?.bankInformationLink ?? ""
  );
  const [addEditBank, { isLoading }] = useAddOrEditBankMutation();

  useEffect(() => {
    if (data) {
      setBankImage(data?.image);
      setBankName(data?.bankName);
      setInterestRate(data?.interestRate);
      setFinanceAmountPercentage(data?.financialAmountPercentage);
      setMaximumAmount(data?.maximumAmount);
      setMaximumDuration(data?.maximumDuration);
      setDownPayment(data?.downPayment);
      setFinalPayment(data?.finalPayment);
      setBankInformationLink(data?.bankInformationLink);
    }
  }, [data]);

  function onClear() {
    setBankImage(data?.image);
    setBankName(data?.bankName);
    setInterestRate(data?.interestRate);
    setFinanceAmountPercentage(data?.financialAmountPercentage);
    setMaximumAmount(data?.maximumAmount);
    setMaximumDuration(data?.maximumDuration);
    setDownPayment(data?.downPayment);
    setFinalPayment(data?.finalPayment);
    setBankInformationLink(data?.bankInformationLink);
  }
  console.log({ bankName });
  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      modalClass={classes.main}
      borderRadius={"0px"}
      width={"1150px"}
      header={true}
      showCloseIcon={false}
      headerClass={classes.header}
    >
      <div className={classes.main}>
        <Row
          className={[
            classes.row,
            classes?.paddingContainer,
            classes?.previewContainer,
            ["ar", "fa"]?.includes(language?.value) ? "rtl" : "ltr",
          ].join(" ")}
        >
          <Row>
            <Col md={12} className={classes.col1}>
              <div className={classes?.spBtw}>
                <h4>BANK CREATION</h4>
                <FormLanguages setter={setLanguage} value={language} />
              </div>
            </Col>
            <Col md={12}>
              <div className={classes?.spBtw}>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                </p>
                <ProfileWithEditButton
                  updateImage={bankImage}
                  setUpdateImage={setBankImage}
                  isEdit={true}
                  className={classes?.profile}
                />
              </div>
            </Col>
          </Row>

          <Row className={`${classes?.row}`}>
            <Col md={12} className={classes?.col1}>
              <Input
                variant="dashboard"
                label={"Bank Name"}
                placeholder={"Enter Bank Name"}
                value={bankName?.[language.value]}
                setValue={(e) =>
                  setBankName((prev) => ({ ...prev, [language.value]: e }))
                }
                type={"text"}
              />
            </Col>
            <Col md={4} className={classes?.col1}>
              <Input
                variant="dashboard"
                label={"Interest Rate"}
                placeholder={"Enter Interest Rate"}
                value={interestRate}
                setValue={setInterestRate}
                type={"number"}
              />
            </Col>
            <Col md={4} className={classes?.col1}>
              <Input
                variant="dashboard"
                label={"Finance Amount Percentage"}
                placeholder={"Enter Finance Amount Percentage"}
                value={financeAmountPercentage}
                setValue={(e) => {
                  if (Number(e) > 100) {
                    return toast.error(
                      "Percentage should be less or equal 100"
                    );
                  }
                  setFinanceAmountPercentage(e);
                }}
                type={"number"}
              />
            </Col>
            <Col md={4} className={classes?.col1}>
              <Input
                variant="dashboard"
                label={"Maximum Amount"}
                placeholder={"Enter Maximum Amount"}
                value={maximumAmount}
                setValue={setMaximumAmount}
                type={"number"}
              />
            </Col>
            {/* // ! Unit ?? */}
            <Col md={4} className={classes?.col1}>
              <Input
                variant="dashboard"
                label={"Maximum Duration"}
                placeholder={"Enter Maximum Duration"}
                value={maximumDuration}
                setValue={setMaximumDuration}
                type={"number"}
              />
            </Col>
            <Col md={4} className={classes?.col1}>
              <Input
                variant="dashboard"
                label={"Down Payment"}
                placeholder={"Enter Down Payment"}
                value={downPayment}
                setValue={setDownPayment}
                type={"number"}
              />
            </Col>
            <Col md={4} className={classes?.col1}>
              <Input
                variant="dashboard"
                label={"Final Payment"}
                placeholder={"Enter Final Payment"}
                value={finalPayment}
                setValue={setFinalPayment}
                type={"number"}
              />
            </Col>
            <Col md={12} className={classes?.col1}>
              <Input
                variant="dashboard"
                label={"Bank Information Link"}
                placeholder={"Enter Bank Information Link"}
                value={bankInformationLink}
                setValue={setBankInformationLink}
                type={"url"}
              />
            </Col>
          </Row>

          <div className={classes?.buttonsContainer}>
            <Button
              label={"Clear"}
              onClick={() => {
                onClear();
              }}
              disabled={isLoading}
            />
            <Button
              label={isLoading ? "Wait..." : data ? "Edit" : "Create"}
              onClick={async () => {
                for (let key in languagesKeysObject) {
                  if (!bankName?.[key]) {
                    return toast.error(
                      `Please fill the Bank Name in language ${capitalizeFirstLetter(
                        key
                      )} field`
                    );
                  }
                }

                let body = {
                  image: bankImage,
                  interestRate: Number(interestRate),
                  financialAmountPercentage: Number(financeAmountPercentage),
                  maximumAmount: Number(maximumAmount),
                  maximumDuration: Number(maximumDuration),
                  downPayment: Number(downPayment),
                  finalPayment: Number(finalPayment),
                  bankInformationLink,
                };
                console.log({ body });

                for (let key in body) {
                  if (body[key] === 0) {
                    return toast.error(
                      `${capitalizeFirstLetter(
                        key?.replace(formRegEx, formRegExReplacer)
                      )} must be greater than zero`
                    );
                  }
                  if (!body[key]) {
                    return toast.error("Please fill all the fields");
                  }
                }
                if (!validator.isURL(bankInformationLink)) {
                  return toast.error(
                    "Please enter valid Bank Information Link"
                  );
                }
                body = {
                  ...body,
                  bankName,
                };
                const formData = await CreateFormData(body);
                const res = await addEditBank({
                  data: formData,
                  ...(data ? { _id: data?._id } : {}),
                });
                if (res?.data) {
                  toast.success(
                    `Bank ${data ? "edited" : "created"} successfully`
                  );
                  setShow(false);
                }
              }}
              disabled={isLoading}
            />
          </div>
        </Row>
      </div>
    </ModalSkeleton>
  );
};

export default BankCreationModal;

const bannerTypeOptions = [
  { label: "Vertical", value: "vertical" },
  { label: "Horizontal", value: "horizontal" },
];
