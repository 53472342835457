/* eslint-disable */
import React, { useState, useEffect } from "react";
import classes from "./BlogDetail.module.css";
import { Col, Container, Row } from "react-bootstrap";
// import HeadingWithUnderline from "../../Component/HeadingWithUnderline";
// import { blogsData } from "../../constant/commonData";
// import Tabs from "../../Component/Tabs";
import { BaseURL, imageUrl } from "../../config/apiUrl";
import { BsCalendarCheck } from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import moment from "moment";
// import { SocialIcon } from "react-social-icons";
import { useNavigate, useParams } from "react-router-dom";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { useGetSingleBlogQuery } from "../../store/apiSlice/apiSlice";
import Parser from "html-react-parser";
import { Loader } from "../../Component/Loader";
import { useSelector } from "react-redux";

const BlogTitleCard = ({ data }) => {
  return (
    <div className={classes.blogTitleCard}>
      <div className={classes.blogTitleCardImage}>
        <img src={data.image} alt="blog" />
      </div>
      <div className={classes.blogTitleCardText}>
        <p>{data.title}</p>
      </div>
    </div>
  );
};

const BlogDetail = () => {
  const navigate = useNavigate();
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const slug = useParams()?.slug;

  const { data: singleData, isLoading: isGetting } = useGetSingleBlogQuery(
    { slug },
    { skip: !slug }
  );

  const blogData = singleData?.data;
  useEffect(() => {
    if (!slug) {
      navigate("/blog-management");
    }
  }, []);


  return (
    <SideBarSkeleton>
      <div className={classes.page}>
        {isGetting ? (
          <Loader className={classes?.loader} />
        ) : (
          <Container className="">
            <Row className={[classes.row].join(" ")}>
              {/* Featured Prop */}
              <Col md={12}>
                <div className={classes.sectionHeading}>
                  <h2>{blogData?.title?.[selectedLanguage]}</h2>
                </div>
              </Col>
              <Col md={12}>
                <div className={classes?.detailMainContainer}>
                  <div className={classes?.imageContainer}>
                    <img src={imageUrl(blogData?.image) ?? ""} />
                  </div>
                  <div className={classes?.detailContainer}>
                    <p>{Parser(blogData?.description?.[selectedLanguage])}</p>
                  </div>
                  <div className={classes?.bottomContainer}>
                    <div />
                    {/* <div className={classes?.socialIconContainer}>
                    <p>Share:</p>
                    <SocialIcon
                      url="https://twitter.com"
                      className={classes?.socialIcon}
                    />
                    <SocialIcon
                      url="https://facebook.com"
                      className={classes?.socialIcon}
                    />
                    <SocialIcon
                      url="https://instagram.com"
                      className={classes?.socialIcon}
                    />
                    <SocialIcon
                      url="https://web.whatsapp.com"
                      className={classes?.socialIcon}
                    />
                    <SocialIcon
                      url="https://youtube.com"
                      className={classes?.socialIcon}
                    />
                  </div> */}
                    <div className={classes?.authorContainer}>
                      <FaUserAlt fill="#cccccc" size={20} />
                      <BsCalendarCheck fill="#cccccc" size={20} />
                      <p>{moment(blogData?.createdAt).format("DDMMM,YYYY")}</p>
                    </div>
                  </div>
                </div>
              </Col>
              {/* <Col md={4}>
              <div className={classes?.tabsWrapper}>
                <Tabs
                  tabsData={[{ id: 1, text: "Popular Posts" }]}
                  value={{ id: 1, text: "Popular Posts" }}
                  setValue={() => {}}
                  className={classes?.tabsContainer}
                  scrollOffset={150}
                />
              </div>

              <div className={classes?.itemsContainer}>
                {popularBlogs?.slice(0, 3).map((item, index) => (
                  <BlogTitleCard key={index} data={item} />
                ))}
              </div>
              <div className={classes?.tabsWrapper}>
                <Tabs
                  tabsData={[{ id: 1, text: "Latest Posts" }]}
                  value={{ id: 1, text: "Latest Posts" }}
                  setValue={() => {}}
                  className={classes?.tabsContainer}
                  scrollOffset={150}
                />
              </div>
              <div className={classes?.itemsContainer}>
                {latestBlogs?.slice(0, 3).map((item, index) => (
                  <BlogTitleCard key={index} data={item} />
                ))}
              </div>
            </Col> */}
            </Row>
          </Container>
        )}
      </div>
    </SideBarSkeleton>
  );
};

export default BlogDetail;
