import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allBrokers: [],
  allCategories: [],
  noti_counter: 0,
  chat_counter: 0,
  provinces: [],
  towns: [],
  neighborhoods: [],
  packages:[]
};

const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    setAllCommon(state, action) {
      state.allBrokers = action.payload?.allBrokers;
      state.allCategories = action.payload?.allCategories;
    },

    saveNotiData(state, action) {
      state.noti_counter += action?.payload || 1;
    },
    clearNotiData(state, action) {
      state.noti_counter = 0;
      state.noti_data = [];
    },
    saveChatCounter(state, action) {
      state.chat_counter += 1;
    },
    clearChatCounter(state, action) {
      state.chat_counter = 0;
    },
    setAllProvinces(state, action) {
      state.provinces = action?.payload?.data;
    },
    setAllTowns(state, action) {
      state.towns = action?.payload?.data;
    },
    setAllNeighborhoods(state, action) {
      state.neighborhoods = action?.payload?.data;
    },
    setAllPackages(state, action) {
      state.packages = action?.payload?.data;
    },
  },
});

export const {
  setAllCommon,
  saveNotiData,
  saveChatCounter,
  clearChatCounter,
  clearNotiData,
  setAllProvinces,
  setAllTowns,
  setAllNeighborhoods,
  setAllPackages
} = commonSlice.actions;

export default commonSlice.reducer;
