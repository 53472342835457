import React, { useState } from "react";
import classes from "./ForgotPassword.module.css";
import { Container, Row, Col } from "react-bootstrap";
import { Logo } from "../../constant/imagePath";
import { resetIcon } from "../../constant/imagePath";
import { Input } from "../../Component/Input/Input";
import { Button } from "../../Component/Button/Button";
import {  useNavigate } from "react-router-dom";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { validateEmail } from "../../config/apiUrl";
import { toast } from "react-toastify";
import { useForgotPasswordMutation } from "../../store/apiSlice/apiSlice";
import { IoArrowBackSharp } from "react-icons/io5";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [submit, { isLoading }] = useForgotPasswordMutation();

  const submitHandler = async () => {
    if (!email) return toast.error("Please fill the Email field");
    if (!validateEmail(email))
      return toast.error("Please provide a valid email");

    const response = await submit({ email });
    if (response?.data) {
      toast.success(
        "Forgot password request has been sent successfully, Kindly check your email for the OTP"
      );
      navigate("/otp", { state: { email: email } });
    }
  };

  return (
    <div className={classes.resetPassword}>
      <div className={classes.header}>
        <Container>
          <Row>
            <Col>
              <div className={classes.imgWrapper}>
                {/* <img src={Logo} /> */}
                <h3>Demo</h3>
              </div>
            </Col>
            {/* <Col>
              <div className={classes.language}>
                <DropDown value={dropdown} setter={setDropdown} options={options} isSearchable={false} placeholder={'Select Language'}/>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
      <div className={classes.formWrapper}>
        <div className={classes.form}>
          <Button
            leftIcon={<IoArrowBackSharp />}
            variant=""
            onClick={() => navigate("/")}
            className={classes.backBtn}
          >
            {" "}
            Back to Login
          </Button>
          <div className={classes.iconWrapper}>
            <img src={resetIcon} />
          </div>
          <>
            <div className={classes.content}>
              <h5> Forgot Password </h5>
              <p className={"muted"}>
                Enter your email address to reset your password
              </p>
            </div>
            <div className={classes.inputWrapper}>
              <Input
                value={email}
                setValue={setEmail}
                leftIcon={<BsFillEnvelopeFill color={"var(--icon-color)"} />}
                placeholder={"Email Address"}
                type={"email"}
              />
              <Button
                onClick={submitHandler}
                className={classes.btn}
                disabled={isLoading}
              >
                {isLoading ? "Wait..." : "Submit"}
              </Button>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
