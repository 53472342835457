/* eslint-disable */

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { Button } from "../../Component/Button/Button";
import DropDown from "../../Component/DropDown/DropDown";
import { Input } from "../../Component/Input/Input";
import UploadImageBox from "../../Component/UploadImageBox";
import { BaseURL, imageUrl } from "../../config/apiUrl";
import { useAddOrEditBannerMutation } from "../../store/apiSlice/apiSlice";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./AdBannerModal.module.css";

const pageNameList = [
  {
    label: "Home",
    pathName: "home",
  },
  {
    label: "Buy",
    pathName: "buy",
  },
  {
    label: "Buy Detail",
    pathName: "buy-detail",
  },
  {
    label: "Rent",
    pathName: "rent",
  },
  {
    label: "Rent Detail",
    pathName: "rent-detail",
  },
  {
    label: "Projects",
    pathName: "projects",
  },
  {
    label: "Project Detail",
    pathName: "project-detail",
  },
  {
    label: "Events",
    pathName: "events",
  },
  {
    label: "Event Detail",
    pathName: "event-detail",
  },
  {
    label: "Agents",
    pathName: "agents",
  },
  {
    label: "Agent Detail",
    pathName: "agent-detail",
  },
  {
    label: "Companies",
    pathName: "companies",
  },
  {
    label: "Company Detail",
    pathName: "company-detail",
  },
  {
    label: "Blogs",
    pathName: "blogs",
  },
  {
    label: "Blog Detail",
    pathName: "blog-detail",
  },
];

const BANNER_TYPE_OPTIONS = [
  { label: "Vertical", value: "vertical" },
  { label: "Horizontal", value: "horizontal" },
];

let isFirstRendered = false;

const AdBannerModal = ({ show, setShow, editData, bannerLimits = {} }) => {
  const [bannerImage, setBannerImage] = useState(editData?.image ?? null);
  const [bannerName, setBannerName] = useState("" || editData?.name);
  const [pageName, setPageName] = useState(
    pageNameList?.find((item) => item?.pathName == editData?.page) ?? null
  );
  const [bannerType, setBannerType] = useState(null);

  const [bannerTypeOptions, setBannerTypeOptions] = useState([]);

  // change pageName when crud is done from backend
  // ! location on pages is irrelevant
  const [startDateTime, setStartDateTime] = useState(
    moment(editData?.startDate).format("YYYY-MM-DD")
  );
  const [endDateTime, setEndDateTime] = useState(
    moment(editData?.endDate).format("YYYY-MM-DD")
  );
  // ! vertical, horizontal
  const [isPreviewEnabled, setIsPreviewEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addEditFunc, result] = useAddOrEditBannerMutation();

  const filteredBannerType = () => {
    let _bannerTypeOptions = [];

    const obj = bannerLimits[pageName?.pathName];

    if (obj?.verticalAllowed) {
      _bannerTypeOptions.push(BANNER_TYPE_OPTIONS[0]);
    }

    if (obj?.horizontalAllowed) {
      _bannerTypeOptions.push(BANNER_TYPE_OPTIONS[1]);
    }

    setBannerTypeOptions(_bannerTypeOptions);
    setBannerType(
      _bannerTypeOptions.find((item) => item?.value == editData?.bannerType) ||
        null
    );
  };

  const handleSubmit = async () => {
    const apiUrl = BaseURL("banner/create");
    const formData = new FormData();
    const body = {
      name: bannerName,
      page: pageName?.pathName,
      startDate: startDateTime,
      endDate: endDateTime,
      bannerType: bannerType?.value,
      image: bannerImage,
    };
    for (let key in body) {
      if (body[key] == "" || body[key] == null) {
        return toast.error("Please fill all the fields");
      }
      formData.append(key, body[key]);
    }
    setIsLoading(true);

    const response = await addEditFunc({
      url: apiUrl,
      ...(editData && { _id: editData?._id }),
      data: formData,
    });

    // const response = await Post(apiUrl, formData, accessToken);
    if (response?.data) {
      toast.success(`Banner ${editData ? "edited" : "created"} successfully`);
      setShow(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (bannerImage instanceof File) {
      setIsPreviewEnabled(true);
    }
  }, [bannerImage]);

  useEffect(() => {
    if (isFirstRendered) {
      filteredBannerType();
    } else {
      isFirstRendered = true;
    }
  }, [pageName]);

  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      modalClass={classes.main}
      borderRadius={"0px"}
      width={"1150px"}
      header={true}
      showCloseIcon={false}
      headerClass={classes.header}
    >
      <div className={classes.main}>
        {/* Preview */}
        {isPreviewEnabled && (
          <Row
            className={[
              classes.row,
              classes?.paddingContainer,
              classes?.previewContainer,
            ].join(" ")}
          >
            <h4>BANNER PREVIEW</h4>
            <p>Your Banner will appear like this...</p>
            <div
              className={`${classes?.horizontalImg} ${
                bannerType?.value === "vertical" && classes?.verticalBanner
              }`}
            >
              <img
                src={
                  typeof bannerImage == "string"
                    ? imageUrl(bannerImage)
                    : URL.createObjectURL(bannerImage)
                }
              />
            </div>
            <div className={classes?.buttonsContainer}>
              <Button
                label={"Back"}
                onClick={() => {
                  setIsPreviewEnabled(false);
                  setBannerImage(null);
                }}
              />
              <Button
                label={"Create Banner"}
                onClick={() => setIsPreviewEnabled(false)}
              />
            </div>
          </Row>
        )}

        {/* Default View */}
        {!isPreviewEnabled && (
          <>
            <div className={classes?.breadCrumb}>
              <span className={classes?.breadCrumbText}>
                {editData ? "Edit Banner" : `Banner Creation > New Banner`}
              </span>
            </div>
            <Row
              className={[
                classes.row,
                classes?.paddingContainer,
                "g-0",
                classes,
              ].join(" ")}
            >
              <Col md={12} className={classes.upload_img_cus}>
                <UploadImageBox
                  value={bannerImage}
                  setValue={setBannerImage}
                  edit
                />
              </Col>
              <Col md={4} className={`mt-3 ${classes?.col1}`}>
                <Input
                  variant="dashboard"
                  label={"Banner Name"}
                  placeholder={"Enter Banner Name"}
                  value={bannerName}
                  setValue={setBannerName}
                  type={"text"}
                />
              </Col>
              <Col md={4} className={`mt-3 ${classes?.col1}`}>
                <DropDown
                  options={pageNameList}
                  value={pageName}
                  setValue={setPageName}
                  label={"Page Name"}
                  variant="dashboard"
                  placeholder={"Select Page"}
                  optionLabel={"label"}
                  optionValue={"label"}
                />
              </Col>
              <Col md={4} className={`mt-3 ${classes?.col1}`}>
                <Input
                  variant="dashboard"
                  label={"Start Display Date "}
                  value={startDateTime}
                  setValue={(e) => {
                    setStartDateTime(e);
                    setEndDateTime("");
                  }}
                  type={"date"}
                />
              </Col>
              <Col md={4} className={`mt-3 ${classes?.col1}`}>
                <Input
                  variant="dashboard"
                  label={"End Display Date "}
                  value={endDateTime}
                  min={startDateTime}
                  setValue={setEndDateTime}
                  type={"date"}
                />
              </Col>
              <Col md={4} className={`mt-3 ${classes?.col1}`}>
                <DropDown
                  options={bannerTypeOptions}
                  value={bannerType}
                  setValue={setBannerType}
                  label={"Banner Type"}
                  variant="dashboard"
                  placeholder={"Select Banner Type"}
                  optionLabel={"label"}
                  optionValue={"value"}
                />
              </Col>
              <div className={classes?.buttonsContainer}>
                <Button label={"Clear"} />
                <Button
                  disabled={isLoading}
                  label={
                    isLoading
                      ? "Please Wait..."
                      : editData
                      ? "Edit Banner"
                      : "Create Banner"
                  }
                  onClick={handleSubmit}
                />
              </div>
            </Row>
          </>
        )}
      </div>
    </ModalSkeleton>
  );
};

export default AdBannerModal;
