import React, { useEffect, useState } from "react";
import classes from "./MembershipManagement.module.css";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { GoCheckbox } from "react-icons/go";
import { MdModeEditOutline } from "react-icons/md";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { Input } from "../../Component/Input/Input";
import { Button } from "../../Component/Button/Button";
import {
  useLazyGetAllPackagesQuery,
  useUpdatePackageMutation,
} from "../../store/apiSlice/apiSlice";
import { toast } from "react-toastify";
import { Loader } from "../../Component/Loader";
import {
  capitalizeFirstLetter,
  formRegEx,
  formRegExReplacer,
} from "../../config/apiUrl";

const InputWithEditButton = ({
  value,
  setValue,
  isEdit,
  setIsEdit,
  ...props
}) => {
  return (
    <div className={classes?.inputWithBtn}>
      <Input
        className={classes.input}
        value={value}
        setValue={(e) => setValue(e)}
        disabled={!isEdit}
        {...props}
      />
      <div onClick={setIsEdit}>
        {isEdit ? (
          <AiOutlineCheckCircle size={20} />
        ) : (
          <MdModeEditOutline size={20} />
        )}
      </div>
    </div>
  );
};

function MembershipManagement() {
  const [getAllPackages, { isLoading }] = useLazyGetAllPackagesQuery();
  const [updateSinglePackage] = useUpdatePackageMutation();
  const [isUpdating, setIsUpdating] = useState(false);

  const [basic, setBasic] = useState({
    name: "Basic",
    noOfAgents: 0,
    noOfProperties: 0,
    noOfProjects: 0,
    noOfEvents: 0,
    receivePropertyRequests: false,
    includeCompanyAndAgentSearch: false,
    companyLogoInOurPartners: false,
    monthlySubscription: 0,
    threeMonthsSubscription: 0,
    sixMonthsSubscription: 0,
    yearlySubscription: 0,
  });
  const [lite, setLite] = useState({
    name: "Lite",
    noOfAgents: 0,
    noOfProperties: 0,
    noOfProjects: 0,
    noOfEvents: 0,
    receivePropertyRequests: false,
    includeCompanyAndAgentSearch: false,
    companyLogoInOurPartners: false,
    monthlySubscription: 0,
    threeMonthsSubscription: 0,
    sixMonthsSubscription: 0,
    yearlySubscription: 0,
  });
  const [plus, setPlus] = useState({
    name: "Plus",
    noOfAgents: 0,
    noOfProperties: 0,
    noOfProjects: 0,
    noOfEvents: 0,
    receivePropertyRequests: false,
    includeCompanyAndAgentSearch: false,
    companyLogoInOurPartners: false,
    monthlySubscription: 0,
    threeMonthsSubscription: 0,
    sixMonthsSubscription: 0,
    yearlySubscription: 0,
  });
  const [pro, setPro] = useState({
    name: "Pro",
    noOfAgents: 0,
    noOfProperties: 0,
    noOfProjects: 0,
    noOfEvents: 0,
    receivePropertyRequests: false,
    includeCompanyAndAgentSearch: false,
    companyLogoInOurPartners: false,
    monthlySubscription: 0,
    threeMonthsSubscription: 0,
    sixMonthsSubscription: 0,
    yearlySubscription: 0,
  });
  const [isEdit, setIsEdit] = useState({
    basic: {
      name: false,
      propertyListings: false,
      projectListings: false,
      eventListings: false,
    },
    lite: {
      name: false,
      propertyListings: false,
      projectListings: false,
      eventListings: false,
    },
    plus: {
      name: false,
      propertyListings: false,
      projectListings: false,
      eventListings: false,
    },
    pro: {
      name: false,
      propertyListings: false,
      projectListings: false,
      eventListings: false,
    },
  });

  const getData = async () => {
    const res = await getAllPackages();
    console.log({ res });
    if (res?.data) {
      setBasic(res?.data?.data?.find((e) => e?.packageType === "basic"));
      setLite(res?.data?.data?.find((e) => e?.packageType === "lite"));
      setPlus(res?.data?.data?.find((e) => e?.packageType === "plus"));
      setPro(res?.data?.data?.find((e) => e?.packageType === "pro"));
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const updatePackage = async (type) => {
    const body =
      (type == "basic" && basic) ||
      (type == "lite" && lite) ||
      (type == "plus" && plus) ||
      (type == "pro" && pro);

    const validationParams = {
      description: body?.name,
      professionalAgentProfiles: body?.noOfAgents,
      propertyListings: body?.noOfProperties,
      projectListings: body?.noOfProjects,
      eventListings: body?.noOfEvents,
      setMonthlySubscriptionFees: body?.monthlySubscription,
      set3MonthsSubscriptionFees: body?.threeMonthsSubscription,
      set6MonthsSubscriptionFees: body?.sixMonthsSubscription,
      set12MonthsSubscriptionFees: body?.yearlySubscription,
    };
    for (let key in validationParams) {
      if (!validationParams[key]) {
        toast.error(
          `Please fill the ${capitalizeFirstLetter(
            key?.replace(formRegEx, formRegExReplacer)
          )} field`
        );
        return;
      }
    }
    setIsUpdating(type);
    const res = await updateSinglePackage({ data: body, id: body?._id });
    setIsUpdating("");

    if (res?.data) {
      toast.success(`${capitalizeFirstLetter(type)} plan updated successfully`);
    }
    setIsEdit((prev) => ({
      ...prev,
      [type]: {
        ...prev?.[type],
        name: false,
        propertyListings: false,
        projectListings: false,
        eventListings: false,
      },
    }));
  };

  return (
    <SideBarSkeleton>
      <Container fluid className={classes.page}>
        {isLoading ? (
          <Loader className={classes?.loader} />
        ) : (
          <Row>
            <Col md={12}>
              <h5 className={classes.heading}>MEMBERSHIPS</h5>
            </Col>
            <Col md={12} className={classes.mb58}>
              <Paper
                sx={{ width: "100%", overflow: "hidden" }}
                className={classes.tableWrapper}
              >
                <TableContainer className={[""].join(" ")}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead className={classes.thead}>
                      <TableRow>
                        <TableCell style={{ width: "300px" }}></TableCell>
                        <TableCell>BASIC</TableCell>
                        <TableCell>LITE</TableCell>
                        <TableCell>PLUS</TableCell>
                        <TableCell>PRO</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        <TableRow
                          // hover
                          // role="checkbox"
                          tabIndex={-1}
                          className={classes.tableRow}
                        >
                          <TableCell></TableCell>
                          <TableCell>
                            <InputWithEditButton
                              value={basic?.name}
                              isEdit={isEdit?.basic?.name}
                              setValue={(e) =>
                                setBasic((prev) => ({ ...prev, name: e }))
                              }
                              disabled={!isEdit?.basic?.name}
                              placeholder="Describe your package"
                              setIsEdit={() =>
                                setIsEdit((prev) => ({
                                  ...prev,
                                  basic: {
                                    ...prev?.basic,
                                    name: !prev?.basic?.name,
                                  },
                                }))
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <InputWithEditButton
                              value={lite?.name}
                              isEdit={isEdit?.lite?.name}
                              setValue={(e) =>
                                setLite((prev) => ({ ...prev, name: e }))
                              }
                              disabled={!isEdit?.lite?.name}
                              placeholder="Describe your package"
                              setIsEdit={() =>
                                setIsEdit((prev) => ({
                                  ...prev,
                                  lite: {
                                    ...prev?.lite,
                                    name: !prev?.lite?.name,
                                  },
                                }))
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <InputWithEditButton
                              value={plus?.name}
                              isEdit={isEdit?.plus?.name}
                              setValue={(e) =>
                                setPlus((prev) => ({ ...prev, name: e }))
                              }
                              disabled={!isEdit?.plus?.name}
                              placeholder="Describe your package"
                              setIsEdit={() =>
                                setIsEdit((prev) => ({
                                  ...prev,
                                  plus: {
                                    ...prev?.plus,
                                    name: !prev?.plus?.name,
                                  },
                                }))
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <InputWithEditButton
                              value={pro?.name}
                              isEdit={isEdit?.pro?.name}
                              setValue={(e) =>
                                setPro((prev) => ({ ...prev, name: e }))
                              }
                              disabled={!isEdit?.pro?.name}
                              placeholder="Describe your package"
                              setIsEdit={() =>
                                setIsEdit((prev) => ({
                                  ...prev,
                                  pro: {
                                    ...prev?.pro,
                                    name: !prev?.pro?.name,
                                  },
                                }))
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow
                          // hover
                          // role="checkbox"
                          tabIndex={-1}
                          className={classes.tableRow}
                        >
                          <TableCell>
                            {/* <Checkbox
                              color="primary"
                              checked={
                                basic?.companyProfile
                                // false
                                //   selectedRows?.find(
                                //     (e) => e?._id == item?._id
                                //   ) !== undefined
                              }
                              // inputProps={{
                              //   'aria-labelledby': `enhanced-table-checkbox-${key}`
                              // }}
                              onChange={() => {
                                // const newData = [...selectedRows];
                                // if (newData?.find((e) => e?._id == item?._id)) {
                                //   newData?.splice(
                                //     newData?.findIndex(
                                //       (e) => e?._id == item?._id
                                //     ),
                                //     1
                                //   );
                                // } else {
                                //   newData?.push(item);
                                // }
                                // setSelectedRows(newData);
                              }}
                            /> */}
                            <p className={classes.tableCol1Text}>
                              Professional Company Profile & Mini Website
                            </p>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <GoCheckbox size={21} />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <GoCheckbox size={21} />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <GoCheckbox size={21} />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <GoCheckbox size={21} />
                            </div>
                          </TableCell>
                        </TableRow>

                        {/* Agent Profiles */}
                        <TableRow tabIndex={-1} className={classes.tableRow}>
                          <TableCell>
                            <p className={classes.tableCol1Text}>
                              Professional Agent Profiles
                            </p>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Input
                                value={basic?.noOfAgents}
                                setValue={(e) => {
                                  setBasic((prev) => ({
                                    ...prev,
                                    noOfAgents: Number(e),
                                  }));
                                }}
                                className={classes.input}
                                type={"number"}
                                min={0}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Input
                                value={lite?.noOfAgents}
                                setValue={(e) => {
                                  setLite((prev) => ({
                                    ...prev,
                                    noOfAgents: Number(e),
                                  }));
                                }}
                                className={classes.input}
                                type={"number"}
                                min={0}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Input
                                value={plus?.noOfAgents}
                                setValue={(e) => {
                                  setPlus((prev) => ({
                                    ...prev,
                                    noOfAgents: Number(e),
                                  }));
                                }}
                                className={classes.input}
                                type={"number"}
                                min={0}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Input
                                value={pro?.noOfAgents}
                                setValue={(e) => {
                                  setPro((prev) => ({
                                    ...prev,
                                    noOfAgents: Number(e),
                                  }));
                                }}
                                className={classes.input}
                                type={"number"}
                                min={0}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        {/* Agent Profiles */}

                        {/* Property Listings */}
                        <TableRow
                          // hover
                          // role="checkbox"
                          tabIndex={-1}
                          className={classes.tableRow}
                        >
                          <TableCell>
                            <p className={classes.tableCol1Text}>
                              Property Listings
                            </p>
                          </TableCell>
                          <TableCell>
                            <InputWithEditButton
                              value={basic?.noOfProperties}
                              isEdit={isEdit?.basic?.propertyListings}
                              setValue={(e) =>
                                setBasic((prev) => ({
                                  ...prev,
                                  noOfProperties: Number(e),
                                }))
                              }
                              type={"number"}
                              min={0}
                              placeholder={"No of Property Listings"}
                              disabled={!isEdit?.basic?.propertyListings}
                              setIsEdit={() =>
                                setIsEdit((prev) => ({
                                  ...prev,
                                  basic: {
                                    ...prev?.basic,
                                    propertyListings:
                                      !prev?.basic?.propertyListings,
                                  },
                                }))
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <InputWithEditButton
                              value={lite?.noOfProperties}
                              isEdit={isEdit?.lite?.propertyListings}
                              setValue={(e) =>
                                setLite((prev) => ({
                                  ...prev,
                                  noOfProperties: Number(e),
                                }))
                              }
                              type={"number"}
                              placeholder={"No of Property Listings"}
                              min={0}
                              disabled={!isEdit?.lite?.propertyListings}
                              setIsEdit={() =>
                                setIsEdit((prev) => ({
                                  ...prev,
                                  lite: {
                                    ...prev?.lite,
                                    propertyListings:
                                      !prev?.lite?.propertyListings,
                                  },
                                }))
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <InputWithEditButton
                              value={plus?.noOfProperties}
                              isEdit={isEdit?.plus?.propertyListings}
                              setValue={(e) =>
                                setPlus((prev) => ({
                                  ...prev,
                                  noOfProperties: Number(e),
                                }))
                              }
                              type={"number"}
                              placeholder={"No of Property Listings"}
                              min={0}
                              disabled={!isEdit?.plus?.propertyListings}
                              setIsEdit={() =>
                                setIsEdit((prev) => ({
                                  ...prev,
                                  plus: {
                                    ...prev?.plus,
                                    propertyListings:
                                      !prev?.plus?.propertyListings,
                                  },
                                }))
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <InputWithEditButton
                              value={pro?.noOfProperties}
                              isEdit={isEdit?.pro?.propertyListings}
                              setValue={(e) =>
                                setPro((prev) => ({
                                  ...prev,
                                  noOfProperties: Number(e),
                                }))
                              }
                              type={"number"}
                              placeholder={"No of Property Listings"}
                              min={0}
                              disabled={!isEdit?.pro?.propertyListings}
                              setIsEdit={() =>
                                setIsEdit((prev) => ({
                                  ...prev,
                                  pro: {
                                    ...prev?.pro,
                                    propertyListings:
                                      !prev?.pro?.propertyListings,
                                  },
                                }))
                              }
                            />
                          </TableCell>
                        </TableRow>
                        {/* Property Listings */}
                        {/* Projects Listings */}
                        <TableRow
                          // hover
                          // role="checkbox"
                          tabIndex={-1}
                          className={classes.tableRow}
                        >
                          <TableCell>
                            <p className={classes.tableCol1Text}>
                              Projects Listings
                            </p>
                          </TableCell>
                          <TableCell>
                            <InputWithEditButton
                              value={basic?.noOfProjects}
                              isEdit={isEdit?.basic?.projectListings}
                              setValue={(e) =>
                                setBasic((prev) => ({
                                  ...prev,
                                  noOfProjects: Number(e),
                                }))
                              }
                              type={"number"}
                              placeholder={"No of Projects Listings"}
                              min={0}
                              disabled={!isEdit?.basic?.projectListings}
                              setIsEdit={() =>
                                setIsEdit((prev) => ({
                                  ...prev,
                                  basic: {
                                    ...prev?.basic,
                                    projectListings:
                                      !prev?.basic?.projectListings,
                                  },
                                }))
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <InputWithEditButton
                              value={lite?.noOfProjects}
                              isEdit={isEdit?.lite?.projectListings}
                              setValue={(e) =>
                                setLite((prev) => ({
                                  ...prev,
                                  noOfProjects: Number(e),
                                }))
                              }
                              placeholder={"No of Projects Listings"}
                              type={"number"}
                              min={0}
                              disabled={!isEdit?.lite?.projectListings}
                              setIsEdit={() =>
                                setIsEdit((prev) => ({
                                  ...prev,
                                  lite: {
                                    ...prev?.lite,
                                    projectListings:
                                      !prev?.lite?.projectListings,
                                  },
                                }))
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <InputWithEditButton
                              value={plus?.noOfProjects}
                              isEdit={isEdit?.plus?.projectListings}
                              setValue={(e) =>
                                setPlus((prev) => ({
                                  ...prev,
                                  noOfProjects: Number(e),
                                }))
                              }
                              placeholder={"No of Projects Listings"}
                              type={"number"}
                              min={0}
                              disabled={!isEdit?.plus?.projectListings}
                              setIsEdit={() =>
                                setIsEdit((prev) => ({
                                  ...prev,
                                  plus: {
                                    ...prev?.plus,
                                    projectListings:
                                      !prev?.plus?.projectListings,
                                  },
                                }))
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <InputWithEditButton
                              value={pro?.noOfProjects}
                              isEdit={isEdit?.pro?.projectListings}
                              setValue={(e) =>
                                setPro((prev) => ({
                                  ...prev,
                                  noOfProjects: Number(e),
                                }))
                              }
                              placeholder={"No of Projects Listings"}
                              type={"number"}
                              min={0}
                              disabled={!isEdit?.pro?.projectListings}
                              setIsEdit={() =>
                                setIsEdit((prev) => ({
                                  ...prev,
                                  pro: {
                                    ...prev?.pro,
                                    projectListings:
                                      !prev?.pro?.projectListings,
                                  },
                                }))
                              }
                            />
                          </TableCell>
                        </TableRow>
                        {/* Projects Listings */}
                        {/* Events Listings */}
                        <TableRow
                          // hover
                          // role="checkbox"
                          tabIndex={-1}
                          className={classes.tableRow}
                        >
                          <TableCell>
                            <p className={classes.tableCol1Text}>
                              Events Listings
                            </p>
                          </TableCell>
                          <TableCell>
                            <InputWithEditButton
                              value={basic?.noOfEvents}
                              isEdit={isEdit?.basic?.eventListings}
                              setValue={(e) =>
                                setBasic((prev) => ({
                                  ...prev,
                                  noOfEvents: Number(e),
                                }))
                              }
                              placeholder={"No of Events Listings"}
                              type={"number"}
                              min={0}
                              disabled={!isEdit?.basic?.eventListings}
                              setIsEdit={() =>
                                setIsEdit((prev) => ({
                                  ...prev,
                                  basic: {
                                    ...prev?.basic,
                                    eventListings: !prev?.basic?.eventListings,
                                  },
                                }))
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <InputWithEditButton
                              value={lite?.noOfEvents}
                              isEdit={isEdit?.lite?.eventListings}
                              setValue={(e) =>
                                setLite((prev) => ({
                                  ...prev,
                                  noOfEvents: Number(e),
                                }))
                              }
                              placeholder={"No of Events Listings"}
                              type={"number"}
                              min={0}
                              disabled={!isEdit?.lite?.eventListings}
                              setIsEdit={() =>
                                setIsEdit((prev) => ({
                                  ...prev,
                                  lite: {
                                    ...prev?.lite,
                                    eventListings: !prev?.lite?.eventListings,
                                  },
                                }))
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <InputWithEditButton
                              value={plus?.noOfEvents}
                              isEdit={isEdit?.plus?.eventListings}
                              setValue={(e) =>
                                setPlus((prev) => ({
                                  ...prev,
                                  noOfEvents: Number(e),
                                }))
                              }
                              type={"number"}
                              placeholder={"No of Events Listings"}
                              min={0}
                              disabled={!isEdit?.plus?.eventListings}
                              setIsEdit={() =>
                                setIsEdit((prev) => ({
                                  ...prev,
                                  plus: {
                                    ...prev?.plus,
                                    eventListings: !prev?.plus?.eventListings,
                                  },
                                }))
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <InputWithEditButton
                              value={pro?.noOfEvents}
                              isEdit={isEdit?.pro?.eventListings}
                              setValue={(e) =>
                                setPro((prev) => ({
                                  ...prev,
                                  noOfEvents: Number(e),
                                }))
                              }
                              type={"number"}
                              placeholder={"No of Events Listings"}
                              min={0}
                              disabled={!isEdit?.pro?.eventListings}
                              setIsEdit={() =>
                                setIsEdit((prev) => ({
                                  ...prev,
                                  pro: {
                                    ...prev?.pro,
                                    eventListings: !prev?.pro?.eventListings,
                                  },
                                }))
                              }
                            />
                          </TableCell>
                        </TableRow>
                        {/* Events Listings */}

                        <TableRow
                          // hover
                          // role="checkbox"
                          tabIndex={-1}
                          className={classes.tableRow}
                        >
                          <TableCell>
                            <p className={classes.tableCol1Text}>
                              Receive Property Requests
                            </p>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Checkbox
                                color="primary"
                                checked={basic?.receivePropertyRequests}
                                onChange={() => {
                                  setBasic((prev) => ({
                                    ...prev,
                                    receivePropertyRequests:
                                      !prev?.receivePropertyRequests,
                                  }));
                                }}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Checkbox
                                color="primary"
                                checked={lite?.receivePropertyRequests}
                                onChange={() => {
                                  setLite((prev) => ({
                                    ...prev,
                                    receivePropertyRequests:
                                      !prev?.receivePropertyRequests,
                                  }));
                                }}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Checkbox
                                color="primary"
                                checked={plus?.receivePropertyRequests}
                                onChange={() => {
                                  setPlus((prev) => ({
                                    ...prev,
                                    receivePropertyRequests:
                                      !prev?.receivePropertyRequests,
                                  }));
                                }}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Checkbox
                                color="primary"
                                checked={pro?.receivePropertyRequests}
                                onChange={() => {
                                  setPro((prev) => ({
                                    ...prev,
                                    receivePropertyRequests:
                                      !prev?.receivePropertyRequests,
                                  }));
                                }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          // hover
                          // role="checkbox"
                          tabIndex={-1}
                          className={classes.tableRow}
                        >
                          <TableCell>
                            <p className={classes.tableCol1Text}>
                              Included Company & Agent Search
                            </p>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Checkbox
                                color="primary"
                                checked={basic?.includeCompanyAndAgentSearch}
                                onChange={() => {
                                  setBasic((prev) => ({
                                    ...prev,
                                    includeCompanyAndAgentSearch:
                                      !prev?.includeCompanyAndAgentSearch,
                                  }));
                                }}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Checkbox
                                color="primary"
                                checked={lite?.includeCompanyAndAgentSearch}
                                onChange={() => {
                                  setLite((prev) => ({
                                    ...prev,
                                    includeCompanyAndAgentSearch:
                                      !prev?.includeCompanyAndAgentSearch,
                                  }));
                                }}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Checkbox
                                color="primary"
                                checked={plus?.includeCompanyAndAgentSearch}
                                onChange={() => {
                                  setPlus((prev) => ({
                                    ...prev,
                                    includeCompanyAndAgentSearch:
                                      !prev?.includeCompanyAndAgentSearch,
                                  }));
                                }}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Checkbox
                                color="primary"
                                checked={pro?.includeCompanyAndAgentSearch}
                                onChange={() => {
                                  setPro((prev) => ({
                                    ...prev,
                                    includeCompanyAndAgentSearch:
                                      !prev?.includeCompanyAndAgentSearch,
                                  }));
                                }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>

                        <TableRow
                          // hover
                          // role="checkbox"
                          tabIndex={-1}
                          className={classes.tableRow}
                        >
                          <TableCell>
                            <p className={classes.tableCol1Text}>
                              Company Logo in Home Page (Our Partners) Slide
                            </p>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Checkbox
                                color="primary"
                                checked={basic?.companyLogoInOurPartners}
                                onChange={() => {
                                  setBasic((prev) => ({
                                    ...prev,
                                    companyLogoInOurPartners:
                                      !prev?.companyLogoInOurPartners,
                                  }));
                                }}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Checkbox
                                color="primary"
                                checked={lite?.companyLogoInOurPartners}
                                onChange={() => {
                                  setLite((prev) => ({
                                    ...prev,
                                    companyLogoInOurPartners:
                                      !prev?.companyLogoInOurPartners,
                                  }));
                                }}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Checkbox
                                color="primary"
                                checked={plus?.companyLogoInOurPartners}
                                onChange={() => {
                                  setPlus((prev) => ({
                                    ...prev,
                                    companyLogoInOurPartners:
                                      !prev?.companyLogoInOurPartners,
                                  }));
                                }}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Checkbox
                                color="primary"
                                checked={pro?.companyLogoInOurPartners}
                                onChange={() => {
                                  setPro((prev) => ({
                                    ...prev,
                                    companyLogoInOurPartners:
                                      !prev?.companyLogoInOurPartners,
                                  }));
                                }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>

                        {/* Monthly Subscription */}
                        <TableRow tabIndex={-1} className={classes.tableRow}>
                          <TableCell>
                            <p className={classes.tableCol1Text}>
                              Set monthly Subscription fees
                            </p>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Input
                                value={basic?.monthlySubscription}
                                setValue={(e) => {
                                  setBasic((prev) => ({
                                    ...prev,
                                    monthlySubscription: Number(e),
                                  }));
                                }}
                                className={classes.input}
                                type={"number"}
                                min={0}
                                leftIcon={"$"}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Input
                                value={lite?.monthlySubscription}
                                setValue={(e) => {
                                  setLite((prev) => ({
                                    ...prev,
                                    monthlySubscription: Number(e),
                                  }));
                                }}
                                className={classes.input}
                                type={"number"}
                                min={0}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Input
                                value={plus?.monthlySubscription}
                                setValue={(e) => {
                                  setPlus((prev) => ({
                                    ...prev,
                                    monthlySubscription: Number(e),
                                  }));
                                }}
                                className={classes.input}
                                type={"number"}
                                min={0}
                                leftIcon={"$"}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Input
                                value={pro?.monthlySubscription}
                                setValue={(e) => {
                                  setPro((prev) => ({
                                    ...prev,
                                    monthlySubscription: Number(e),
                                  }));
                                }}
                                className={classes.input}
                                type={"number"}
                                min={0}
                                leftIcon={"$"}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        {/* Monthly Subscription */}
                        {/* 3 Month Subscription */}
                        <TableRow tabIndex={-1} className={classes.tableRow}>
                          <TableCell>
                            <p className={classes.tableCol1Text}>
                              Set 3 months Subscription fees
                            </p>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Input
                                value={basic?.threeMonthsSubscription}
                                setValue={(e) => {
                                  setBasic((prev) => ({
                                    ...prev,
                                    threeMonthsSubscription: Number(e),
                                  }));
                                }}
                                className={classes.input}
                                type={"number"}
                                min={0}
                                leftIcon={"$"}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Input
                                value={lite?.threeMonthsSubscription}
                                setValue={(e) => {
                                  setLite((prev) => ({
                                    ...prev,
                                    threeMonthsSubscription: Number(e),
                                  }));
                                }}
                                className={classes.input}
                                type={"number"}
                                min={0}
                                leftIcon={"$"}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Input
                                value={plus?.threeMonthsSubscription}
                                setValue={(e) => {
                                  setPlus((prev) => ({
                                    ...prev,
                                    threeMonthsSubscription: Number(e),
                                  }));
                                }}
                                className={classes.input}
                                type={"number"}
                                min={0}
                                leftIcon={"$"}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Input
                                value={pro?.threeMonthsSubscription}
                                setValue={(e) => {
                                  setPro((prev) => ({
                                    ...prev,
                                    threeMonthsSubscription: Number(e),
                                  }));
                                }}
                                className={classes.input}
                                type={"number"}
                                min={0}
                                leftIcon={"$"}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        {/* 3 Month Subscription */}
                        {/* 6 Month Subscription */}
                        <TableRow tabIndex={-1} className={classes.tableRow}>
                          <TableCell>
                            <p className={classes.tableCol1Text}>
                              Set 6 months Subscription fees
                            </p>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Input
                                value={basic?.sixMonthsSubscription}
                                setValue={(e) => {
                                  setBasic((prev) => ({
                                    ...prev,
                                    sixMonthsSubscription: Number(e),
                                  }));
                                }}
                                className={classes.input}
                                type={"number"}
                                min={0}
                                leftIcon={"$"}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Input
                                value={lite?.sixMonthsSubscription}
                                setValue={(e) => {
                                  setLite((prev) => ({
                                    ...prev,
                                    sixMonthsSubscription: Number(e),
                                  }));
                                }}
                                className={classes.input}
                                type={"number"}
                                min={0}
                                leftIcon={"$"}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Input
                                value={plus?.sixMonthsSubscription}
                                setValue={(e) => {
                                  setPlus((prev) => ({
                                    ...prev,
                                    sixMonthsSubscription: Number(e),
                                  }));
                                }}
                                className={classes.input}
                                type={"number"}
                                min={0}
                                leftIcon={"$"}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Input
                                value={pro?.sixMonthsSubscription}
                                setValue={(e) => {
                                  setPro((prev) => ({
                                    ...prev,
                                    sixMonthsSubscription: Number(e),
                                  }));
                                }}
                                className={classes.input}
                                type={"number"}
                                min={0}
                                leftIcon={"$"}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        {/* 6 Month Subscription */}
                        {/* 12 Month Subscription */}
                        <TableRow tabIndex={-1} className={classes.tableRow}>
                          <TableCell>
                            <p className={classes.tableCol1Text}>
                              Set 12 months Subscription fees
                            </p>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Input
                                value={basic?.yearlySubscription}
                                setValue={(e) => {
                                  setBasic((prev) => ({
                                    ...prev,
                                    yearlySubscription: Number(e),
                                  }));
                                }}
                                className={classes.input}
                                type={"number"}
                                min={0}
                                leftIcon={"$"}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Input
                                value={lite?.yearlySubscription}
                                setValue={(e) => {
                                  setLite((prev) => ({
                                    ...prev,
                                    yearlySubscription: Number(e),
                                  }));
                                }}
                                className={classes.input}
                                type={"number"}
                                min={0}
                                leftIcon={"$"}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Input
                                value={plus?.yearlySubscription}
                                setValue={(e) => {
                                  setPlus((prev) => ({
                                    ...prev,
                                    yearlySubscription: Number(e),
                                  }));
                                }}
                                className={classes.input}
                                type={"number"}
                                min={0}
                                leftIcon={"$"}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Input
                                value={pro?.yearlySubscription}
                                setValue={(e) => {
                                  setPro((prev) => ({
                                    ...prev,
                                    yearlySubscription: Number(e),
                                  }));
                                }}
                                className={classes.input}
                                type={"number"}
                                min={0}
                                leftIcon={"$"}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        {/* 12 Month Subscription */}

                        <TableRow tabIndex={-1} className={classes.tableRow}>
                          <TableCell></TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Button
                                variant="secondary"
                                onClick={() => updatePackage("basic")}
                                disabled={isUpdating == "basic"}
                              >
                                {isUpdating == "basic"
                                  ? "Updating..."
                                  : "Update"}
                              </Button>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Button
                                variant="secondary"
                                onClick={() => updatePackage("lite")}
                                disabled={isUpdating == "lite"}
                              >
                                {isUpdating == "lite"
                                  ? "Updating..."
                                  : "Update"}
                              </Button>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Button
                                variant="secondary"
                                onClick={() => updatePackage("plus")}
                                disabled={isUpdating == "plus"}
                              >
                                {isUpdating == "plus"
                                  ? "Updating..."
                                  : "Update"}
                              </Button>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="jCenter">
                              <Button
                                variant="secondary"
                                onClick={() => updatePackage("pro")}
                                disabled={isUpdating == "pro"}
                              >
                                {isUpdating == "pro" ? "Updating..." : "Update"}
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Col>
          </Row>
        )}
      </Container>
    </SideBarSkeleton>
  );
}

export default MembershipManagement;
