/* eslint-disable */

import React, { useState } from "react";
import classes from "./CompaniesManagement.module.css";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import CustomMaterialTable from "../../Component/CustomMaterialTable";
import { AiFillEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { MdOutlineUpload } from "react-icons/md";
import { Button } from "../../Component/Button/Button";
import SearchInput from "../../Component/SearchInput";
import { useNavigate } from "react-router-dom";
import UpgradingListingModal from "../../modals/UpgradingListingModal/UpgradingListingModal";
import DropDown from "../../Component/DropDown/DropDown";
import { dateFilterOptions } from "../../constant/commonData";
import { IoIosAddCircle } from "react-icons/io";
import AddCreditPointsModal from "../../modals/AddCreditPointsModal/AddCreditPointsModal";
import {
  useAddCreditCoinsMutation,
  useDeleteCompaniesMutation,
  useGetAllCompaniesQuery,
  useUpgradeCompanyPackageMutation,
} from "../../store/apiSlice/apiSlice";
import useDebounce from "../../CustomHooks/useDebounce";
import { recordsLimit, webLiveURL } from "../../config/apiUrl";
import moment from "moment";
import { toast } from "react-toastify";
import UpgradeMembershipModal from "../../modals/UpgradeMembershipModal";
import { useSelector } from "react-redux";

const tableData = Array(10)
  .fill({
    id: "5645",
    date: "2020.11.12",
    currentMembership: "Lite",
    companyName: "Real Estate",
    email: "smith@email.com",
    contact: "42197362890",
  })
  ?.map((item) => ({ ...item, id: Math.floor(Math.random() * 10000) }));

function CompaniesManagement() {
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(1);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [dateFilter, setDateFilter] = useState(dateFilterOptions[0]);
  const headings = [
    // { id: 'id', label: 'ID' },
    {
      id: "createdAt",
      label: "CREATION DATE",
      renderValue: (item, col) => {
        return moment(item[col?.id]).format("DD/MM/YYYY hh:mm A");
      },
    },
    {
      id: "package",
      label: "CURRENT MEMBERSHIP",
      renderValue: (item, col) => item[col?.id]?.packageType,
    },
    {
      id: "companyName",
      label: "COMPANY NAME",
      renderValue: (item, col) => {
        return item[col?.id]?.[selectedLanguage];
      },
    },
    { id: "email", label: "EMAIL" },
    { id: "phoneNumber", label: "CONTACT" },
    { id: "options", label: "OPTIONS", align: "center" },
  ];
  const debounceSearch = useDebounce(searchText, 500);
  const { data, isLoading, isFetching } = useGetAllCompaniesQuery({
    limit: recordsLimit,
    page: page,
    search: debounceSearch,
    sortBy: dateFilter?.value,
    lan:selectedLanguage
  });
  const [deleteCompanies, { isLoading: isDeleting }] =
    useDeleteCompaniesMutation();

  // Add Credit Points
  const [addCreditCoins, { isLoading: isAddingCoins }] =
    useAddCreditCoinsMutation();
  // Upgrade Membership
  const [upgradeMembership, { isLoading: isUpdatingPackage }] =
    useUpgradeCompanyPackageMutation();

  const deleteMultiple = async () => {
    const res = await deleteCompanies(selectedRows?.map((item) => item._id));
    if (res?.data) {
      toast.success(
        `${
          selectedRows?.length > 1 ? "Companies" : "Company"
        } deleted successfully`
      );
      setSelectedRows([]);
    }
  };

  return (
    <SideBarSkeleton>
      <Container fluid className={classes.page}>
        <Row>
          <Col md={12} className={classes.mb58}>
            <div className={classes.header}>
              <div className={classes.left}>
                <SearchInput
                  placeholder="Search by name"
                  setter={setSearchText}
                  value={searchText}
                  variant="dashboard"
                  containerStyle={{ width: "300px" }}
                />
                <DropDown
                  label={"Sort By Date"}
                  containerClassName={classes.dropDownContainer}
                  customStyle={{
                    padding: "0px 6px",
                    minHeight: "40px",
                    minWidth: "200px",
                  }}
                  options={dateFilterOptions}
                  setValue={(e) => {
                    setDateFilter(e);
                  }}
                  value={dateFilter}
                  placeholder={"Select Date"}
                  // isClearable={true}
                />
              </div>
              <div className={classes.headerRight}>
                <Button
                  borderRadius={"8px"}
                  className={classes.deleteBtn}
                  disabled={selectedRows?.length == 0}
                  onClick={deleteMultiple}
                >
                  {isDeleting ? "Deleting..." : "Delete"}
                </Button>
                <Button
                  borderRadius={"8px"}
                  className={classes.headerBtn}
                  onClick={() => navigate("/companies-management/new-company")}
                >
                  Create New Company
                </Button>
                {/* <Button borderRadius={'8px'}>Create New Property</Button> */}
              </div>
            </div>
          </Col>
          <Col md={12}>
            <h5 className={classes.heading}>COMPANIES</h5>
          </Col>
          <Col md={12}>
            <CustomMaterialTable
              headings={headings}
              data={data?.data}
              showOptionsColumn
              setPage={setPage}
              page={page}
              isLoading={isLoading || isFetching}
              options={[
                {
                  label: "View Profile",
                  icon: <AiFillEye size={18} />,
                  onClick: (_,e) => {
                    window.open(`${webLiveURL}company/${e?.slug}`, "_blank")
                  },
                },
                {
                  label: "Edit",
                  icon: <FiEdit size={18} />,
                  onClick: (label, e) => {
                    navigate(`/companies-management/edit-company/${e?.slug}`);
                  },
                },
                // {
                //   label: 'Active',
                //   icon: <FaToggleOn size={18} />,
                //   onClick: () => {},
                //   switch: true
                // },
                {
                  label: "Upgrade Membership",
                  icon: <MdOutlineUpload size={18} />,
                  onClick: (label, e) => {
                    // console.log("upgrade package", label, item);
                    setSelectedItem(e);
                    setIsModalOpen("membership");
                  },
                },
                {
                  label: "Add Points",
                  icon: <IoIosAddCircle size={18} />,
                  onClick: (_, e) => {
                    console.log("add points", e);
                    setSelectedItem(e);
                    setIsModalOpen("credit-points");
                  },
                },
              ]}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              allowMultipleSelection
              totalRecords={Math.ceil(data?.totalRecords / recordsLimit)}
            />
          </Col>
        </Row>
      </Container>

      {isModalOpen == "credit-points" && (
        <AddCreditPointsModal
          show={isModalOpen == "credit-points"}
          setShow={setIsModalOpen}
          isApiCall={isAddingCoins}
          data={selectedItem}
          onClick={async (slug, e) => {
            const res = await addCreditCoins({ slug, data: e });
            if (res?.data) {
              setIsModalOpen("");
              toast.success("Points added successfully");
            }
          }}
        />
      )}
      {isModalOpen == "membership" && (
        <UpgradeMembershipModal
          show={isModalOpen == "membership"}
          setShow={setIsModalOpen}
          isApiCall={isUpdatingPackage}
          data={selectedItem}
          onClick={async (e) => {
            const res = await upgradeMembership({ slug:selectedItem?.slug, data: e });
            if (res?.data) {
              setIsModalOpen("");
              toast.success("Membership upgrated successfully");
            }
          }}
        />
      )}
    </SideBarSkeleton>
  );
}

export default CompaniesManagement;
