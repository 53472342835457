import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "../../Component/Button/Button";
import { Input } from "../../Component/Input/Input";
import { toast } from "react-toastify";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import classes from "./MyProfile.module.css";
import { FaEdit } from "react-icons/fa";
import UpdatePasswordModal from "../../modals/UpdatePasswordModal";
import { Patch } from "../../Axios/AxiosFunctions";
import { useSelector, useDispatch } from "react-redux";
import { apiHeader, BaseURL, CreateFormData } from "../../config/apiUrl";
import { updateUser } from "../../store/auth/authSlice";
import { ProfileWithEditButton } from "../../Component/ProfileWithEditButton";

const MyProfile = () => {
  const dispatch = useDispatch();
  const { user,  accessToken } = useSelector(
    (state) => state.authReducer
  );
  const inputStyle = {
    borderRadius: "46px",
    boxShadow: "21px 14px 24px #00000012",
  };
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);

  const [profileImg, setProfileImg] = useState(user?.photo ? user?.photo : "");
  const [showModal, setshowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleEditProfile = async () => {
    const params = {
      photo: profileImg,
      firstName,
      lastName,
    };

    // validate
    for (let key in params)
      if (!params[key]) return toast.error(`Fields can not be empty.`);
    // call api
    const formData = await CreateFormData(params);
    const url = BaseURL(`users/updateMe`);
    setIsLoading(true);
    const response = await Patch(url, formData, apiHeader(accessToken, true));
    setIsLoading(false);
    if (response) {
      dispatch(updateUser(response?.data?.data?.user));
      toast.success("Profile has been updated successfully");
    }
  };
  return (
    <SideBarSkeleton>
      <Container className={classes.conatiner}>
        <Row className={classes.main}>
          <Col md={12}>
            <div className={classes.head}>
              <h5 className={classes.heading}>Profile Settings</h5>
              <Button
                label={"Update Password"}
                className={classes.editPasswordBtn}
                rightIcon={<FaEdit size={20} />}
                onClick={() => setshowModal(true)}
              />
            </div>
          </Col>
          <Col md={12}>
            <ProfileWithEditButton
              updateImage={profileImg}
              setUpdateImage={setProfileImg}
              isEdit={true}
            
            />
          </Col>
          <Col md={12}>
            <Row className={classes.innerRowGap}>
              <Col md={6} xl={4} lg={5}>
                <Input
                  setValue={setFirstName}
                  value={firstName}
                  placeholder={"Type Your First Name"}
                  label={"First Name"}
                  type={"text"}
                  customStyle={inputStyle}
                />
              </Col>
              <Col md={6} xl={4} lg={5}>
                <Input
                  setValue={setLastName}
                  value={lastName}
                  placeholder={"Type Your Last Name"}
                  label={"Last Name"}
                  type={"text"}
                  customStyle={inputStyle}
                />
              </Col>
            </Row>
          </Col>
          <Col md={12}>
            <Row className={classes.innerRowGap}>
              <Col md={12} xl={8} lg={10}>
                <Input
                  setValue={()=>null}
                  value={user?.email}
                  placeholder={"Enter Email "}
                  label={"Email"}
                  type={"email"}
                  customStyle={inputStyle}
                  disabled={true}
                />
              </Col>
            </Row>
          </Col>

          <Col md={12} xl={8} lg={10} sm={12}>
            <Button
              label={isLoading ? "Updating..." : "Update Profile"}
              className={classes.editBtn}
              rightIcon={<FaEdit size={20} />}
              onClick={handleEditProfile}
              disabled={isLoading}
            />
          </Col>
        </Row>
      </Container>
      <UpdatePasswordModal show={showModal} setShow={setshowModal} />
    </SideBarSkeleton>
  );
};

export default MyProfile;
