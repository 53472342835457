// import React from "react";
// import Pagination from "@mui/material/Pagination";

// const PaginationComponent = ({ totalPages, currentPage, setCurrentPage }) => {
//   const handleChange = (event, value) => {
//     setCurrentPage(value);
//   };
//   return (
//     <>
//       <style>{`
//         .MuiPagination-ul li .Mui-selected {
//           background: transparent linear-gradient(90deg, var(--primary-clr) 0%, var(--secondary-clr) 100%) 0% 0% no-repeat padding-box !important;
//             color: var(--white-clr) !important;
//             font-size:16px !important;
//             min-width: 28px !important;
//             height: 28px !important;
//             font-family:"Poppins-semiBold" !important;
//         }
//         .MuiPagination-ul li button {
//             color: var(--dashboard-text-color) !important;
//             font-size:16px !important;
//             font-family:"Poppins-semiBold" !important;
//         }
//     `}</style>
//       <div>
//         <Pagination
//           count={totalPages}
//           page={currentPage}
//           onChange={handleChange}
//           shape="rounded"
//         />
//       </div>
//     </>
//   );
// };

// export default PaginationComponent;



import React from "react";
import classes from "./PaginationComponent.module.css";
import Pagination from "@mui/material/Pagination";
// import "./pagination.css";

 const PaginationComponent = ({
  totalPages,
  currentPage,
  setCurrentPage,
  className,
}) => {
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  return (
    <div
      className={[classes.paginationDiv,'ltr', `${className && className}`].join(" ")}
    >
      <style>{`
      .MuiPagination-ul li .Mui-selected{
        background-color:var(--primary-clr);
        color:var(--white-clr) !important;
      }
      .MuiPagination-ul li:not(.Mui-selected) button:hover, .MuiPagination-ul li:not(.Mui-selected) button:focus{
        background-color:var(--primary-clr-hover) !important;
        color:var(--white-clr) !important;
      }
      `}</style>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handleChange}
        className={[classes.pageItem].join(" ")}
      />
    </div>
  );
};

export default PaginationComponent
