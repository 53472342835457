import { ReactSVG } from "react-svg";
import {
  ArabicLanguage,
  EnglishLanguage,
  EventsIcon,
  FrenchLanguage,
  GermanLanguage,
  PersianLanguage,
  PropertiesManagementIcon,
  RussianLanguage,
  TurkishLanguage,
  adIcon,
  bankIcon,
  blogIcon,
  dashboardIcon,
  faqIcon,
  membershipIcon,
  projectsIcon,
  settingsIcon,
} from "./imagePath";
import { BiBookBookmark, BiGlobe, BiSolidCity } from "react-icons/bi";
import { ImLocation2 } from "react-icons/im";
import { IoLanguage } from "react-icons/io5";
import { BsCardList, BsCurrencyDollar } from "react-icons/bs";
import { IoIosBusiness, IoIosList } from "react-icons/io";
import { AiFillDatabase } from "react-icons/ai";
import { SiBandsintown } from "react-icons/si";
import { GiFireplace } from "react-icons/gi";
import { MdOutlinePolicy, MdOutlinePrivacyTip } from "react-icons/md";
import { FaRegAddressBook } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";

export const allTaskStatusoptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Archive",
    value: "completed",
    status: "Archive",
  },
  {
    label: "Modify-Request",
    value: "modify-request",
    status: "Modify-Request",
  },
  {
    label: "In Progress",
    value: "accepted",
    status: "In Progress",
  },
  {
    label: "Cancelled Tasks",
    value: "cancelled",
    status: "Cancelled Task",
  },
  {
    label: "Awaiting Acceptance",
    value: "pending",
    status: "Awaiting Acceptance",
  },
];

export const sidebarOptions = [
  {
    icon: <ReactSVG src={dashboardIcon} />,
    title: "Dashboard",
    path: "/dashboard",
  },
  {
    icon: <IoIosBusiness size={25} />,
    title: "Companies Management",
    path: "/companies-management",
  },
  {
    icon: <ReactSVG src={PropertiesManagementIcon} />,
    title: "Properties Management",
    path: "/properties-management",
  },
  {
    icon: <ReactSVG src={projectsIcon} />,
    title: "Projects Management",
    path: "/projects-management",
  },
  {
    icon: <ReactSVG src={EventsIcon} />,
    title: "Events Management",
    path: "/events-management",
  },
  {
    icon: <ReactSVG src={membershipIcon} />,
    title: "Membership Management",
    path: "/membership-management",
  },
  {
    icon: <ReactSVG src={adIcon} />,
    title: "Banners Management",
    path: "/banners-management",
  },
  {
    icon: <ReactSVG src={bankIcon} />,
    title: "Banks Management",
    path: "/banks-management",
  },
  {
    icon: <ReactSVG src={blogIcon} />,
    title: "Blog Management",
    path: "/blog-management",
  },
  {
    icon: <ReactSVG src={faqIcon} />,
    title: "FAQs Management",
    path: "/faqs",
  },

  {
    icon: <AiFillDatabase size={25} />,
    title: "CRUDS",
    subMenu: [
      {
        icon: <BsCardList size={24} />,
        title: "Amenities",
        path: "/amenities",
      },
      {
        icon: <IoIosList size={24} />,
        title: "Types",
        path: "/types",
      },

      {
        icon: <BiSolidCity size={24} />,
        title: "Provinces",
        path: "/provinces",
      },
      {
        icon: <SiBandsintown size={20} />,
        title: "Towns",
        path: "/towns",
      },
      {
        icon: <GiFireplace size={24} />,
        title: "Neighborhoods",
        path: "/neighborhoods",
      },
      {
        icon: <BiBookBookmark size={24} />,
        title: "Privacy Policy",
        path: "/privacy-policy",
      },
      {
        icon: <FaRegAddressBook size={24} />,
        title: "Terms & Conditions",
        path: "/terms-and-conditions",
      },
      {
        icon: <FaPeopleGroup size={24} />,
        title: "Our Partners",
        path: "/our-partners",
      },
    ],
  },

  {
    icon: <ReactSVG src={settingsIcon} />,
    title: "Settings",
    subMenu: [
      {
        icon: <FaRegAddressBook size={20} />,
        title: "Profile Settings",
        path: "/profile-settings",
      },
      // {
      //   icon: <BsCurrencyDollar size={20} />,
      //   title: "Currency Settings",
      //   path: "/currency-settings",
      // },
      // {
      //   icon: <ImLocation2 size={20} />,
      //   title: "Location Settings",
      //   path: "/location-settings",
      // },
      // {
      //   icon: <BiGlobe size={20} />,
      //   title: "Website Settings",
      //   path: "/website-settings",
      // },
    ],
  },
];

// Languages Array With Flags
export const languagesWithFlags = [
  {
    value: "EN",
    label: "EN",
    flag: "https://cdn-icons-png.flaticon.com/512/206/206626.png",
  },
];

// Sort By Date Options
export const dateFilterOptions = [
  { label: "Newest to Oldest", value: -1 },
  { label: "Oldest to Newest", value: 1 },
];
// Sort By Date Options

export const typesCrudOptions = [
  { label: "Project Types", value: "project-type", type: "Project" },
  { label: "Event Types", value: "event-type", type: "Event" },
  { label: "Property Types", value: "property-type", type: "Property" },
];

export const amenitiesTypesOptions = [
  { label: "Interior", value: "interior" },
  { label: "Exterior", value: "exterior" },
];

// export const allLanguagesArray = [
//   {
//     name: "Abkhaz",
//   },
//   {
//     name: "Afar",
//   },
//   {
//     name: "Afrikaans",
//   },
//   {
//     name: "Akan",
//   },
//   {
//     name: "Albanian",
//   },
//   {
//     name: "Amharic",
//   },
//   {
//     name: "Arabic",
//   },
//   {
//     name: "Aragonese",
//   },
//   {
//     name: "Armenian",
//   },
//   {
//     name: "Assamese",
//   },
//   {
//     name: "Avaric",
//   },
//   {
//     name: "Avestan",
//   },
//   {
//     name: "Aymara",
//   },
//   {
//     name: "Azerbaijani",
//   },
//   {
//     name: "Bambara",
//   },
//   {
//     name: "Bashkir",
//   },
//   {
//     name: "Basque",
//   },
//   {
//     name: "Belarusian",
//   },
//   {
//     name: "Bengali; Bangla",
//   },
//   {
//     name: "Bihari",
//   },
//   {
//     name: "Bislama",
//   },
//   {
//     name: "Bosnian",
//   },
//   {
//     name: "Breton",
//   },
//   {
//     name: "Bulgarian",
//   },
//   {
//     name: "Burmese",
//   },
//   {
//     name: "Catalan; Valencian",
//   },
//   {
//     name: "Chamorro",
//   },
//   {
//     name: "Chechen",
//   },
//   {
//     name: "Chichewa; Chewa; Nyanja",
//   },
//   {
//     name: "Chinese",
//   },
//   {
//     name: "Chuvash",
//   },
//   {
//     name: "Cornish",
//   },
//   {
//     name: "Corsican",
//   },
//   {
//     name: "Cree",
//   },
//   {
//     name: "Croatian",
//   },
//   {
//     name: "Czech",
//   },
//   {
//     name: "Danish",
//   },
//   {
//     name: "Divehi; Dhivehi; Maldivian;",
//   },
//   {
//     name: "Dutch",
//   },
//   {
//     name: "Dzongkha",
//   },
//   {
//     name: "English",
//   },
//   {
//     name: "Esperanto",
//   },
//   {
//     name: "Estonian",
//   },
//   {
//     name: "Ewe",
//   },
//   {
//     name: "Faroese",
//   },
//   {
//     name: "Fijian",
//   },
//   {
//     name: "Finnish",
//   },
//   {
//     name: "French",
//   },
//   {
//     name: "Fula; Fulah; Pulaar; Pular",
//   },
//   {
//     name: "Galician",
//   },
//   {
//     name: "Georgian",
//   },
//   {
//     name: "German",
//   },
//   {
//     name: "Greek, Modern",
//   },
//   {
//     name: "GuaranÃ­",
//   },
//   {
//     name: "Gujarati",
//   },
//   {
//     name: "Haitian; Haitian Creole",
//   },
//   {
//     name: "Hausa",
//   },
//   {
//     name: "Hebrew (modern)",
//   },
//   {
//     name: "Herero",
//   },
//   {
//     name: "Hindi",
//   },
//   {
//     name: "Hiri Motu",
//   },
//   {
//     name: "Hungarian",
//   },
//   {
//     name: "Interlingua",
//   },
//   {
//     name: "Indonesian",
//   },
//   {
//     name: "Interlingue",
//   },
//   {
//     name: "Irish",
//   },
//   {
//     name: "Igbo",
//   },
//   {
//     name: "Inupiaq",
//   },
//   {
//     name: "Ido",
//   },
//   {
//     name: "Icelandic",
//   },
//   {
//     name: "Italian",
//   },
//   {
//     name: "Inuktitut",
//   },
//   {
//     name: "Japanese",
//   },
//   {
//     name: "Javanese",
//   },
//   {
//     name: "Kalaallisut, Greenlandic",
//   },
//   {
//     name: "Kannada",
//   },
//   {
//     name: "Kanuri",
//   },
//   {
//     name: "Kashmiri",
//   },
//   {
//     name: "Kazakh",
//   },
//   {
//     name: "Khmer",
//   },
//   {
//     name: "Kikuyu, Gikuyu",
//   },
//   {
//     name: "Kinyarwanda",
//   },
//   {
//     name: "Kyrgyz",
//   },
//   {
//     name: "Komi",
//   },
//   {
//     name: "Kongo",
//   },
//   {
//     name: "Korean",
//   },
//   {
//     name: "Kurdish",
//   },
//   {
//     name: "Kwanyama, Kuanyama",
//   },
//   {
//     name: "Latin",
//   },
//   {
//     name: "Luxembourgish, Letzeburgesch",
//   },
//   {
//     name: "Ganda",
//   },
//   {
//     name: "Limburgish, Limburgan, Limburger",
//   },
//   {
//     name: "Lingala",
//   },
//   {
//     name: "Lao",
//   },
//   {
//     name: "Lithuanian",
//   },
//   {
//     name: "Luba-Katanga",
//   },
//   {
//     name: "Latvian",
//   },
//   {
//     name: "Manx",
//   },
//   {
//     name: "Macedonian",
//   },
//   {
//     name: "Malagasy",
//   },
//   {
//     name: "Malay",
//   },
//   {
//     name: "Malayalam",
//   },
//   {
//     name: "Maltese",
//   },
//   {
//     name: "MÄori",
//   },
//   {
//     name: "Marathi (MarÄá¹­hÄ«)",
//   },
//   {
//     name: "Marshallese",
//   },
//   {
//     name: "Mongolian",
//   },
//   {
//     name: "Nauru",
//   },
//   {
//     name: "Navajo, Navaho",
//   },
//   {
//     name: "Norwegian BokmÃ¥l",
//   },
//   {
//     name: "North Ndebele",
//   },
//   {
//     name: "Nepali",
//   },
//   {
//     name: "Ndonga",
//   },
//   {
//     name: "Norwegian Nynorsk",
//   },
//   {
//     name: "Norwegian",
//   },
//   {
//     name: "Nuosu",
//   },
//   {
//     name: "South Ndebele",
//   },
//   {
//     name: "Occitan",
//   },
//   {
//     name: "Ojibwe, Ojibwa",
//   },
//   {
//     name: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
//   },
//   {
//     name: "Oromo",
//   },
//   {
//     name: "Oriya",
//   },
//   {
//     name: "Ossetian, Ossetic",
//   },
//   {
//     name: "Panjabi, Punjabi",
//   },
//   {
//     name: "PÄli",
//   },
//   {
//     name: "Persian (Farsi)",
//   },
//   {
//     name: "Polish",
//   },
//   {
//     name: "Pashto, Pushto",
//   },
//   {
//     name: "Portuguese",
//   },
//   {
//     name: "Quechua",
//   },
//   {
//     name: "Romansh",
//   },
//   {
//     name: "Kirundi",
//   },
//   {
//     name: "Romanian, [])",
//   },
//   {
//     name: "Russian",
//   },
//   {
//     name: "Sanskrit (Saá¹ská¹›ta)",
//   },
//   {
//     name: "Sardinian",
//   },
//   {
//     name: "Sindhi",
//   },
//   {
//     name: "Northern Sami",
//   },
//   {
//     name: "Samoan",
//   },
//   {
//     name: "Sango",
//   },
//   {
//     name: "Serbian",
//   },
//   {
//     name: "Scottish Gaelic; Gaelic",
//   },
//   {
//     name: "Shona",
//   },
//   {
//     name: "Sinhala, Sinhalese",
//   },
//   {
//     name: "Slovak",
//   },
//   {
//     name: "Slovene",
//   },
//   {
//     name: "Somali",
//   },
//   {
//     name: "Southern Sotho",
//   },
//   {
//     name: "Spanish; Castilian",
//   },
//   {
//     name: "Sundanese",
//   },
//   {
//     name: "Swahili",
//   },
//   {
//     name: "Swati",
//   },
//   {
//     name: "Swedish",
//   },
//   {
//     name: "Tamil",
//   },
//   {
//     name: "Telugu",
//   },
//   {
//     name: "Tajik",
//   },
//   {
//     name: "Thai",
//   },
//   {
//     name: "Tigrinya",
//   },
//   {
//     name: "Tibetan Standard, Tibetan, Central",
//   },
//   {
//     name: "Turkmen",
//   },
//   {
//     name: "Tagalog",
//   },
//   {
//     name: "Tswana",
//   },
//   {
//     name: "Tonga (Tonga Islands)",
//   },
//   {
//     name: "Turkish",
//   },
//   {
//     name: "Tsonga",
//   },
//   {
//     name: "Tatar",
//   },
//   {
//     name: "Twi",
//   },
//   {
//     name: "Tahitian",
//   },
//   {
//     name: "Uyghur, Uighur",
//   },
//   {
//     name: "Ukrainian",
//   },
//   {
//     name: "Urdu",
//   },
//   {
//     name: "Uzbek",
//   },
//   {
//     name: "Venda",
//   },
//   {
//     name: "Vietnamese",
//   },
//   {
//     name: "VolapÃ¼k",
//   },
//   {
//     name: "Walloon",
//   },
//   {
//     name: "Welsh",
//   },
//   {
//     name: "Wolof",
//   },
//   {
//     name: "Western Frisian",
//   },
//   {
//     name: "Xhosa",
//   },
//   {
//     name: "Yiddish",
//   },
//   {
//     name: "Yoruba",
//   },
//   {
//     name: "Zhuang, Chuang",
//   },
//   {
//     name: "Zulu",
//   },
// ];

export const allLanguagesArray = [
  {
    en: "Abkhaz",
    ar: "أبخاز",
    fa: "آبخاز",
    de: "Abchasisch",
    tr: "Abhazca",
    ru: "Абхазский",
    fr: "Abkhaze",
  },
  {
    en: "Afar",

    ar: "عفار",
    fa: "عفار",
    de: "Danakil",
    tr: "Afarca",
    ru: "Афарский",
    fr: "Afar",
  },
  {
    en: "Afrikaans",

    ar: "الأفريكانية",
    fa: "آفریکانس",
    de: "Afrikaans",
    tr: "Afrikanca",
    ru: "Африкаанс",
    fr: "Afrikaans",
  },
  {
    en: "Akan",

    ar: "الأكان",
    fa: "آکان",
    de: "Akan",
    tr: "Akan Dili",
    ru: "Акан",
    fr: "Akan",
  },
  {
    en: "Albanian",

    ar: "الألبانية",
    fa: "البانی",
    de: "Albanisch",
    tr: "Arnavutça",
    ru: "Албанский",
    fr: "Albanais",
  },
  {
    en: "Amharic",

    ar: "الأمهرية",
    fa: "امهری",
    de: "Amharisch",
    tr: "Amharca",
    ru: "Амхарский",
    fr: "Amharique",
  },
  {
    en: "Arabic",

    ar: "العربية",
    fa: "عربی",
    de: "Arabisch",
    tr: "Arapça",
    ru: "Арабский",
    fr: "Arabe",
  },
  {
    en: "Aragonese",

    ar: "أراغونية",
    fa: "آراگونی",
    de: "Aragonisch",
    tr: "Aragonca",
    ru: "Арагонский",
    fr: "Aragonais",
  },
  {
    en: "Armenian",

    ar: "الأرمينية",
    fa: "ارمنی",
    de: "Armenisch",
    tr: "Ermenice",
    ru: "Армянский",
    fr: "Arménien",
  },
  {
    en: "Assamese",

    ar: "الأسامية",
    fa: "آسامی",
    de: "Assamesisch",
    tr: "Asamca",
    ru: "Ассамский",
    fr: "Assamais",
  },
  {
    en: "Avaric",

    ar: "الأفاريقية",
    fa: "آواری",
    de: "Awarisch",
    tr: "Avarca",
    ru: "Аварский",
    fr: "Avar",
  },
  {
    en: "Avestan",

    ar: "الأفستي",
    fa: "اوستایی",
    de: "Avestisch",
    tr: "Avestanca",
    ru: "Авестийский",
    fr: "Avestique",
  },
  {
    en: "Aymara",

    ar: "الأيمارا",
    fa: "آیمارا",
    de: "Aymara",
    tr: "Aymara Dili",
    ru: "Аймара",
    fr: "Aymara",
  },
  {
    en: "Azerbaijani",

    ar: "الأذربيجانية",
    fa: "آذربایجانی",
    de: "Aserbaidschanisch",
    tr: "Azerbaycan Dili",
    ru: "Азербайджанский",
    fr: "Azéri",
  },
  {
    en: "Bambara",

    ar: "البامبارا",
    fa: "بامبارا",
    de: "Bambara",
    tr: "Bambara Dili",
    ru: "Бамбара",
    fr: "Bambara",
  },
  {
    en: "Bashkir",

    ar: "الباشكيرية",
    fa: "باشکیری",
    de: "Baschkirisch",
    tr: "Başkurtça",
    ru: "Башкирский",
    fr: "Bachkir",
  },
  {
    en: "Basque",

    ar: "الباسكي",
    fa: "باسک",
    de: "Baskisch",
    tr: "Baskça",
    ru: "Баскский",
    fr: "Basque",
  },
  {
    en: "Belarusian",

    ar: "البيلاروسية",
    fa: "بلاروسی",
    de: "Weißrussisch",
    tr: "Beyaz Rusça",
    ru: "Белорусский",
    fr: "Biélorusse",
  },
  {
    en: "Bengali; Bangla",

    ar: "البنغالية",
    fa: "بنگالی",
    de: "Bengali",
    tr: "Bengalce",
    ru: "Бенгальский",
    fr: "Bengali; Bangla",
  },
  {
    en: "Bihari",

    ar: "البيهارية",
    fa: "بیهاری",
    de: "Bihari",
    tr: "Bihari Dili",
    ru: "Бихари",
    fr: "Bihari",
  },
  {
    en: "Bislama",

    ar: "البيسلاما",
    fa: "بیسلاما",
    de: "Bislama",
    tr: "Bislama Dili",
    ru: "Бислама",
    fr: "Bichelamar",
  },
  {
    en: "Bosnian",

    ar: "البوسنية",
    fa: "بوسنیایی",
    de: "Bosnisch",
    tr: "Bosna Dili",
    ru: "Боснийский",
    fr: "Bosnien",
  },
  {
    en: "Breton",

    ar: "البريتونية",
    fa: "برتون",
    de: "Bretonisch",
    tr: "Bretonca",
    ru: "Бретонский",
    fr: "Breton",
  },
  {
    en: "Bulgarian",

    ar: "البلغارية",
    fa: "بلغاری",
    de: "Bulgarisch",
    tr: "Bulgarca",
    ru: "Болгарский",
    fr: "Bulgare",
  },
  {
    en: "Burmese",

    ar: "البورمية",
    fa: "برمه",
    de: "Burmesisch",
    tr: "Burmaca",
    ru: "Бирманский",
    fr: "Birman",
  },
  {
    en: "Catalan; Valencian",

    ar: "الكتالانية",
    fa: "کاتالانیایی",
    de: "Katalanisch",
    tr: "Katalanca",
    ru: "Каталанский",
    fr: "Catalan",
  },
  {
    en: "Chamorro",

    ar: "التشامورو",
    fa: "چامورو",
    de: "Chamorro",
    tr: "Chamorro Dili",
    ru: "Чаморро",
    fr: "Chamorro",
  },
  {
    en: "Chechen",

    ar: "التشيشن",
    fa: "چچن",
    de: "Tschetschenisch",
    tr: "Çeçen Dili",
    ru: "Чеченский",
    fr: "Tchétchène",
  },
  {
    en: "Chichewa; Chewa; Nyanja",

    ar: "تشيتشوا؛ تشيو؛ نيانجا",
    fa: "چیچو؛ چوا؛ نیانجا",
    de: "Chichewa; Chewa; Nyanja",
    tr: "Chichewa; Chewa; Nyanja Dili",
    ru: "Чичева; Чева; Ньянджа",
    fr: "Chichewa; Chewa; Nyanja",
  },
  {
    en: "Chinese",

    ar: "الصينية",
    fa: "چینی",
    de: "Chinesisch",
    tr: "Çince",
    ru: "Китайский",
    fr: "Chinois",
  },
  {
    en: "Chuvash",

    ar: "التشوفاش",
    fa: "چوواش",
    de: "Tschuwaschisch",
    tr: "Çuvaşça",
    ru: "Чувашский",
    fr: "Tchouvache",
  },
  {
    en: "Cornish",

    ar: "الكورنية",
    fa: "کورنیش",
    de: "Kornisch",
    tr: "Kornca",
    ru: "Корнийский",
    fr: "Cornique",
  },
  {
    en: "Corsican",

    ar: "الكورسيكية",
    fa: "کورسیکایی",
    de: "Korsisch",
    tr: "Korsikaca",
    ru: "Корсиканский",
    fr: "Corse",
  },
  {
    en: "Cree",

    ar: "الكري",
    fa: "کری",
    de: "Cree",
    tr: "Cree Dili",
    ru: "Кри",
    fr: "Cris",
  },
  {
    en: "Croatian",

    ar: "الكرواتية",
    fa: "کرواتی",
    de: "Kroatisch",
    tr: "Hırvatça",
    ru: "Хорватский",
    fr: "Croate",
  },
  {
    en: "Czech",

    ar: "التشيكية",
    fa: "چکی",
    de: "Tschechisch",
    tr: "Çekçe",
    ru: "Чешский",
    fr: "Tchèque",
  },
  {
    en: "Danish",

    ar: "الدنماركية",
    fa: "دانمارکی",
    de: "Dänisch",
    tr: "Danca",
    ru: "Датский",
    fr: "Danois",
  },
  {
    en: "Divehi; Dhivehi; Maldivian;",

    ar: "الديفهي؛ الذيفي؛ المالديفية",
    fa: "دیوهی؛ ذیوهی؛ مالدیوی",
    de: "Dhivehi; Dhivehi; Maledivisch;",
    tr: "Divehi; Dhivehi; Maldivce;",
    ru: "Дивехи; Дивехи; Мальдивский;",
    fr: "Divehi; Dhivehi; Maldivien;",
  },
  {
    en: "Dutch",

    ar: "الهولندية",
    fa: "هلندی",
    de: "Holländisch",
    tr: "Felemenkçe",
    ru: "Голландский",
    fr: "Néerlandais",
  },
  {
    en: "Dzongkha",

    ar: "الزونغكا",
    fa: "ژونگکایی",
    de: "Dzongkha",
    tr: "Dzongkha Dili",
    ru: "Дзонгкха",
    fr: "Dzongkha",
  },
  {
    en: "English",

    ar: "الإنجليزية",
    fa: "انگلیسی",
    de: "Englisch",
    tr: "İngilizce",
    ru: "Английский",
    fr: "Anglais",
  },
  {
    en: "Esperanto",

    ar: "إسبرانتو",
    fa: "اسپرانتو",
    de: "Esperanto",
    tr: "Esperanto Dili",
    ru: "Эсперанто",
    fr: "Espéranto",
  },
  {
    en: "Estonian",

    ar: "الإستونية",
    fa: "استونیایی",
    de: "Estnisch",
    tr: "Estonca",
    ru: "Эстонский",
    fr: "Estonien",
  },
  {
    en: "Ewe",

    ar: "الإيو",
    fa: "اوه",
    de: "Ewe",
    tr: "Ewe Dili",
    ru: "Эве",
    fr: "Éwé",
  },
  {
    en: "Faroese",

    ar: "الفارويز",
    fa: "فاروی",
    de: "Färöisch",
    tr: "Faroece",
    ru: "Фарерский",
    fr: "Féroïen",
  },
  {
    en: "Fijian",

    ar: "فيجي",
    fa: "فیجی",
    de: "Fidschi",
    tr: "Fiji Dili",
    ru: "Фиджи",
    fr: "Fidjien",
  },
  {
    en: "Finnish",

    ar: "الفنلندية",
    fa: "فنلاندی",
    de: "Finnisch",
    tr: "Fince",
    ru: "Финский",
    fr: "Finnois",
  },
  {
    en: "French",

    ar: "الفرنسية",
    fa: "فرانسوی",
    de: "Französisch",
    tr: "Fransızca",
    ru: "Французский",
    fr: "Français",
  },
  {
    en: "Fula; Fulah; Pulaar; Pular",

    ar: "فولاني؛ فولاح؛ بولار؛ بولار",
    fa: "فولا؛ فولاه؛ پولار؛ پولار",
    de: "Fula; Fulah; Pulaar; Pular",
    tr: "Fula; Fulah; Pulaar; Pular Dili",
    ru: "Фула; Фулах; Пулаар; Пуляр",
    fr: "Peul; Fulfulde; Fula; Fulfulde",
  },
  {
    en: "Galician",

    ar: "الغاليسية",
    fa: "گالیسیایی",
    de: "Galizisch",
    tr: "Galiçyaca",
    ru: "Галисийский",
    fr: "Galicien",
  },
  {
    en: "Georgian",

    ar: "الجورجية",
    fa: "گرجی",
    de: "Georgisch",
    tr: "Gürcüce",
    ru: "Грузинский",
    fr: "Géorgien",
  },
  {
    en: "German",

    ar: "الألمانية",
    fa: "آلمانی",
    de: "Deutsch",
    tr: "Almanca",
    ru: "Немецкий",
    fr: "Allemand",
  },
  {
    en: "Greek, Modern",

    ar: "اليونانية الحديثة",
    fa: "یونانی مدرن",
    de: "Neugriechisch",
    tr: "Modern Yunanca",
    ru: "Современный греческий",
    fr: "Grec moderne",
  },
  {
    en: "Guaraní",

    ar: "الغواراني",
    fa: "گوارانی",
    de: "Guaraní",
    tr: "Guaranice",
    ru: "Гуарани",
    fr: "Guarani",
  },
  {
    en: "Gujarati",

    ar: "الغوجاراتية",
    fa: "گجراتی",
    de: "Gujarati",
    tr: "Güceratça",
    ru: "Гуджарати",
    fr: "Gujarati",
  },
  {
    en: "Haitian; Haitian Creole",

    ar: "هائيتي؛ الكريولية الهايتية",
    fa: "هائیتی؛ کریول هائیتی",
    de: "Haitianisch; Haitianisches Kreol",
    tr: "Haiti Kreyolu",
    ru: "Гаитянский; Гаитянский креольский",
    fr: "Haïtien; Créole haïtien",
  },
  {
    en: "Hausa",

    ar: "الهوسا",
    fa: "هوسا",
    de: "Haussa",
    tr: "Hausa Dili",
    ru: "Хауса",
    fr: "Haoussa",
  },
  {
    en: "Hebrew (modern)",

    ar: "العبرية (الحديثة)",
    fa: "عبری (مدرن)",
    de: "Hebräisch (Modern)",
    tr: "Modern İbranice",
    ru: "Современный иврит",
    fr: "Hébreu moderne",
  },
  {
    en: "Herero",

    ar: "الهيريرو",
    fa: "هررو",
    de: "Herero",
    tr: "Herero Dili",
    ru: "Гереро",
    fr: "Herero",
  },
  {
    en: "Hindi",

    ar: "الهندية",
    fa: "هندی",
    de: "Hindi",
    tr: "Hintçe",
    ru: "Хинди",
    fr: "Hindi",
  },
  {
    en: "Hiri Motu",

    ar: "هيري موتو",
    fa: "هیری موتو",
    de: "Hiri Motu",
    tr: "Hiri Motu Dili",
    ru: "Хиримоту",
    fr: "Hiri Motu",
  },
  {
    en: "Hungarian",

    ar: "المجرية",
    fa: "مجارستانی",
    de: "Ungarisch",
    tr: "Macarca",
    ru: "Венгерский",
    fr: "Hongrois",
  },
  {
    en: "Interlingua",

    ar: "الإنترلينغوا",
    fa: "انترلینگوآ",
    de: "Interlingua",
    tr: "Interlingua Dili",
    ru: "Интерлингва",
    fr: "Interlingua",
  },
  {
    en: "Indonesian",

    ar: "الإندونيسية",
    fa: "اندونزیایی",
    de: "Indonesisch",
    tr: "Endonezce",
    ru: "Индонезийский",
    fr: "Indonésien",
  },
  {
    en: "Interlingue",

    ar: "الإنترلينجو",
    fa: "آنترلینگوئه",
    de: "Interlingue",
    tr: "Interlingue Dili",
    ru: "Интерлингве",
    fr: "Interlingue",
  },
  {
    en: "Irish",

    ar: "الإيرلندية",
    fa: "ایرلندی",
    de: "Irisch",
    tr: "İrlandaca",
    ru: "Ирландский",
    fr: "Irlandais",
  },
  {
    en: "Igbo",

    ar: "الإيجبو",
    fa: "ایگبو",
    de: "Igbo",
    tr: "Igbo Dili",
    ru: "Игбо",
    fr: "Igbo",
  },
  {
    en: "Inupiaq",

    ar: "الإينبياك",
    fa: "اینوپیاک",
    de: "Inupiaq",
    tr: "Inupiaq Dili",
    ru: "Инупиак",
    fr: "Inupiaq",
  },
  {
    en: "Ido",

    ar: "الإيدو",
    fa: "ایدو",
    de: "Ido",
    tr: "Ido Dili",
    ru: "Идо",
    fr: "Ido",
  },
  {
    en: "Icelandic",

    ar: "الأيسلندية",
    fa: "ایسلندی",
    de: "Isländisch",
    tr: "İzlandaca",
    ru: "Исландский",
    fr: "Islandais",
  },
  {
    en: "Italian",

    ar: "الإيطالية",
    fa: "ایتالیایی",
    de: "Italienisch",
    tr: "İtalyanca",
    ru: "Итальянский",
    fr: "Italien",
  },
  {
    en: "Inuktitut",

    ar: "الإينوكتيتوت",
    fa: "اینوکتیتوت",
    de: "Inuktitut",
    tr: "Inuktitut Dili",
    ru: "Инуктитут",
    fr: "Inuktitut",
  },
  {
    en: "Japanese",

    ar: "اليابانية",
    fa: "ژاپنی",
    de: "Japanisch",
    tr: "Japonca",
    ru: "Японский",
    fr: "Japonais",
  },
  {
    en: "Javanese",

    ar: "الجاوية",
    fa: "جاوایی",
    de: "Javanisch",
    tr: "Cava Dili",
    ru: "Яванский",
    fr: "Javanais",
  },
  {
    en: "Kalaallisut, Greenlandic",

    ar: "الكالاليست، الجرينلاندي",
    fa: "کالالیسوت، گرینلندی",
    de: "Kalaallisut, Grönländisch",
    tr: "Kalaallisut, Grönlandca",
    ru: "Kalaallisut, Гренландский",
    fr: "Kalaallisut, Groenlandais",
  },
  {
    en: "Kannada",

    ar: "الكانادا",
    fa: "کانادا",
    de: "Kannada",
    tr: "Kannada Dili",
    ru: "Каннада",
    fr: "Kannada",
  },
  {
    en: "Kanuri",

    ar: "الكانوري",
    fa: "کانوری",
    de: "Kanuri",
    tr: "Kanuri Dili",
    ru: "Канури",
    fr: "Kanouri",
  },
  {
    en: "Kashmiri",

    ar: "الكشميرية",
    fa: "کشمیری",
    de: "Kaschmirisch",
    tr: "Keşmirce",
    ru: "Кашмирский",
    fr: "Kashmiri",
  },
  {
    en: "Kazakh",

    ar: "الكازاخية",
    fa: "قزاقی",
    de: "Kasachisch",
    tr: "Kazakça",
    ru: "Казахский",
    fr: "Kazakh",
  },
  {
    en: "Khmer",

    ar: "الخميرية",
    fa: "خمر",
    de: "Khmer",
    tr: "Khmer Dili",
    ru: "Кхмерский",
    fr: "Khmer",
  },
  {
    en: "Kikuyu, Gikuyu",

    ar: "الكيكويو، الجيكويو",
    fa: "کیکویو، گیکویو",
    de: "Kikuyu, Gikuyu",
    tr: "Kikuyu, Gikuyu Dili",
    ru: "Кикуйю; Гикуйю",
    fr: "Kikuyu, Gikuyu",
  },
  {
    en: "Kinyarwanda",

    ar: "الكينياروندا",
    fa: "کینیارواندا",
    de: "Kinyarwanda",
    tr: "Kinyarwanda Dili",
    ru: "Киньяруанда",
    fr: "Kinyarwanda",
  },
  {
    en: "Kyrgyz",

    ar: "القيرغيزية",
    fa: "قرقیز",
    de: "Kirgisisch",
    tr: "Kırgızca",
    ru: "Кыргызский",
    fr: "Kirghize",
  },
  {
    en: "Komi",

    ar: "الكومية",
    fa: "کومی",
    de: "Komi",
    tr: "Komi Dili",
    ru: "Коми",
    fr: "Kom",
  },
  {
    en: "Kongo",

    ar: "الكونغو",
    fa: "کنگو",
    de: "Kongo",
    tr: "Kongo Dili",
    ru: "Конго",
    fr: "Kongo",
  },
  {
    en: "Korean",

    ar: "الكورية",
    fa: "کره ای",
    de: "Koreanisch",
    tr: "Korece",
    ru: "Корейский",
    fr: "Coréen",
  },
  {
    en: "Kurdish",

    ar: "الكردية",
    fa: "کردی",
    de: "Kurdisch",
    tr: "Kürtçe",
    ru: "Курдский",
    fr: "Kurde",
  },
  {
    en: "Kwanyama, Kuanyama",

    ar: "الكوانياما، كوانياما",
    fa: "کوانیاما، کوانیاما",
    de: "Kwanyama, Kuanyama",
    tr: "Kwanyama, Kuanyama Dili",
    ru: "Kwanyama; Kuanyama",
    fr: "Kwanyama, Kuanyama",
  },
  {
    en: "Latin",

    ar: "اللاتينية",
    fa: "لاتین",
    de: "Latein",
    tr: "Latince",
    ru: "Латинский",
    fr: "Latin",
  },
  {
    en: "Luxembourgish, Letzeburgesch",

    ar: "اللوكسمبورغية، ليتزيبورغيش",
    fa: "لوکزامبورگی؛ لوتزامبورگیش",
    de: "Luxemburgisch, Letzeburgesch",
    tr: "Lüksemburgça, Letzeburca",
    ru: "Люксембургский, Летцебургский",
    fr: "Luxembourgeois, Luxembourgeois",
  },
  {
    en: "Ganda",

    ar: "الغاندا",
    fa: "گاندا",
    de: "Ganda",
    tr: "Ganda Dili",
    ru: "Ганда",
    fr: "Luganda",
  },
  {
    en: "Limburgish, Limburgan, Limburger",

    ar: "الليمبورغيش، الليمبورجيش، الليمبورغر",
    fa: "لیمبورگیش؛ لیمبورگان؛ لیمبورگر",
    de: "Limburgisch, Limburgan, Limburger",
    tr: "Limburgish, Limburgan, Limburger Dili",
    ru: "Лимбургский, Лимбурган, Лимбургский",
    fr: "Limbourgeois, Limbourgeois, Limbourgeois",
  },
  {
    en: "Lingala",

    ar: "اللينجالا",
    fa: "لینگالا",
    de: "Lingala",
    tr: "Lingala Dili",
    ru: "Лингала",
    fr: "Lingala",
  },
  {
    en: "Lao",

    ar: "اللاو",
    fa: "لاو",
    de: "Laotisch",
    tr: "Laoca",
    ru: "Лаосский",
    fr: "Laotien",
  },
  {
    en: "Lithuanian",

    ar: "الليتوانية",
    fa: "لیتوانیایی",
    de: "Litauisch",
    tr: "Litvanca",
    ru: "Литовский",
    fr: "Lituanien",
  },
  {
    en: "Luba-Katanga",

    ar: "اللوبا-كاتانجا",
    fa: "لوبا-کاتانگا",
    de: "Luba-Katanga",
    tr: "Luba-Katanga Dili",
    ru: "Луба-Катанга",
    fr: "Luba-Katanga",
  },
  {
    en: "Latvian",

    ar: "اللاتفية",
    fa: "لتونیایی",
    de: "Lettisch",
    tr: "Letonca",
    ru: "Латышский",
    fr: "Letton",
  },
  {
    en: "Manx",

    ar: "الإنسان",
    fa: "منکس",
    de: "Manx",
    tr: "Manxcade",
    ru: "Манкс",
    fr: "Manx",
  },
  {
    en: "Macedonian",

    ar: "المقدونية",
    fa: "مقدونی",
    de: "Mazedonisch",
    tr: "Makedonca",
    ru: "Македонский",
    fr: "Macédonien",
  },
  {
    en: "Malagasy",

    ar: "الملغاشية",
    fa: "مالاگاسی",
    de: "Malagasy",
    tr: "Malgaşça",
    ru: "Малагасийский",
    fr: "Malgache",
  },
  {
    en: "Malay",

    ar: "المالاي",
    fa: "مالایی",
    de: "Malaiisch",
    tr: "Malayca",
    ru: "Малайский",
    fr: "Malais",
  },
  {
    en: "Malayalam",

    ar: "الماليالامية",
    fa: "مالایالم",
    de: "Malayalam",
    tr: "Malayalam Dili",
    ru: "Малаялам",
    fr: "Malayalam",
  },
  {
    en: "Maltese",

    ar: "المالطية",
    fa: "مالتی",
    de: "Maltesisch",
    tr: "Maltaca",
    ru: "Мальтийский",
    fr: "Maltais",
  },
  {
    en: "Māori",

    ar: "الماوري",
    fa: "مائوری",
    de: "Māori",
    tr: "Māori Dili",
    ru: "Маори",
    fr: "Maori",
  },
  {
    en: "Marathi (Marāṭhī)",

    ar: "المراثية (ماراثى)",
    fa: "مراتی (مراٹھی)",
    de: "Marathi (Marāṭhī)",
    tr: "Marathi (Marāṭhī) Dili",
    ru: "Маратхи (Маратхи)",
    fr: "Marathi (Marāṭhī)",
  },
  {
    en: "Marshallese",

    ar: "المارشالية",
    fa: "مارشالی",
    de: "Marshallese",
    tr: "Marshallese Dili",
    ru: "Маршалльский",
    fr: "Marshallais",
  },
  {
    en: "Mongolian",

    ar: "المغولية",
    fa: "مغولی",
    de: "Mongolisch",
    tr: "Moğolca",
    ru: "Монгольский",
    fr: "Mongol",
  },
  {
    en: "Nauru",

    ar: "ناورو",
    fa: "نائورو",
    de: "Nauruisch",
    tr: "Nauru Dili",
    ru: "Науру",
    fr: "Nauruan",
  },
  {
    en: "Navajo, Navaho",

    ar: "النافاجو، النافاهو",
    fa: "ناواهو، ناواهو",
    de: "Navajo, Navaho",
    tr: "Navajo, Navaho Dili",
    ru: "Навахо, Навахо",
    fr: "Navajo, Navaho",
  },
  {
    en: "Norwegian Bokmål",

    ar: "البوكمالية النرويجية",
    fa: "بوکمال نروژی",
    de: "Norwegisch (Bokmål)",
    tr: "Norveççe (Bokmål)",
    ru: "Норвежский (Bokmål)",
    fr: "Norvégien Bokmål",
  },
  {
    en: "North Ndebele",

    ar: "النديبيل الشمالية",
    fa: "ندبله شمالی",
    de: "Nord-Ndebele",
    tr: "Kuzey Ndebele Dili",
    ru: "Северный ндебеле",
    fr: "Ndebele du Nord",
  },
  {
    en: "Nepali",

    ar: "النيبالية",
    fa: "نپالی",
    de: "Nepali",
    tr: "Nepalce",
    ru: "Непали",
    fr: "Népalais",
  },
  {
    en: "Ndonga",

    ar: "الندونجا",
    fa: "ندونگا",
    de: "Ndonga",
    tr: "Ndonga Dili",
    ru: "Ндонга",
    fr: "Ndonga",
  },
  {
    en: "Norwegian Nynorsk",

    ar: "النرويجية النينورسك",
    fa: "نینورسک نروژی",
    de: "Norwegisch (Nynorsk)",
    tr: "Norveççe (Nynorsk)",
    ru: "Норвежский (Nynorsk)",
    fr: "Norvégien Nynorsk",
  },
  {
    en: "Norwegian",

    ar: "النرويجية",
    fa: "نروژی",
    de: "Norwegisch",
    tr: "Norveççe",
    ru: "Норвежский",
    fr: "Norvégien",
  },
  {
    en: "Nuosu",

    ar: "النوسو",
    fa: "نوسو",
    de: "Nuosu",
    tr: "Nuosu Dili",
    ru: "Nuosu",
    fr: "Nuosu",
  },
  {
    en: "South Ndebele",

    ar: "النديبيل الجنوبي",
    fa: "ندبله جنوبی",
    de: "Süd-Ndebele",
    tr: "Güney Ndebele Dili",
    ru: "Южный ндебеле",
    fr: "Ndebele du Sud",
  },
  {
    en: "Occitan",

    ar: "الأوكيتانية",
    fa: "اکسیتانی",
    de: "Okzitanisch",
    tr: "Oksitanca",
    ru: "Окситанский",
    fr: "Occitan",
  },
  {
    en: "Ojibwe, Ojibwa",

    ar: "أوجيبوي، أوجيبوا",
    fa: "اوجیبوو؛ اوجیبووا",
    de: "Ojibwe, Ojibwa",
    tr: "Ojibwe, Ojibwa Dili",
    ru: "Оджибве, Оджибва",
    fr: "Ojibwa, Ojibwa",
  },
  {
    en: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",

    ar: "الكنيسة السلافية القديمة، الكنيسة السلافية، الكنيسة السلافية، البلغارية القديمة، السلافية القديمة",
    fa: "کلیسای قدیمی اسلاوی، کلیسای اسلاوی، کلیسای اسلاوی کلیسای قدیمی بلغارستان، کلیسای اسلاوی قدیمی",
    de: "Altkirchenslawisch, Kirchenslawisch, Kirchenslawisch, Altbulgarisch, Altslawisch",
    tr: "Eski Kilise Slavcası, Kilise Slavcası, Kilise Slavcası, Eski Bulgarca, Eski Slavca",
    ru: "Древнеславянский, Церковнославянский, Церковнославянский, Староболгарский, Старославянский",
    fr: "Vieux slavon d'église, slavon d'église, slavon d'église, vieux bulgare, vieux slavon",
  },
  {
    en: "Oromo",

    ar: "الأورومو",
    fa: "اورومو",
    de: "Oromo",
    tr: "Oromo Dili",
    ru: "Оромо",
    fr: "Oromo",
  },
  {
    en: "Oriya",

    ar: "الأوريا",
    fa: "اوریا",
    de: "Oriya",
    tr: "Oriya Dili",
    ru: "Ория",
    fr: "Oriya",
  },
  {
    en: "Ossetian, Ossetic",

    ar: "الأوسيتية، الأوسيتية",
    fa: "آسامی، آسامی",
    de: "Ossetisch, Ossetisch",
    tr: "Osetçe, Osetçe",
    ru: "Осетинский, Осетинский",
    fr: "Ossète, Ossète",
  },
  {
    en: "Panjabi, Punjabi",

    ar: "البنجابية، البنجابية",
    fa: "پنجابی، پنجابی",
    de: "Pandschabi, Pandschabi",
    tr: "Pencapça, Pencapça",
    ru: "Панджаби, Панджаби",
    fr: "Pendjabi, Pendjabi",
  },

  {
    en: "PÄli",

    ar: "البالي",
    fa: "پالی",
    de: "Pali",
    tr: "Pali Dili",
    ru: "Пали",
    fr: "Pali",
  },
  {
    en: "Persian (Farsi)",

    ar: "الفارسية",
    fa: "فارسی",
    de: "Persisch (Farsi)",
    tr: "Farsça (Farsça)",
    ru: "Персидский (Фарси)",
    fr: "Persan (Farsi)",
  },
  {
    en: "Polish",

    ar: "البولنديّة",
    fa: "لهستانی",
    de: "Polnisch",
    tr: "Lehçe",
    ru: "Польский",
    fr: "Polonais",
  },
  {
    en: "Pashto, Pushto",

    ar: "البشتو",
    fa: "پشتو",
    de: "Paschtu",
    tr: "Peştuca",
    ru: "Пушту (Пушту)",
    fr: "Pachto, Pachto",
  },
  // Continue with the rest of the languages
  {
    en: "Portuguese",

    ar: "البرتغالية",
    fa: "پرتغالی",
    de: "Portugiesisch",
    tr: "Portekizce",
    ru: "Португальский",
    fr: "Portugais",
  },
  {
    en: "Quechua",

    ar: "الكيشوا",
    fa: "کچوا",
    de: "Quechua",
    tr: "Keçuva",
    ru: "Кечуа",
    fr: "Quechua",
  },
  {
    en: "Romansh",

    ar: "الرومانشية",
    fa: "رومانش",
    de: "Rätoromanisch",
    tr: "Romanşça",
    ru: "Романш",
    fr: "Romanche",
  },
  {
    en: "Kirundi",

    ar: "الكيروندي",
    fa: "کیروندی",
    de: "Kirundi",
    tr: "Kirundi Dili",
    ru: "Кирунди",
    fr: "Kirundi",
  },
  {
    en: "Romanian, [])",

    ar: "الرومانيّة",
    fa: "رومانی",
    de: "Rumänisch",
    tr: "Rumence",
    ru: "Румынский",
    fr: "Roumain, [])",
  },
  {
    en: "Russian",

    ar: "الروسية",
    fa: "روسی",
    de: "Russisch",
    tr: "Rusça",
    ru: "Русский",
    fr: "Russe",
  },
  {
    en: "Sanskrit (Saṁskṛta)",

    ar: "السنسكريتية",
    fa: "سانسکریت",
    de: "Sanskrit",
    tr: "Sanskrit Dili",
    ru: "Санскрит",
    fr: "Sanskrit",
  },
  {
    en: "Sardinian",

    ar: "السردينية",
    fa: "ساردینی",
    de: "Sardisch",
    tr: "Sardinya Dili",
    ru: "Сардинский",
    fr: "Sarde",
  },
  {
    en: "Sindhi",

    ar: "السندية",
    fa: "سندی",
    de: "Sindhi",
    tr: "Sindh Dili",
    ru: "Синдхи",
    fr: "Sindhi",
  },
  {
    en: "Northern Sami",

    ar: "الساميّة الشمالية",
    fa: "سامی شمالی",
    de: "Nordsamisch",
    tr: "Kuzey Sami Dili",
    ru: "Северносаамский",
    fr: "Sami du Nord",
  },
  {
    en: "Samoan",

    ar: "الساموائي",
    fa: "ساموایی",
    de: "Samoanisch",
    tr: "Samoanca",
    ru: "Самоанский",
    fr: "Samoan",
  },
  {
    en: "Sango",

    ar: "السانغو",
    fa: "سانگو",
    de: "Sango",
    tr: "Sango Dili",
    ru: "Санго",
    fr: "Sango",
  },
  {
    en: "Serbian",

    ar: "الصربية",
    fa: "صربی",
    de: "Serbisch",
    tr: "Sırpça",
    ru: "Сербский",
    fr: "Serbe",
  },
  {
    en: "Scottish Gaelic; Gaelic",

    ar: "الغيلية الاسكتلندية",
    fa: "غیلی اسکاتلندی",
    de: "Schottisch-Gälisch; Gälisch",
    tr: "İskoç Galcesi; Geylik",
    ru: "Шотландский гэльский; Гэльский",
    fr: "Gaélique écossais; Gaélique",
  },
  {
    en: "Shona",

    ar: "الشونا",
    fa: "شونا",
    de: "Shona",
    tr: "Shona Dili",
    ru: "Шона",
    fr: "Shona",
  },
  {
    en: "Sinhala, Sinhalese",

    ar: "السنهالية",
    fa: "سینهالا",
    de: "Singhalesisch",
    tr: "Singalaca",
    ru: "Сингальский",
    fr: "Cingalais, Singhala",
  },
  {
    en: "Slovak",

    ar: "السلوفاكية",
    fa: "اسلواکی",
    de: "Slowakisch",
    tr: "Slovakça",
    ru: "Словацкий",
    fr: "Slovaque",
  },
  {
    en: "Slovene",

    ar: "السلوفينية",
    fa: "اسلوونی",
    de: "Slowenisch",
    tr: "Slovence",
    ru: "Словенский",
    fr: "Slovène",
  },
  {
    en: "Somali",

    ar: "الصومالية",
    fa: "سومالی",
    de: "Somali",
    tr: "Somalice",
    ru: "Сомалийский",
    fr: "Somali",
  },
  {
    en: "Southern Sotho",

    ar: "السوتو الجنوبية",
    fa: "سوتو جنوبی",
    de: "Sesotho (Süd-Sotho)",
    tr: "Güney Sotho Dili",
    ru: "Сесото (Южный сото)",
    fr: "Sotho du Sud",
  },
  {
    en: "Spanish; Castilian",

    ar: "الإسبانية; الكاستيلانية",
    fa: "اسپانیایی; کاستیلیایی",
    de: "Spanisch; Kastilisch",
    tr: "İspanyolca; Kastilyanca",
    ru: "Испанский; Кастильский",
    fr: "Espagnol; Castillan",
  },
  {
    en: "Sundanese",

    ar: "السوندانية",
    fa: "سوندانی",
    de: "Sundanesisch",
    tr: "Sunda Dili",
    ru: "Сунданский",
    fr: "Soundanais",
  },
  {
    en: "Swahili",

    ar: "السواحيلية",
    fa: "سواحلی",
    de: "Suaheli",
    tr: "Swahili Dili",
    ru: "Суахили",
    fr: "Swahili",
  },
  {
    en: "Swati",

    ar: "السواتي",
    fa: "سواتی",
    de: "Swati",
    tr: "Swati Dili",
    ru: "Свати",
    fr: "Swati",
  },
  {
    en: "Swedish",

    ar: "السويدية",
    fa: "سوئدی",
    de: "Schwedisch",
    tr: "İsveççe",
    ru: "Шведский",
    fr: "Suédois",
  },
  {
    en: "Tamil",

    ar: "التاميل",
    fa: "تامیل",
    de: "Tamil",
    tr: "Tamilce",
    ru: "Тамильский",
    fr: "Tamoul",
  },
  {
    en: "Telugu",

    ar: "التيلوغو",
    fa: "تلوگو",
    de: "Telugu",
    tr: "Telugu Dili",
    ru: "Телугу",
    fr: "Télougou",
  },
  {
    en: "Tajik",

    ar: "الطاجيكية",
    fa: "تاجیک",
    de: "Tadschikisch",
    tr: "Tacik Dili",
    ru: "Таджикский",
    fr: "Tadjik",
  },
  {
    en: "Thai",

    ar: "التايلاندية",
    fa: "تایلندی",
    de: "Thailändisch",
    tr: "Tayca",
    ru: "Тайский",
    fr: "Thaï",
  },
  {
    en: "Tigrinya",

    ar: "التيغرينيا",
    fa: "تیگرینیا",
    de: "Tigrinya",
    tr: "Tigrinya Dili",
    ru: "Тигринья",
    fr: "Tigrigna",
  },
  {
    en: "Tibetan Standard, Tibetan, Central",

    ar: "التبتية القياسية، التبتية الوسطى",
    fa: "تبتی استاندارد، تبتی مرکزی",
    de: "Tibetisch (Standard), Tibetisch (Zentral)",
    tr: "Tibetçe (Standart), Tibetçe (Merkezi)",
    ru: "Тибетский стандартный, Тибетский центральный",
    fr: "Tibétain standard, Tibétain central",
  },
  {
    en: "Turkmen",

    ar: "التركمانية",
    fa: "ترکمن",
    de: "Turkmenisch",
    tr: "Türkmence",
    ru: "Туркменский",
    fr: "Turkmène",
  },
  {
    en: "Tagalog",

    ar: "التاغالوغ",
    fa: "تاگالوگ",
    de: "Tagalog",
    tr: "Tagalog Dili",
    ru: "Тагальский",
    fr: "Tagalog",
  },
  {
    en: "Tswana",

    ar: "التسوانية",
    fa: "تسوانا",
    de: "Tswana",
    tr: "Tswana Dili",
    ru: "Тсвана",
    fr: "Tswana",
  },
  {
    en: "Tonga (Tonga Islands)",

    ar: "التونغية (جزر تونغا)",
    fa: "تونگایی (جزایر تونگا)",
    de: "Tongaisch (Tonga-Inseln)",
    tr: "Tonga Dili (Tonga Adaları)",
    ru: "Тонганский (Острова Тонга)",
    fr: "Tongien (Îles Tonga)",
  },
  {
    en: "Turkish",

    ar: "التركية",
    fa: "ترکی",
    de: "Türkisch",
    tr: "Türkçe",
    ru: "Турецкий",
    fr: "Turc",
  },
  {
    en: "Tsonga",

    ar: "التسونجا",
    fa: "تسونگا",
    de: "Tsonga",
    tr: "Tsonga Dili",
    ru: "Тсонга",
    fr: "Tsonga",
  },
  {
    en: "Tatar",

    ar: "التتارية",
    fa: "تاتار",
    de: "Tatarisch",
    tr: "Tatarca",
    ru: "Татарский",
    fr: "Tatar",
  },
  {
    en: "Twi",

    ar: "التوي",
    fa: "تویی",
    de: "Twi",
    tr: "Twi Dili",
    ru: "Тви",
    fr: "Twi",
  },
  {
    en: "Tahitian",

    ar: "التاهيتي",
    fa: "تاهیتی",
    de: "Tahitianisch",
    tr: "Tahiti Dili",
    ru: "Таитянский",
    fr: "Tahitien",
  },
  {
    en: "Uyghur, Uighur",

    ar: "الأويغورية",
    fa: "آیغور",
    de: "Uigurisch",
    tr: "Uygurca",
    ru: "Уйгурский",
    fr: "Ouïgour",
  },
  {
    en: "Ukrainian",

    ar: "الأوكرانية",
    fa: "اوکراینی",
    de: "Ukrainisch",
    tr: "Ukraynaca",
    ru: "Украинский",
    fr: "Ukrainien",
  },
  {
    en: "Urdu",

    ar: "الأردية",
    fa: "اردو",
    de: "Urdu",
    tr: "Urdu Dili",
    ru: "Урду",
    fr: "Ourdou",
  },
  {
    en: "Uzbek",

    ar: "الأوزبكية",
    fa: "ازبک",
    de: "Usbekisch",
    tr: "Özbekce",
    ru: "Узбекский",
    fr: "Ouzbek",
  },
  {
    en: "Venda",

    ar: "الفندية",
    fa: "وندا",
    de: "Venda",
    tr: "Venda Dili",
    ru: "Венда",
    fr: "Venda",
  },
  {
    en: "Vietnamese",

    ar: "الفيتنامية",
    fa: "ویتنامی",
    de: "Vietnamesisch",
    tr: "Vietnamca",
    ru: "Вьетнамский",
    fr: "Vietnamien",
  },
  {
    en: "Volapük",

    ar: "الفولابوك",
    fa: "ولاپوک",
    de: "Volapük",
    tr: "Volapük Dili",
    ru: "Волапюк",
    fr: "Volapük",
  },
  {
    en: "Walloon",

    ar: "الوالون",
    fa: "والون",
    de: "Wallonisch",
    tr: "Walloon Dili",
    ru: "Валлонский",
    fr: "Wallon",
  },
  {
    en: "Welsh",

    ar: "الويلزية",
    fa: "ولزی",
    de: "Walisisch",
    tr: "Galce",
    ru: "Уэльский",
    fr: "Gallois",
  },
  {
    en: "Wolof",

    ar: "الولوف",
    fa: "ولوف",
    de: "Wolof",
    tr: "Wolof Dili",
    ru: "Волоф",
    fr: "Wolof",
  },
  {
    en: "Western Frisian",

    ar: "الفريزي الغربي",
    fa: "فريزي غربي",
    de: "Friesisch",
    tr: "Batı Frizcesi",
    ru: "Западнофризский",
    fr: "Frison occidental",
  },
  {
    en: "Xhosa",

    ar: "الخوسا",
    fa: "خوسا",
    de: "Xhosa",
    tr: "Xhosa Dili",
    ru: "Коса",
    fr: "Xhosa",
  },
  {
    en: "Yiddish",

    ar: "اليديش",
    fa: "یدیش",
    de: "Jiddisch",
    tr: "Yidiş",
    ru: "Идиш",
    fr: "Yiddish",
  },
  {
    en: "Yoruba",

    ar: "اليوروبا",
    fa: "یوروبا",
    de: "Yoruba",
    tr: "Yoruba Dili",
    ru: "Йоруба",
    fr: "Yoruba",
  },
  {
    en: "Zhuang, Chuang",

    ar: "الزهانغ، تشوانغ",
    fa: "چوانگ، چوآنگ",
    de: "Zhuang",
    tr: "Zhuang Dili",
    ru: "Чжуанский, Чуанг",
    fr: "Zhuang, Chuang",
  },
  {
    en: "Zulu",

    ar: "الزولو",
    fa: "زولو",
    de: "Zulu",
    tr: "Zulu Dili",
    ru: "Зулу",
    fr: "Zoulou",
  },
];

export const packageDurationOptions = [
  { label: "1 Month", value: 1 },
  { label: "3 Months", value: 3 },
  { label: "6 Months", value: 6 },
  { label: "12 Months", value: 12 },
];

export const languagesOptions = [
  { label: "English", value: "en", flag: EnglishLanguage },
  { label: "Farsi", value: "fa", flag: PersianLanguage },
  { label: "Turkish", value: "tr", flag: TurkishLanguage },
  { label: "Arabic", value: "ar", flag: ArabicLanguage },
  { label: "Russian", value: "ru", flag: RussianLanguage },
  { label: "German", value: "de", flag: GermanLanguage },
  { label: "French", value: "fr", flag: FrenchLanguage },
];

export const activeDeactiveOptions = [
  { label: "All", value: "all" },
  { label: "Active", value: true },
  { label: "Deactive", value: false },
];

export const companyTypeOptions = [
  {
    en: "Real Estate Company",
    ar: "شركة عقارية",
    fa: "شرکت مشاور املاک",
    de: "Immobilienunternehmen",
    tr: "Emlak Şirketi",
    ru: "Компания по недвижимости",
    fr: "Société immobilière",
  },
  {
    en: "Real Estate Developer",
    ar: "مطور عقاري",
    fa: "توسعه دهنده املاک",
    de: "Immobilienentwickler",
    tr: "Emlak Geliştirici",
    ru: "Застройщик недвижимости",
    fr: "Promoteur immobilier",
  },
  {
    en: "Construction Company",
    ar: "شركة مقاولات",
    fa: "شرکت ساخت و ساز",
    de: "Bauunternehmen",
    tr: "İnşaat Şirketi",
    ru: "Строительная компания",
    fr: "Entreprise de construction",
  },
  {
    en: "Marketing & Advertising Company",
    ar: "شركة تسويق وإعلان",
    fa: "شرکت بازاریابی و تبلیغات",
    de: "Marketing- und Werbeagentur",
    tr: "Pazarlama ve Reklam Şirketi",
    ru: "Маркетинговая и рекламная компания",
    fr: "Société de marketing et de publicité",
  },
];
