import Popover from "@mui/material/Popover";
import classes from "./CustomPopover.module.css";

// export default function CustomPopover({
//   identifier = Math.floor(Math.random() * 100000).toString(),
//   Icon,
//   text = "",
//   Component,
//   TriggerComp,
//   anchorOrigin = {
//     vertical: "bottom",
//     horizontal: "right",
//   },
//   transformOrigin = {
//     vertical: "top",
//     horizontal: "right",
//   },
//   className = "",
//   iconBtnClass = "",
//   popoverRef = null,
//   anchorEl = null,
//   setAnchorEl,
//   handleClose,
//   ...props
// }) {
//   // const [anchorEl, setAnchorEl] = useState(null);

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const onClose = () => {
//     handleClose ? handleClose(null) : setAnchorEl(null);
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? `simple-popover-${identifier}` : undefined;

//   return (
//     <>
//       <style>
//         {`
//             .${classes?.bxShadow} .MuiPaper-elevation.MuiPaper-rounded {
//               box-shadow:0px 5px 5px -3px rgb(0 0 0 / 0%), 0px 8px 10px 1px rgb(0 0 0 / 3%), 0px 3px 14px 2px rgb(0 0 0 / 2%) !important;
//             }

//             `}
//       </style>
//       <div>
//         <div aria-describedby={id} onClick={handleClick}>
//           {TriggerComp ? (
//             <TriggerComp open={open} />
//           ) : (
//             <IconButton
//               text={text}
//               Icon={Icon}
//               anchorEl={anchorEl}
//               btnClass={iconBtnClass}
//             />
//           )}
//         </div>
//         <Popover

//           anchorReference={popoverRef}
//           id={id}
//           open={open}
//           anchorEl={anchorEl}
//           onClose={onClose}
//           anchorOrigin={anchorOrigin}
//           transformOrigin={transformOrigin}
//           className={`${classes?.bxShadow} ${className && className}`}
//           disablePortal

//           {...props}
//         >
//           {Component}
//         </Popover>
//       </div>
//     </>
//   );
// }

import React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
// import classes from "./PopperComponent.module.css";

const PoperComponent = ({
  setOpen,
  open,
  anchorRef,
  data,
  handleClick,
  isCloseOnClick = true,
  placement = "bottom-start",
  Component,
}) => {
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      placement={placement}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "center-start" ? "center center" : "center center",
          }}
        >
          <Paper className={[classes.popperDiv]}>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              {Component ? (
                Component
              ) : (
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {data?.map((item, i) => {
                    return (
                      <MenuItem
                        onClick={() => {
                          handleClick(item);
                          isCloseOnClick && setOpen(false);
                        }}
                        key={i}
                      >
                        {item}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              )}
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default PoperComponent;

export const IconButton = ({
  Icon,
  text,
  anchorEl,
  btnClass = "",
  onClick = () => null,
}) => {
  const isOpen = Boolean(anchorEl);
  return (
    <div
      className={`${classes?.iconButtonMain} ${btnClass} ${
        isOpen && classes?.iconButtonActive
      }`}
      onClick={onClick}
    >
      {Icon}
      <p>{text}</p>
    </div>
  );
};

// import React from 'react';
// import ClickAwayListener from '@mui/material/ClickAwayListener';
// import Grow from '@mui/material/Grow';
// import Popper from '@mui/material/Popper';
// import MenuItem from '@mui/material/MenuItem';
// import MenuList from '@mui/material/MenuList';
// import Paper from '@mui/material/Paper';
// import classes from './CustomPopover.module.css';

// const PoperComponent = ({
//   setOpen,
//   open,
//   anchorRef,
//   data,
//   handleClick,
//   isCloseOnClick = true,
// }) => {
//   function handleListKeyDown(event) {
//     if (event.key === 'Tab') {
//       event.preventDefault();
//       setOpen(false);
//     } else if (event.key === 'Escape') {
//       setOpen(false);
//     }
//   }

//   const prevOpen = React.useRef(open);
//   React.useEffect(() => {

//     prevOpen.current = open;
//   }, [open]);

//   return (
//     <Popper
//       open={open}
//       anchorEl={anchorRef.current}
//       role={undefined}
//       placement='bottom-start'
//       transition
//       disablePortal
//       className={classes.popper}
//     >
//       {({ TransitionProps, placement }) => (
//         <Grow
//           {...TransitionProps}
//           style={{
//             transformOrigin:
//               placement === 'center-start' ? 'center center' : 'center center',
//           }}
//         >
//           <Paper className={[classes.popperDiv]}>
//             <ClickAwayListener onClickAway={() => setOpen(false)}>
//               <MenuList
//                 autoFocusItem={open}
//                 id='composition-menu'
//                 aria-labelledby='composition-button'
//                 onKeyDown={handleListKeyDown}
//               >
//                 {data?.map((item, i) => {
//                   return (
//                     <MenuItem
//                       onClick={() => {
//                         handleClick(item);
//                         isCloseOnClick && setOpen(false);
//                       }}
//                       key={i}
//                     >
//                       {item}
//                     </MenuItem>
//                   );
//                 })}
//               </MenuList>
//             </ClickAwayListener>
//           </Paper>
//         </Grow>
//       )}
//     </Popper>
//   );
// };

// export default PoperComponent;
