import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button } from "../../Component/Button/Button";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./AddCSVModal.module.css";
import { toast } from "react-toastify";
import UploadCsvBox from "../../Component/UploadCsvBox";
import Papa from "papaparse";
import DropDown from "../../Component/DropDown/DropDown";
import { useSelector } from "react-redux";
import {
  useLazyGetAllProvincesQuery,
  useLazyGetAllTownsQuery,
} from "../../store/apiSlice/apiSlice";
import UploadExcelBox from "../../Component/UploadExcelBox";

const AddCSVModal = ({
  show,
  setShow,
  onClick,
  loading,
  title = "Add Excel File",
  dropdownlabel,
  dropdownPlaceholder,
  dataArray,
  type = "",
}) => {
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const [file, setFile] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const [
    getAllProvinces,
    { isLoading: isGettingProvinces, currentData: updatedProvinces },
  ] = useLazyGetAllProvincesQuery();
  const [
    getAllTowns,
    { isLoading: isGettingTowns, currentData: updatedTowns },
  ] = useLazyGetAllTownsQuery();

  useEffect(() => {
    if (type == "town") {
      getAllProvinces();
    }
    if (type == "neighborhood") {
      getAllTowns();
    }
  }, []);

  const HandleSubmitUpload = async () => {
    // Papa.parse(csvFile, {
    //   header: true,
    //   complete: async function (results) {
    //     console.log(results);
    //     let params = {
    //       csvFile: results?.data,
    //       ref: selectedItem?._id,
    //     };
    //     console.log(params, "params params params");
    //     // await onClick(params);
    //   },
    // });

    let params = {
      file,
      ...(dataArray && { ref: selectedItem?._id }),
    };
    for (let key in params) {
      if (params[key] == "" || params[key] == null) {
        return toast.error(`Please fill the ${key} field`);
      }
    }
    await onClick(params);
  };

  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      borderRadius={"20px"}
      modalClass={classes.modal}
      width={"650px"}
      header={title}
      headerStyles={{ textTransform: "capitalize" }}
    >
      <Row>
        {type && (
          <Col md={12} className={classes.inputCol}>
            <DropDown
              label={dropdownlabel}
              placeholder={dropdownPlaceholder}
              options={
                type == "town" ? updatedProvinces?.data : updatedTowns?.data
              }
              indicatorColor="var(--dashboard-text-gray-clr)"
              variant="dashboard"
              optionValue={"_id"}
              value={selectedItem}
              setValue={setSelectedItem}
              getOptionLabel={(e) => e?.name?.[selectedLanguage]}
              disabled={type == "town" ? isGettingProvinces : isGettingTowns}
            />
          </Col>
        )}
        <Col md={12} className={classes.inputCol}>
          <UploadExcelBox state={file} setter={setFile} />
        </Col>

        <Col md={12} className={classes.btnCol}>
          <Button
            borderRadius={"8px"}
            customStyle={{
              margin: "0 auto",
            }}
            disabled={loading}
            onClick={HandleSubmitUpload}
          >
            {loading ? "Uploading..." : "Upload"}
          </Button>
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default AddCSVModal;
