import React, { useRef } from "react";
import { MdModeEdit } from "react-icons/md";
import { RiDeleteBinLine, RiFileExcel2Fill } from "react-icons/ri";
import { FiEye } from "react-icons/fi";
import classes from "./UploadExcelBox.module.css";
import { FaFileCsv } from "react-icons/fa";
import { toast } from "react-toastify";

function UploadExcelBox({ state, setter, className = "",  }) {
  const inputRef = useRef(null);
  const HandleUploadFile = (e) => {
    const fileName = e.target?.files[0]?.name;
    const fileExtension = fileName?.split('.')?.pop()?.toLowerCase();
  
  
    // console.log(e.target.files[0].type, "e.target.files[0].type");
    // given file is not csv return error
    // if (e.target.files[0].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
    //   return toast.error("Please upload XLSX files only");
    // }
    if (fileExtension !== "xlsx") {
      return toast.error("Please upload XLSX files only");
    }
    setter(e.target.files[0]);
  };

  return (
    <div className={`${classes.box} ${className}`}>
      {state?.name ? (
        <div className={classes.csvBox}>
          <FaFileCsv color={"var(--secondary-color)"} size={60} />
          <span className={classes?.uploadText}>{state?.name}</span>
          <div className={classes.editAndDelete}>
            <>
              <div
                className={`${classes.icon} ${classes.viewIcon}`}
                onClick={() => {
                  window.open(URL.createObjectURL(state), "_blank");
                }}
              >
                <FiEye />
              </div>
              <div className={classes.editAndDeleteBtns}>
                <div
                  className={classes.icon}
                  onClick={() => {
                    setter(null);
                  }}
                >
                  <RiDeleteBinLine />
                </div>
                <div
                  className={classes.icon}
                  onClick={() => inputRef.current.click()}
                >
                  <MdModeEdit />
                </div>
              </div>
            </>
          </div>
        </div>
      ) : (
        <div
          className={classes.csvBox}
          onClick={() => {
            inputRef.current.click();
          }}
        >
          <>
            <RiFileExcel2Fill  color={"var(--secondary-color)"} size={50} />
            <span className={classes?.uploadText}>Upload File</span>
          </>
        </div>
      )}
      {/* Input For Image Upload */}
      <input
        hidden
        type={"file"}
        ref={inputRef}
        onChange={(e) => HandleUploadFile(e)}
        accept=".xlsx"
      />
    </div>
  );
}

export default UploadExcelBox;
