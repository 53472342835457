/* eslint-disable */

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import UploadImageBox from "../../Component/UploadImageBox";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./AdBannerViewModal.module.css";

const pageNameList = [
  {
    label: "Home",
    pathName: "home",
  },
  {
    label: "Buy",
    pathName: "buy",
  },
  {
    label: "Buy Detail",
    pathName: "buy-detail",
  },
  {
    label: "Rent",
    pathName: "rent",
  },
  {
    label: "Rent Detail",
    pathName: "rent-detail",
  },
  {
    label: "Projects",
    pathName: "projects",
  },
  {
    label: "Project Detail",
    pathName: "project-detail",
  },
  {
    label: "Events",
    pathName: "events",
  },
  {
    label: "Event Detail",
    pathName: "event-detail",
  },
  {
    label: "Agents",
    pathName: "agents",
  },
  {
    label: "Agent Detail",
    pathName: "agent-detail",
  },
  {
    label: "Companies",
    pathName: "companies",
  },
  {
    label: "Company Detail",
    pathName: "company-detail",
  },
  {
    label: "Blogs",
    pathName: "blogs",
  },
  {
    label: "Blog Detail",
    pathName: "blog-detail",
  },
];

const AdBannerViewModal = ({ show, setShow, editData }) => {
  const [bannerImage, setBannerImage] = useState(editData?.image ?? null);
  const [bannerName, setBannerName] = useState("" || editData?.name);
  const [pageName, setPageName] = useState(
    pageNameList?.find((item) => item?.pathName == editData?.page) ?? null
  );

  const [startDateTime, setStartDateTime] = useState(
    moment(editData?.startDate).format("YYYY-MM-DD")
  );
  const [endDateTime, setEndDateTime] = useState(
    moment(editData?.endDate).format("YYYY-MM-DD")
  );
  const [bannerType, setBannerType] = useState(
    bannerTypeOptions.find((item) => item?.value == editData?.bannerType)
  );

  useEffect(() => {
    if (bannerImage instanceof File) {
      setIsPreviewEnabled(true);
    }
  }, [bannerImage]);

  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      modalClass={classes.main}
      borderRadius={"0px"}
      width={"1150px"}
      header={true}
      showCloseIcon={false}
      headerClass={classes.header}
    >
      <div className={classes.main}>
        {/* Default View */}
        <>
          <div className={classes?.breadCrumb}>
            <span className={classes?.breadCrumbText}>View Banner</span>
          </div>
          <Row
            className={[
              classes.row,
              classes?.paddingContainer,
              "g-0",
              classes,
            ].join(" ")}
          >
            {bannerImage && (
              <Col
                md={12}
                className={[classes.upload_img_cus, "mb-5"].join(" ")}
              >
                <UploadImageBox value={bannerImage} edit={false} />
              </Col>
            )}

            <Col md={12} className={classes?.col1}>
              <span className={classes.labelText}>
                Banner Name :{" "}
                <span className={classes.labelValue}>{bannerName}</span>
              </span>
            </Col>

            {pageName && (
              <Col md={12} className={classes?.col1}>
                <span className={classes.labelText}>
                  Page Name :{" "}
                  <span className={classes.labelValue}>{pageName.label}</span>
                </span>
              </Col>
            )}

            <Col md={4} className={classes?.col1}>
              <span className={classes.labelText}>
                Start Display Date :{" "}
                <span className={classes.labelValue}>
                  {startDateTime.toString()}
                </span>
              </span>
            </Col>
            <Col md={4} className={classes?.col1}>
              <span className={classes.labelText}>
                End Display Date :{" "}
                <span className={classes.labelValue}>
                  {endDateTime.toString()}
                </span>
              </span>
            </Col>
            <Col md={4} className={classes?.col1}>
              <span className={classes.labelText}>
                Banner Type :{" "}
                <span className={classes.labelValue}>{bannerType.label}</span>
              </span>
            </Col>
          </Row>
        </>
      </div>
    </ModalSkeleton>
  );
};

export default AdBannerViewModal;

const bannerTypeOptions = [
  { label: "Vertical", value: "vertical" },
  { label: "Horizontal", value: "horizontal" },
];
