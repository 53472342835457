import React, { useState } from "react";
import classes from "./SideBar.module.css";
import { TbLogout } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { signOutRequest } from "../../store/auth/authSlice";
// import { useSelector } from 'react-redux';
import {
  RiArrowDownSFill,
  RiArrowUpSFill,
  // RiSettings5Fill,
} from "react-icons/ri";
import { sidebarOptions } from "../../constant/commonData";

const RenderItem = ({ icon, title, subMenu = [], path }) => {
  const active = useLocation()?.pathname;
  const [subnav, setSubnav] = useState(false);
  const subActive = subMenu.find((item) => item?.path == active);
  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <Link
        className={[
          classes?.listItemContainer,
          path == active && classes?.active,
          subActive && classes?.subActive,
          subnav && classes?.marginZero,
        ].join(" ")}
        to={subMenu?.length > 0 ? "#" : path}
        onClick={() => {
          if (subMenu?.length > 0) {
            showSubnav(!subnav);
          }
        }}
      >
        {icon}
        <span>{title}</span>
        {subMenu?.length > 0 &&
          (subnav ? (
            <RiArrowUpSFill
              size={20}
              color={"white"}
              className={classes?.dropDownIcon}
            />
          ) : (
            <RiArrowDownSFill
              size={20}
              color={"white"}
              className={classes?.dropDownIcon}
            />
          ))}
      </Link>
      {subnav &&
        subMenu.map((item, index) => {
          return (
            <Link
              className={[
                classes?.innerItemContainer,
                item?.path == active && classes?.active,
              ].join(" ")}
              data-active={item?.path == active}
              key={index}
              to={item?.path}
            >
              {item?.icon}
              <span>{item.title}</span>
            </Link>
          );
        })}
    </>
  );
};

const SideBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const { user: userData, fcmToken } = useSelector((state) => state?.authReducer);

  const HandleSubmitSignOut = () => {
    dispatch(signOutRequest());
    navigate("/");
  };
  const sidebarOptionAccToRole = sidebarOptions

  return (
    <div className={classes?.mainContainer}>
      <div className={classes.itemsContainer}>
        {sidebarOptionAccToRole?.map((item, key) => (
          <RenderItem
            icon={item?.icon}
            title={item?.title}
            path={item?.path}
            key={`sidebar-option-${key}`}
            subMenu={item?.subMenu}
          />
        ))}
        <div
          className={[classes?.listItemContainer].join(" ")}
          onClick={() => {
            HandleSubmitSignOut();
          }}
        >
          <TbLogout size={20} color={"var(--sidebar-text-clr)"} />
          <span>Logout</span>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
