/* eslint-disable */

import React, { useState } from "react";
import classes from "./ProvincesCrud.module.css";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import CustomMaterialTable from "../../Component/CustomMaterialTable";
import { Button } from "../../Component/Button/Button";
import SearchInput from "../../Component/SearchInput";
import {
  useActiveDeactiveLocationWithTypeMutation,
  useDeleteLocationWithTypeMutation,
  useGetAllProvincesQuery,
  useLazyGetLocationCSVByTypeQuery,
  useUploadLocationCSVMutation,
} from "../../store/apiSlice/apiSlice";
import useDebounce from "../../CustomHooks/useDebounce";
import { BaseURL, apiHeader, recordsLimit } from "../../config/apiUrl";
import moment from "moment";
import AreYouSureModal from "../../modals/AreYouSureModal";
import { toast } from "react-toastify";
import { FiEdit } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import AddOrEditProvinceModal from "../../modals/AddOrEditProvinceModal";
import DropDown from "../../Component/DropDown/DropDown";
import { activeDeactiveOptions } from "../../constant/commonData";
import { useTranslation } from "react-i18next";
import AddCSVModal from "../../modals/AddCSVModal";
import LocationDemo from "../../assets/location-demo.xlsx";
import { FileDownloadFromUrl } from "../../Helper/FileDownloadFromLink";

function ProvincesCrud() {
  const { accessToken } = useSelector((state) => state.authReducer);
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const { t } = useTranslation("locationComp");
  const [searchText, setSearchText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState("");
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(activeDeactiveOptions[0]);

  const [selectedItem, setSelectedItem] = useState(null);
  const debounceSearch = useDebounce(searchText, 500);
  const [isDeletingCSV, setIsDeletingCSV] = useState(false);
  const {
    data,
    isLoading,
    isFetching,
    refetch: refetchAllProvince,
  } = useGetAllProvincesQuery({
    limit: recordsLimit,
    page: page,
    search: debounceSearch,
    lan: selectedLanguage,
    status: status?.value,
  });

  const [deleteItem, { isLoading: isDeleting }] =
    useDeleteLocationWithTypeMutation();
  const [activeDeactiveApi, { isLoading: isStatusLoading }] =
    useActiveDeactiveLocationWithTypeMutation();
  const [uploadCSV, { isLoading: isUploadingCSV }] =
    useUploadLocationCSVMutation();

  const deleteMultiple = async () => {
    const res = await deleteItem({
      ids: selectedRows?.map((item) => item._id),
      type: "Province",
    });
    if (res?.data) {
      toast.success(`Province deleted successfully`);
      setSelectedRows([]);
    }
  };

  const downloadCSV = async () => {
    setIsDeletingCSV(true);

    await FileDownloadFromUrl(
      BaseURL("location/download/file?type=province"),
      "provinces.xlsx",
      apiHeader(accessToken)
    );
    setIsDeletingCSV(false);
  };

  const headings = [
    {
      id: "createdAt",
      label: "CREATION DATE",
      renderValue: (item, col) => {
        return moment(item[col?.id]).format("DD/MM/YYYY hh:mm A");
      },
    },

    {
      id: "name",
      label: "Name",
      renderValue: (item, col) => {
        return <p className="text1Line">{item[col?.id]?.[selectedLanguage]}</p>;
      },
    },
    {
      id: "isActive",
      label: "STATUS",
      renderValue: (item, col) => (item[col?.id] ? "Active" : "Deactive"),
    },

    { id: "options", label: "OPTIONS", align: "center" },
  ];

  const HandleUploadCSV = async (params) => {
    let updatedParams = {
      ...params,
      type: "province",
    };
    const formData = new FormData();
    for (let key in updatedParams) {
      formData.append(key, updatedParams[key]);
    }

    const res = await uploadCSV(formData);

    if (res?.data) {
      const { numberOfSuccess = 0, numberOfFail = 5 } = res?.data;
      toast.success(
        `Province XLSX ${numberOfSuccess} records added and ${numberOfFail} records neglected successfully`
      );
      setIsModalOpen("");
      await refetchAllProvince();
    }
  };

  return (
    <SideBarSkeleton>
      <Container fluid className={classes.page}>
        <Row>
          <Col md={12} className={""}>
            <div className={classes.header}>
              <div className={classes.left}>
                <SearchInput
                  placeholder="Search by name"
                  setter={setSearchText}
                  value={searchText}
                  variant="dashboard"
                  containerStyle={{ width: "300px" }}
                />
                <DropDown
                  label={"Status"}
                  containerClassName={classes.dropDownContainer}
                  customStyle={{
                    padding: "0px 6px",
                    minHeight: "40px",
                    minWidth: "200px",
                  }}
                  options={activeDeactiveOptions}
                  setValue={(e) => {
                    setSelectedRows([]);
                    setStatus(e);
                  }}
                  value={status}
                  placeholder={"Select Status"}
                />
              </div>
              <div className={classes.headerRight}>
                <Button
                  borderRadius={"8px"}
                  className={classes.headerBtn}
                  onClick={() => {
                    setIsModalOpen("upload-csv");
                  }}
                >
                  Upload Excel File
                </Button>
                <Button
                  borderRadius={"8px"}
                  className={classes.deleteBtn}
                  disabled={selectedRows?.length == 0}
                  onClick={deleteMultiple}
                >
                  {isDeleting ? "Deleting..." : "Delete"}
                </Button>
                <Button
                  borderRadius={"8px"}
                  className={classes.headerBtn}
                  onClick={() => {
                    setSelectedItem(null);
                    setIsModalOpen("add-edit");
                  }}
                >
                  Create
                </Button>
              </div>
            </div>
          </Col>
          <Col
            md={12}
            className={[classes?.headingAndBtns, classes.my30].join(" ")}
          >
            <h5 className={classes.heading}>Provinces</h5>
            <div className={classes?.btns}>
              <Button
                borderRadius={"8px"}
                className={classes.headerBtn}
                onClick={() => {
                  const a = document.createElement("a");
                  a.href = LocationDemo;
                  a.download = "provinces-demo.xlsx";
                  a.click();
                }}
              >
                Download Sample
              </Button>
              <Button
                borderRadius={"8px"}
                className={classes.headerBtn}
                onClick={() => {
                  downloadCSV();
                }}
                disabled={isDeletingCSV}
              >
                {isDeletingCSV ? "Downloading..." : `Download File`}
              </Button>
            </div>
          </Col>
          <Col md={12}>
            <CustomMaterialTable
              headings={headings}
              data={data?.data}
              showOptionsColumn
              isLoading={isLoading || isFetching}
              totalRecords={Math.ceil(data?.totalRecords / recordsLimit)}
              noDataText={`No Provinces Found`}
              page={page}
              setPage={setPage}
              options={[
                {
                  label: "Edit",
                  icon: <FiEdit size={18} />,
                  onClick: (_, item) => {
                    setSelectedItem(item);
                    setIsModalOpen("add-edit");
                  },
                },
                {
                  label: (e) => (e?.isActive ? "Deactivate" : "Activate"),
                  onClick: (_, e) => {
                    setSelectedItem(e);
                    setIsModalOpen("active-deactive");
                  },
                  switch: true,
                },
              ]}
              selectedRows={selectedRows}
              setSelectedRows={(e) => {
                const newArray = [...selectedRows];
                newArray?.splice(0, 1, e[e?.length - 1]);
                setSelectedRows(newArray);
              }}
              allowMultipleSelection
            />
          </Col>
        </Row>
      </Container>

      {isModalOpen == "upload-csv" && (
        <AddCSVModal
          show={isModalOpen == "upload-csv"}
          setShow={setIsModalOpen}
          title={t("Add Province Excel File")}
          onClick={HandleUploadCSV}
          loading={isUploadingCSV}
        />
      )}

      {isModalOpen == "add-edit" && (
        <AddOrEditProvinceModal
          show={isModalOpen == "add-edit"}
          setShow={setIsModalOpen}
          data={selectedItem}
        />
      )}

      {isModalOpen == "active-deactive" && (
        <AreYouSureModal
          show={isModalOpen == "active-deactive"}
          setShow={setIsModalOpen}
          subTitle={`Do you want to ${
            selectedItem?.isActive ? "deactivate" : "activate"
          } this Province?`}
          isApiCall={isStatusLoading}
          onClick={async () => {
            const res = await activeDeactiveApi({
              id: selectedItem?._id,
              status: !selectedItem?.isActive,
              type: "Province",
            });
            if (res?.data) {
              toast.success(
                `Province ${
                  res?.data?.data?.isActive ? "activated" : "deactivated"
                } successfully`
              );
              setIsModalOpen("");
            }
          }}
        />
      )}
    </SideBarSkeleton>
  );
}

export default ProvincesCrud;
