/* eslint-disable */

import React, { useEffect, useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./UpgradeMembershipModal.module.css";
import { Col, Row } from "react-bootstrap";
import { Button } from "../../Component/Button/Button";
import DropDown from "../../Component/DropDown/DropDown";
import { toast } from "react-toastify";
import { useGetCompanyAgentsQuery } from "../../store/apiSlice/apiSlice";
import { featureListingImg } from "../../constant/imagePath";
import moment from "moment";
import { useSelector } from "react-redux";
import { packageDurationOptions } from "../../constant/commonData";

const UpgradeMembershipModal = ({
  show,
  setShow,
  isApiCall,
  data,
  onClick,
}) => {
  const [selectPackage, setSelectPackage] = useState("");
  const [duration, setDuration] = useState("");
  const { packages } = useSelector((state) => state?.commonReducer);
  // const { data: companyAgents, isLoading } = useGetCompanyAgentsQuery(
  //   { slug: data?.slug },
  //   { skip: type == "Company" }
  // );

  const currentPackage = {
    packageType: data?.package?.packageType,
    packageEndDate: data?.packageDetails?.endDate,
  };

  const checkPackageValidation = (desiredPackage) => {
    const packageTiers = {
      basic: 1,
      lite: 2,
      plus: 3,
      pro: 4,
    };

    if (
      packageTiers[desiredPackage] >= packageTiers[currentPackage?.packageType]
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      modalClass={classes.main}
      borderRadius={"0px"}
      width={"1150px"}
      header={true}
      showCloseIcon={false}
      headerClass={classes.header}
    >
      <div className={classes.main}>
        <Row className={[classes.row, "g-0", classes].join(" ")}>
          <Col md={6} className={classes.col1}>
            <h4>Upgrade Membership</h4>
            <div className={classes?.mainInfo}>
              <div
                className={classes.memberShipWrap}
                // style={{
                //   backgroundImage: `url(${DashboardCardImg2})`,
                //   backgroundSize: 'cover',
                //   backgroundPosition: 'center'
                // }}
              >
                <p className={classes.membership}>Membership</p>
                <p className={classes.lite}>{currentPackage?.packageType}</p>
                <p className={classes.valid}>
                  Valid till{" "}
                  {moment(currentPackage?.packageEndDate).format("Do MMM YYYY")}
                </p>
              </div>
            </div>

            <div className={classes.inputsBox}>
              <DropDown
                options={packages}
                value={selectPackage}
                setValue={setSelectPackage}
                variant="dashboard"
                placeholder={"Select Package"}
                label={"Upgrade To"}
                getOptionLabel={(e) => `${e.name}`}
                optionValue={"_id"}
              />
              <DropDown
                options={packageDurationOptions}
                value={duration}
                setValue={setDuration}
                variant="dashboard"
                placeholder={"Select Duration"}
                label={"Duration"}
              />

              <Button
                label={isApiCall ? "Please Wait..." : "Upgrade Package"}
                disabled={isApiCall}
                onClick={() => {
                  const body = {
                    packageId: selectPackage?._id,
                    duration:duration?.value,
                    // credits: Number(creditPoints),
                    // type,
                    // ...(type == "Agent" ? { selectPackage } : {}),
                  };
                  for (let key in body) {
                    if (!body[key]) {
                      return toast.error("Please fill all the fields");
                    }
                  }
                  const isAllowed = checkPackageValidation(
                    selectPackage?.packageType
                  );
                  if (!isAllowed) {
                    return toast.error(
                      "Downgrading the package is not allowed."
                    );
                  }
                  onClick && onClick(body);
                }}
              />
            </div>
          </Col>
          <Col
            md={6}
            className="d-flex justify-content-center align-items-center"
          >
            <div className={classes.sideImage}>
              <img src={featureListingImg} alt="" draggable={false} />
            </div>
          </Col>
        </Row>
      </div>
    </ModalSkeleton>
  );
};

export default UpgradeMembershipModal;
