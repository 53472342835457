import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Combobox, ComboboxInput, ComboboxList, ComboboxOption } from '@reach/combobox';
import '@reach/combobox/styles.css';
import { AiFillCloseCircle } from 'react-icons/ai';

import classes from './MainMain.module.css';
import { useEffect } from 'react';

export default function Places({
  setCoordinates,
  setAddress,
  className,
  comboRootClassName,
  placeholder,
  deleteIcon,
  address,
  // valueToPrint,
  // makeEmptyValue = false,
  setCity,
  setCityChecker,
  variant, //default
  label
}) {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions
  } = usePlacesAutocomplete({
    // requestOptions:{language:'ur'}
    // requestOptions: { componentRestrictions: { country: "tt" } },
  });

  // useEffect(() => {
  //   if (value.length == 0 && !makeEmptyValue) {
  //     typeof setCoordinates == 'function' && setCoordinates('');

  //     typeof setAddress == 'function' && setAddress('');
  //   }
  // }, [value]);

  useEffect(() => {
    if (address) {
      setValue(address, false);
      clearSuggestions();
    }
  }, [address]);

  // const getDetails = async () => {
  //   console.log('getDetails');
  //   const res = await getGeocode({ location: { lat: 25.347299575806, lng: 55.399799346924 } ,language:'ar'});
  //   console.log({ res  },'res');
  // };

  // useEffect(() => {
  //   if (valueToPrint) {
  //     setValue(valueToPrint, false);
  //   }
  // }, []);

  //   handleSelect
  const handleSelect = async (val) => {
    setValue(val, false);
    clearSuggestions();
    // getDetails()

    const results = await getGeocode({ address: val });
    const { lat, lng } = await getLatLng(results[0]);
    console.log(results?.[0]?.address_components?.at(-1)?.long_name);

    // if we need city
    if (setCity) {
      let matches = results?.[0]?.address_components?.filter((address_component) =>
        ['locality', 'colloquial_area'].some((word) => ~address_component?.types?.indexOf(word))
      );
      if (matches?.length > 0) {
        setCity(matches[0].short_name);
        setCityChecker(false);
      } else {
        setCityChecker(true);
        setCity('');
      }
    }
    setCoordinates({ lat, lng, address: val });
    setAddress &&setAddress(val);
  };

  return (
    <div className={classes.placesInput} data-variant={variant}>
      {label && <label className={classes.label}>{label}</label>}
      <Combobox
        onSelect={handleSelect}
        className={` ${className && className}`}
        style={{ position: 'relative' }}>
        <ComboboxInput
          value={value}
          onChange={(e) => {
            if (!e) {
              e = window.event;
            }
            e.stopPropagation();
            setValue(e.target.value);
          }}
          disabled={!ready}
          className={`${classes['combobox-input']}`}
          placeholder={placeholder}
        />
        {deleteIcon && (
          <AiFillCloseCircle
            className={classes?.deleteIcon}
            onClick={() => {
              setValue('');
              setCoordinates('');
              setAddress('');
            }}
          />
        )}
        <ComboboxList
          className={`${data?.length > 0 && classes?.comboRoot} ${
            comboRootClassName && comboRootClassName
          } `}
          style={{
            zIndex: 99999
          }}>
          {status === 'OK' &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} className={classes?.comboOption} />
            ))}
        </ComboboxList>
      </Combobox>
    </div>
  );
}
