/* eslint-disable */

import React, { useState } from "react";
import classes from "./BlogManagement.module.css";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "../../Component/Button/Button";
import SearchInput from "../../Component/SearchInput";
import { useNavigate } from "react-router-dom";
import BlogCard from "../../Component/BlogCard";
import { Checkbox } from "@mui/material";
import { BlogImg } from "../../constant/imagePath";
import PaginationComponent from "../../Component/PaginationComponent";
import {
  useDeleteBlogsMutation,
  useGetAllBlogsQuery,
} from "../../store/apiSlice/apiSlice";
import { recordsLimit } from "../../config/apiUrl";
import useDebounce from "../../CustomHooks/useDebounce";
import { dateFilterOptions } from "../../constant/commonData";
import { Loader } from "../../Component/Loader";
import { toast } from "react-toastify";
import NoData from "../../Component/NoData/NoData";
import { useSelector } from "react-redux";

const tableData = Array(10)
  .fill({
    id: "5645",
    date: "2020.11.12",
    slug: `Blog Title`,
    title: `Blog Title`,
    description:
      "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.",
    createdAt: "10/02/2023",
    images: [BlogImg],
  })
  ?.map((item) => ({
    ...item,
    id: Math.floor(Math.random() * 10000),
    slug: `Blog Title ${Math.floor(Math.random() * 10000)}`,
  }));
console.log({ tableData });

function BlogManagement() {
const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(1);
  const debounceSearch = useDebounce(searchText, 500);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, isLoading, isFetching } = useGetAllBlogsQuery({
    limit: recordsLimit,
    page: page,
    search: debounceSearch,
    lan: selectedLanguage,
  });

  const [deleteBlogs, { isLoading: isDeleting }] = useDeleteBlogsMutation();
  // const [activeDeactiveApi, { isLoading: isStatusLoading }] =
  //   useActiveDeactiveEventMutation();

  const deleteMultiple = async () => {
    const res = await deleteBlogs(selectedRows?.map((item) => item._id));
    if (res?.data) {
      toast.success(
        `${selectedRows?.length > 1 ? "Blogs" : "Blog"} deleted successfully`
      );
      setSelectedRows([]);
    }
  };

  console.log({ data });

  return (
    <SideBarSkeleton>
      <Container fluid className={classes.page}>
        <Row>
          <Col md={12} className={classes.mb58}>
            <div className={classes.header}>
              <div className={classes.left}>
                <SearchInput
                  placeholder="Search by name"
                  setter={setSearchText}
                  value={searchText}
                  variant="dashboard"
                  containerStyle={{ width: "300px" }}
                />
              </div>
              <div className={classes.headerRight}>
                <Button
                  borderRadius={"8px"}
                  className={classes.deleteBtn}
                  disabled={selectedRows?.length == 0}
                  onClick={deleteMultiple}
                >
                  {isDeleting ? "Deleting..." : "Delete"}
                </Button>
                <Button
                  borderRadius={"8px"}
                  variant="primary"
                  onClick={() => {
                    navigate(`/blog-management/create`);
                  }}
                >
                  Create New Blog
                </Button>
              </div>
            </div>
          </Col>
          <Col md={12}>
            <h5 className={classes.heading}>BLOGS</h5>
          </Col>
          <Col md={12} className={classes?.content}>
            <Row className={classes.cardsRow}>
              {isLoading || isFetching ? (
                <Loader className={classes?.loader} />
              ) : data?.data?.length == 0 ? (
                <NoData text="No Blogs Found" />
              ) : (
                data?.data?.map((item, index) => (
                  <Col
                    md={6}
                    key={`blog-${index}`}
                    className={classes.cardWithCheckbox}
                  >
                    <Checkbox
                      value={
                        selectedRows?.find((e) => e?._id == item?._id) && true
                      }
                      className={classes.checkbox}
                      onChange={() => {
                        const newItems = [...selectedRows];
                        if (newItems?.find((e) => e?._id == item?._id)) {
                          newItems?.splice(
                            newItems?.findIndex((e) => e?._id == item?._id),
                            1
                          );
                          console.log("found");
                        } else {
                          newItems?.push(item);
                          console.log("not found");
                        }
                        setSelectedRows(newItems);
                      }}
                    />
                    <BlogCard
                      data={item}
                      onEdit={() => {
                        navigate(`/blog-management/edit/${item?.slug}`);
                      }}
                      onView={() => {
                        navigate(`/blog-management/${item?.slug}`);
                      }}
                    />
                  </Col>
                ))
              )}
            </Row>
          </Col>
          {data?.data?.length > 0 && (
            <Col md={12} className={classes.paginationDiv}>
              <PaginationComponent
                totalPages={Math.ceil(data?.totalRecords / recordsLimit)}
                currentPage={page}
                setCurrentPage={setPage}
              />
            </Col>
          )}
        </Row>
      </Container>
    </SideBarSkeleton>
  );
}

export default BlogManagement;
