/* eslint-disable */

import React, { useState } from "react";
import classes from "./ProjectsManagement.module.css";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import CustomMaterialTable from "../../Component/CustomMaterialTable";
import { AiFillEye } from "react-icons/ai";
import { Button } from "../../Component/Button/Button";
import SearchInput from "../../Component/SearchInput";
import { useNavigate } from "react-router-dom";
import { FaToggleOn } from "react-icons/fa";
import DropDown from "../../Component/DropDown/DropDown";
import { dateFilterOptions } from "../../constant/commonData";
import AddCreditPointsModal from "../../modals/AddCreditPointsModal/AddCreditPointsModal";
import {
  useActiveDeactiveProjectMutation,
  useDeleteProjectsMutation,
  useDisplayProjectOnHomePageMutation,
  useGetAllProjectsQuery,
  useRefreshProjectMutation,
} from "../../store/apiSlice/apiSlice";
import useDebounce from "../../CustomHooks/useDebounce";
import { recordsLimit, webLiveURL } from "../../config/apiUrl";
import moment from "moment";
import AreYouSureModal from "../../modals/AreYouSureModal";
import { toast } from "react-toastify";
import { HiRefresh } from "react-icons/hi";
import { useSelector } from "react-redux";
import { MdDisplaySettings } from "react-icons/md";

const tableData = Array(10)
  .fill({
    id: "5645",
    date: "2020.11.12",
    contract: "For Sale",
    type: "Villa",
    title: "Text text",
    companyName: "Real Estate",
    location: "London",
    status: "Active",
  })
  ?.map((item) => ({ ...item, id: Math.floor(Math.random() * 10000) }));

function ProjectsManagement() {
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);

  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState("");
  const [page, setPage] = useState(1);

  const [selectedItem, setSelectedItem] = useState(null);
  const [dateFilter, setDateFilter] = useState(dateFilterOptions[0]);
  const debounceSearch = useDebounce(searchText, 500);
  const { data, isLoading, isFetching } = useGetAllProjectsQuery({
    limit: recordsLimit,
    page: page,
    search: debounceSearch,
    sortBy: dateFilter?.value,
    lan: selectedLanguage,
  });

  const [deleteProjects, { isLoading: isDeleting }] =
    useDeleteProjectsMutation();
  const [activeDeactiveApi, { isLoading: isStatusLoading }] =
    useActiveDeactiveProjectMutation();

  const [refreshApi, { isLoading: isRefreshLoading }] =
    useRefreshProjectMutation();

  const [displayApi, { isLoading: isDisplaying }] =
    useDisplayProjectOnHomePageMutation();

  const deleteMultiple = async () => {
    const res = await deleteProjects(selectedRows?.map((item) => item._id));
    if (res?.data) {
      toast.success(
        `${
          selectedRows?.length > 1 ? "Projects" : "Project"
        } deleted successfully`
      );
      setSelectedRows([]);
    }
  };

  const onDisplayHomePage = async (item) => {
    const res = await displayApi({
      slug: item?.slug,
      status: !item?.displayOnHome,
    });
    if (res?.data) {
      console.log('res',res?.data)
      toast.success(
        `Project ${
          res?.data?.data?.displayOnHome ? "displayed" : "hide"
        } on home page successfully`
      );
    }
  };
  const headings = [
    { id: "listingId", label: "ID" },
    {
      id: "createdAt",
      label: "CREATION DATE",
      renderValue: (item, col) => {
        return moment(item[col?.id]).format("DD/MM/YYYY hh:mm A");
      },
    },
    {
      id: "projectType",
      label: "TYPE",
      renderValue: (item, col) => item?.projectType?.title?.[selectedLanguage],
    },
    {
      id: "title",
      label: "TITLE",
      renderValue: (item, col) => item?.[col.id]?.[selectedLanguage],
    },
    {
      id: "companyName",
      label: "COMPANY NAME",
      renderValue: (item, col) =>
        item?.company?.companyName?.[selectedLanguage],
    },
    { id: "address", label: "Location" },
    {
      id: "isActive",
      label: "STATUS",
      renderValue: (item, col) => (item[col?.id] ? "Active" : "Deactive"),
    },
    { id: "options", label: "OPTIONS", align: "center" },
  ];

  return (
    <SideBarSkeleton>
      <Container fluid className={classes.page}>
        <Row>
          <Col md={12} className={classes.mb58}>
            <div className={classes.header}>
              <div className={classes.left}>
                <SearchInput
                  placeholder="Search by Title or ID"
                  setter={setSearchText}
                  value={searchText}
                  variant="dashboard"
                  containerStyle={{ width: "300px" }}
                />
                <DropDown
                  label={"Sort By Date"}
                  containerClassName={classes.dropDownContainer}
                  customStyle={{
                    padding: "0px 6px",
                    minHeight: "40px",
                    minWidth: "200px",
                  }}
                  options={dateFilterOptions}
                  setValue={(e) => {
                    setDateFilter(e);
                  }}
                  value={dateFilter}
                  placeholder={"Select Date"}
                  // isClearable={true}
                />
              </div>
              <div className={classes.headerRight}>
                <Button
                  borderRadius={"8px"}
                  className={classes.deleteBtn}
                  disabled={selectedRows?.length == 0}
                  onClick={deleteMultiple}
                >
                  {isDeleting ? "Deleting..." : "Delete"}
                </Button>
              </div>
            </div>
          </Col>
          <Col md={12}>
            <h5 className={classes.heading}>PROJECTS</h5>
          </Col>
          <Col md={12}>
            <CustomMaterialTable
              headings={headings}
              data={data?.data ?? []}
              showOptionsColumn
              isLoading={isLoading || isFetching}
              totalRecords={Math.ceil(data?.totalRecords / recordsLimit)}
              noDataText="No Projects Found"
              page={page}
              setPage={setPage}
              options={[
                {
                  label: "View",
                  icon: <AiFillEye size={18} />,
                  onClick: (_,e) => {
                    window.open(`${webLiveURL}projects/${e?.slug}`, "_blank")
                  },
                },
                {
                  label: "Refresh",
                  onClick: (_, e) => {
                    setSelectedItem(e);
                    setIsModalOpen("refresh");
                  },
                  icon: <HiRefresh size={18} />,
                },
                {
                  label: (e) => (e?.isActive ? "Deactivate" : "Activate"),
                  onClick: (_, e) => {
                    setSelectedItem(e);
                    setIsModalOpen("active-deactive");
                  },
                  switch: true,
                },
                // Display on Homepage will be discussed later
                {
                  label: (e) =>
                    !e?.displayOnHome
                      ? "Display on Homepage"
                      : "Hide from Homepage",
                  icon: <MdDisplaySettings size={18} />,
                  onClick: (label, item) => {
                    console.log("upgrade package", label, item);
                    if (isDisplaying) return;
                    onDisplayHomePage(item);
                  },
                },
              ]}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              allowMultipleSelection
            />
          </Col>
        </Row>
      </Container>

      {isModalOpen == "credit-points" && (
        <AddCreditPointsModal
          show={isModalOpen == "credit-points"}
          setShow={setIsModalOpen}
        />
      )}
      {isModalOpen == "active-deactive" && (
        <AreYouSureModal
          show={isModalOpen == "active-deactive"}
          setShow={setIsModalOpen}
          subTitle={`Do you want to ${
            selectedItem?.isActive ? "deactivate" : "activate"
          } this project?`}
          isApiCall={isStatusLoading}
          onClick={async () => {
            const res = await activeDeactiveApi({
              slug: selectedItem?.slug,
              status: !selectedItem?.isActive,
            });
            if (res?.data) {
              toast.success(
                `Project ${
                  res?.data?.data?.isActive ? "activated" : "deactivated"
                } successfully`
              );
              setIsModalOpen("");
            }
          }}
        />
      )}
      {isModalOpen == "refresh" && (
        <AreYouSureModal
          show={isModalOpen == "refresh"}
          setShow={setIsModalOpen}
          subTitle={`Do you want to Refresh this project?`}
          isApiCall={isRefreshLoading}
          onClick={async () => {
            const res = await refreshApi({
              slug: selectedItem?.slug,
            });
            if (res?.data) {
              toast.success(`Project refreshed successfully`);
              setIsModalOpen("");
            }
          }}
        />
      )}
    </SideBarSkeleton>
  );
}

export default ProjectsManagement;
