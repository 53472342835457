/* eslint-disable */

import React, { useState, useEffect } from "react";
import classes from "./TermsAndConditions.module.css";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { useSelector } from "react-redux";
import { ButtonGroup, Col, Container, Row } from "react-bootstrap";
import { Button } from "../../Component/Button/Button";
import {
  BaseURL,
  apiHeader,
  capitalizeFirstLetter,
  languagesKeysObject,
} from "../../config/apiUrl";
import { Get, Patch } from "../../Axios/AxiosFunctions";
import { toast } from "react-toastify";
import { Loader } from "../../Component/Loader";
import QuillInput from "../../Component/QuillInput";
import { languagesOptions } from "../../constant/commonData";
import FormLanguages from "../../Component/FormLanguages";

const TermsAndConditions = () => {
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const { accessToken } = useSelector((state) => state.authReducer);
  const [language, setLanguage] = useState(
    languagesOptions?.find((e) => e?.value == selectedLanguage)
  );

  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [type, setType] = useState("user");

  const getData = async () => {
    const url = BaseURL("terms-and-conditions");
    setIsLoading(true);

    const response = await Get(url, accessToken);
    setIsLoading(false);

    if (response) {
      setData(response?.data?.data);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = async () => {
    const currentItem = data?.find((item) => item?.type == type);
    const content = currentItem?.content;
    const url = BaseURL(`terms-and-conditions/${currentItem?._id}`);

    for (let lang in languagesKeysObject) {
      if (!content?.[lang] || content?.[lang] == "<p><br></p>") {
        return toast.error(
          `Please fill the content in language: ${capitalizeFirstLetter(lang)}`
        );
      }
    }

    setBtnLoading(true);
    const response = await Patch(url, { content }, apiHeader(accessToken));
    if (response) {
      toast.success("Terms And Conditions updated successfully");
    }
    setBtnLoading(false);
  };
  console.log(data);
  return (
    <SideBarSkeleton>
      <Container className={classes.main}>
        {isLoading ? (
          <Loader />
        ) : (
          <Row className={classes.row}>
            <Col md={12}>
              <div className={classes?.spBtw}>
                <h5>Terms And Conditions</h5>
                <FormLanguages value={language} setter={setLanguage} />
              </div>
              <ButtonGroup className={classes?.btnGroup}>
                <Button
                  variant=""
                  data-selected={type == "user"}
                  onClick={() => setType("user")}
                  disabled={btnLoading}
                >
                  User
                </Button>
                <Button
                  variant=""
                  data-selected={type == "company"}
                  onClick={() => setType("company")}
                  disabled={btnLoading}
                >
                  Company
                </Button>
              </ButtonGroup>
            </Col>
            <Col md={12}>
              <QuillInput
                setter={(e) => {
                  const newData = [...data];
                  if (newData.find((item) => item?.type == type)?.content) {
                    newData.find((item) => item?.type == type).content = {
                      ...newData.find((item) => item?.type == type).content,
                      [language?.value]: e,
                    };
                  }
                  setData(newData);
                }}
                value={
                  data?.find((item) => item?.type == type)?.content?.[language?.value]
                }
                quillClass={classes.quillInput}
              />
            </Col>

            <Col md={12}>
              <Button
                label={btnLoading ? "Wait..." : "Submit"}
                onClick={handleSubmit}
              />
            </Col>
          </Row>
        )}
      </Container>
    </SideBarSkeleton>
  );
};

export default TermsAndConditions;
