/* eslint-disable */

import React, { useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./AddCreditPointsModal.module.css";
import { Col, Row } from "react-bootstrap";
import { coinsImg } from "../../constant/imagePath";
import { Input } from "../../Component/Input/Input";
import { Button } from "../../Component/Button/Button";
import DropDown from "../../Component/DropDown/DropDown";
import { Radio } from "../../Component/Radio/Radio";
import { toast } from "react-toastify";
import { useGetCompanyAgentsQuery } from "../../store/apiSlice/apiSlice";
import { useSelector } from "react-redux";

const AddCreditPointsModal = ({ show, setShow, isApiCall, data, onClick }) => {
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const [creditPoints, setCreditPoints] = useState("");
  const [selectAgent, setSelectAgent] = useState("");
  const [type, setType] = useState("Agent");
  const { data: companyAgents, isLoading } = useGetCompanyAgentsQuery(
    { slug: data?.slug },
    { skip: type == "Company" }
  );
  console.log({ companyAgents });
  const agentsOptions = companyAgents?.data ?? [];
  const companyDetails = [
    // { label: 'Agent Name', value: 'Agent Name XYZ' },
    { label: "Company Name", value: data?.companyName?.[selectedLanguage] },
    { label: "Company Email", value: data?.email },
    { label: "Company Mobile", value: data?.phoneNumber },
    {
      label: "Available Credit Points",
      value: data?.availableCreditPoints ?? 0,
    },
    // { label: 'Agent Email', value: 'abc@yahoo.com' },
    // { label: 'Available Credit Points', value: '200' }
  ];

  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      modalClass={classes.main}
      borderRadius={"0px"}
      width={"1150px"}
      header={true}
      showCloseIcon={false}
      headerClass={classes.header}
    >
      <div className={classes.main}>
        <Row className={[classes.row, "g-0", classes].join(" ")}>
          <Col md={6} className={classes.col1}>
            <h4>Credit Points</h4>
            <div className={classes?.mainInfo}>
              {companyDetails?.map((item, index) => {
                return (
                  <div className={classes?.info} key={index}>
                    <span className={classes?.leftDetail}>{item?.label}</span>
                    <span className={classes?.rightDetail}>{item?.value}</span>
                  </div>
                );
              })}
            </div>

            <div className={classes.inputsBox}>
              <div className={classes?.radiosWithLabel}>
                <label className={classes?.label}>Please Select the type</label>
                <div className={classes?.radios}>
                  <Radio value={type} setValue={setType} label="Agent" />
                  <Radio value={type} setValue={setType} label="Company" />
                </div>
              </div>
              {type == "Agent" && (
                <DropDown
                  options={agentsOptions}
                  value={selectAgent}
                  setValue={setSelectAgent}
                  variant="dashboard"
                  placeholder={"Select Agent"}
                  label={"Agent"}
                  getOptionLabel={(e) => `${e.agentName?.[selectedLanguage]}`}
                  optionValue={"slug"}
                />
              )}
              <div className={classes?.addCreditPointsContainer}>
                <Input
                  variant="dashboard"
                  label={"Add Credit Points"}
                  value={creditPoints}
                  setValue={setCreditPoints}
                  type={"number"}
                />
                <Button
                  label={isApiCall ? "Adding..." : "Add"}
                  disabled={isApiCall}
                  onClick={() => {
                    const body = {
                      credits: Number(creditPoints),
                      // type,
                      // ...(type == "Agent" ? { selectAgent } : {}),
                    };
                    for (let key in body) {
                      if (!body[key]) {
                        return toast.error("Please fill all the fields");
                      }
                    }
                    onClick &&
                      onClick(
                        type == "Company" ? data?.slug : selectAgent?.slug,
                        body
                      );
                  }}
                />
              </div>
            </div>
          </Col>
          <Col
            md={6}
            className="d-flex justify-content-center align-items-center"
          >
            <div className={classes.sideImage}>
              <img src={coinsImg} alt="" draggable={false} />
            </div>
          </Col>
        </Row>
      </div>
    </ModalSkeleton>
  );
};

export default AddCreditPointsModal;
