/* eslint-disable */

import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "../../Component/Button/Button";
import CustomMaterialTable from "../../Component/CustomMaterialTable";
import SearchInput from "../../Component/SearchInput";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import useDebounce from "../../CustomHooks/useDebounce";
import { recordsLimit } from "../../config/apiUrl";
import AdBannerModal from "../../modals/AdBannerModal/AdBannerModal";
import AdBannerViewModal from "../../modals/AdBannerViewModal/AdBannerViewModal";
import {
  useDeleteBannersMutation,
  useGetAllBannerQuery,
} from "../../store/apiSlice/apiSlice";
import classes from "./BannersManagement.module.css";

const tableData = Array(10)
  .fill({
    id: "5645",
    bannerName: "Property Search Pages",
    pageName: "Residential For Sale",
    type: "Vertical",
    startDate: "2020.11.12-10:00 AM",
    endDate: "2020.11.12-10:00 AM",
    status: "Active",
  })
  ?.map((item) => ({ ...item, id: Math.floor(Math.random() * 10000) }));

function BannersManagement() {
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);

  const [searchText, setSearchText] = useState("");
  const debounceSearch = useDebounce(searchText, 500);
  const [editData, setEditData] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState("");
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useGetAllBannerQuery({
    page: page,
    search: debounceSearch,
    lan: selectedLanguage,
  });

  const [deleteBanners, { isLoading: isDeleting }] = useDeleteBannersMutation();

  const deleteMultiple = async () => {
    const res = await deleteBanners(selectedRows?.map((item) => item._id));
    if (res?.data) {
      toast.success(
        `${
          selectedRows?.length > 1 ? "Banners" : "Banner"
        } deleted successfully`
      );
      setSelectedRows([]);
    }
  };

  const headings = [
    { id: "name", label: "BANNER NAME" },
    { id: "page", label: "PAGE NAME" },
    // Need to be discussed
    // { id: 'locationOnPage', label: 'LOCATION ON PAGE' },
    // We have added banners type because we have vertical and horizaontal banners
    { id: "bannerType", label: "TYPE" },
    { id: "startDate", label: "START DISPLAY DATE" },
    { id: "endDate", label: "END DISPLAY DATE" },
    { id: "options", label: "OPTIONS", align: "center" },
  ];

  return (
    <SideBarSkeleton>
      <Container fluid className={classes.page}>
        <Row>
          <Col md={12} className={classes.mb58}>
            <div className={classes.header}>
              <div className={classes.left}>
                <SearchInput
                  placeholder="Search by name"
                  setter={setSearchText}
                  value={searchText}
                  variant="dashboard"
                  containerStyle={{ width: "300px" }}
                />
              </div>
              <div className={classes.headerRight}>
                <Button
                  borderRadius={"8px"}
                  className={classes.deleteBtn}
                  disabled={selectedRows?.length == 0}
                  onClick={deleteMultiple}
                >
                  {isDeleting ? "Deleting..." : "Delete"}
                </Button>
                <Button
                  borderRadius={"8px"}
                  variant="primary"
                  onClick={() => {
                    setEditData(null);
                    setIsModalOpen("ad-banner");
                  }}
                >
                  Create New Banner
                </Button>
              </div>
            </div>
          </Col>
          <Col md={12}>
            <h5 className={classes.heading}>BANNERS</h5>
          </Col>
          <Col md={12}>
            <CustomMaterialTable
              headings={headings}
              data={data?.data}
              noDataText="No Banners Found"
              showOptionsColumn
              isLoading={isLoading || isFetching}
              page={page}
              setPage={setPage}
              totalRecords={Math.ceil(data?.totalRecords / recordsLimit)}
              options={[
                {
                  label: "View",
                  icon: <AiFillEye size={18} />,
                  onClick: (e, item) => {
                    setIsModalOpen("view");
                    setEditData(item);
                  },
                },
                {
                  label: "Edit",
                  icon: <FiEdit size={18} />,
                  onClick: (e, item) => {
                    setIsModalOpen("ad-banner");
                    setEditData(item);
                  },
                },
              ]}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              allowMultipleSelection
            />
          </Col>
        </Row>
      </Container>
      {isModalOpen == "ad-banner" && (
        <AdBannerModal
          bannerLimits={data?.limits?.bannerLimits}
          editData={editData}
          show={isModalOpen == "ad-banner"}
          setShow={() => {
            setEditData(null);
            setIsModalOpen("");
          }}
        />
      )}

      {isModalOpen == "view" && (
        <AdBannerViewModal
          editData={editData}
          show={isModalOpen == "view"}
          setShow={() => {
            setIsModalOpen("");
          }}
        />
      )}
    </SideBarSkeleton>
  );
}

export default BannersManagement;
