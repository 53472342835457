/* eslint-disable */

import React, { useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./ViewFAQModal.module.css";
import { Col, Row } from "react-bootstrap";
import ShowMoreShowLessText from "../../Component/ShowMoreShowLess/ShowMoreShowLessText";
import { useSelector } from "react-redux";
import FormLanguages from "../../Component/FormLanguages";
import { languagesOptions } from "../../constant/commonData";

const ViewFAQModal = ({ show, setShow, data }) => {
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const [language, setLanguage] = useState(languagesOptions[0]);
  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      modalClass={classes.main}
      borderRadius={"0px"}
      width={"1150px"}
      header={true}
      showCloseIcon={false}
      headerClass={classes.header}
    >
      <div className={classes.main}>
        <Row
          className={[
            classes.row,
            classes?.paddingContainer,
            classes?.previewContainer,
          ].join(" ")}
        >
          <Row className={`${classes?.row}`}>
            <Col md={12} className={classes.title}>
              <h4>FAQ Detail</h4>
              <FormLanguages value={language} setter={setLanguage} />
            </Col>
            <Col
              md={12}
              className={[classes?.col1, classes?.labelAndValue].join(" ")}
            >
              <label>Question:</label>
              <p>{data?.question?.[language?.value]}</p>
            </Col>
            <Col
              md={12}
              className={[classes?.col1, classes?.labelAndValue].join(" ")}
            >
              <label>Answer:</label>
              <ShowMoreShowLessText
                text={data?.answer?.[language?.value]}
                visibility={200}
              />
            </Col>
          </Row>
        </Row>
      </div>
    </ModalSkeleton>
  );
};

export default ViewFAQModal;
