/* eslint-disable */

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import classes from "./AddCompany.module.css";
import { Button } from "../../Component/Button/Button";
import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import DropDown from "../../Component/DropDown/DropDown";
import { Input } from "../../Component/Input/Input";
import CustomPhoneInput from "../../Component/CustomPhoneInput";
import TextArea from "../../Component/TextArea";
import { toast } from "react-toastify";
import {
  BaseURL,
  CreateFormData,
  apiHeader,
  capitalizeFirstLetter,
  formRegEx,
  formRegExReplacer,
  languagesKeysObject,
  validateEmail,
} from "../../config/apiUrl";
import UploadImageBox from "../../Component/UploadImageBox";
import {
  allLanguagesArray,
  companyTypeOptions,
  languagesOptions,
  packageDurationOptions,
} from "../../constant/commonData";
import { ProfileWithEditButton } from "../../Component/ProfileWithEditButton";
import LocationComponent from "../../Component/LocationComponent";
import FormLanguages from "../../Component/FormLanguages";
import {
  useAddOrEditCompanyMutation,
  useLazyGetSingleCompanyQuery,
} from "../../store/apiSlice/apiSlice";
import { Loader } from "../../Component/Loader";
import { useSelector } from "react-redux";

function AddCompany() {
  const { packages } = useSelector((state) => state?.commonReducer);
  const [language, setLanguage] = useState(languagesOptions[0]);
  const navigate = useNavigate();
  const slug = useParams()?.slug;
  const [getData, { isLoading: isGetting }] = useLazyGetSingleCompanyQuery();
  const [handleCreate, { isLoading }] = useAddOrEditCompanyMutation();

  const [profileData, setProfileData] = useState({
    photo: null,
    companyName: languagesKeysObject,
    companyType: null,
    serviceAreas: languagesKeysObject,
    language: [],
    registrationNumber: null,
    description: languagesKeysObject,
    email: null,
    // password,
    whatsapp: null,
    phoneNumber: null,
    // For Package
    package: null,
    duration: null,

    // role: "company",
  });

  const [locationState, setLocationState] = useState({
    province: null,
    town: null,
    neighbourhood: null,
    address: null,
    lat: null,
    lng: null,
  });

  // Get Single Company

  const getSingleCompany = async () => {
    const response = await getData({ slug });
    if (response?.data) {
      const resData = response?.data?.data;
      setProfileData({
        photo: resData?.photo,
        companyName: resData?.companyName,
        companyType: resData?.companyType,
        serviceAreas: resData?.serviceAreas,
        language: resData?.language,
        registrationNumber: resData?.registrationNumber,
        description: resData?.description,
        email: resData?.email,
        whatsapp: resData?.whatsapp,
        phoneNumber: resData?.phoneNumber,
      });
      setLocationState({
        province: resData?.province,
        town: resData?.town,
        neighbourhood: resData?.neighborhood,
        address: resData?.address,
        lat: resData?.location?.coordinates[1],
        lng: resData?.location?.coordinates[0],
      });
    }
  };
  useEffect(() => {
    if (slug) {
      getSingleCompany();
    }
  }, [isGetting]);

  // handleSubmit
  const handleSubmit = async () => {
    const multipleLangsData = {
      companyName: profileData?.companyName,
      companyType: profileData?.companyType,
      serviceAreas: profileData?.serviceAreas,
      description: profileData?.description,
    };
    for (let key in languagesKeysObject) {
      for (let key2 in multipleLangsData) {
        if (!multipleLangsData[key2]?.[key]) {
          return toast.error(
            `Please fill the ${capitalizeFirstLetter(
              key2?.replace(formRegEx, formRegExReplacer)
            )} field in language: ${key}`
          );
        }
      }
    }

    let otherParams = {
      photo: profileData?.photo,
      registrationNumber: profileData?.registrationNumber,
      email: profileData?.email,
      whatsapp: profileData?.whatsapp,
      phoneNumber: profileData?.phoneNumber,
      province: locationState?.province?._id,
      town: locationState?.town?._id,
      neighborhood: locationState?.neighbourhood?._id,
      address: locationState?.address,
    };

    for (let key in otherParams) {
      if (!otherParams[key]) {
        return toast.error(
          `${capitalizeFirstLetter(
            key?.replace(formRegEx, formRegExReplacer)
          )} is required.`
        );
      }
    }
    if (profileData?.language.length === 0) {
      return toast.error("Please select at least one language.");
    }
    if (!validateEmail(profileData?.email))
      return toast.error("Please enter a valid email address.");

    if (!slug && !profileData?.package) {
      return toast.error("Please select package.");
    }
    if (!slug && !profileData?.duration) {
      return toast.error("Please select package duration.");
    }
    otherParams = {
      ...otherParams,
      lng: locationState?.lng,
      lat: locationState?.lat,
      ...multipleLangsData,
      language: profileData?.language,
      ...(slug
        ? {}
        : {
            packageId: profileData?.package?._id,
            duration: profileData?.duration?.value,
          }),
    };

    // create formData
    const formData = await CreateFormData(otherParams);
    const response = await handleCreate({ data: formData, slug });
    if (response?.data) {
      toast.success(`Company ${slug ? "updated" : "created"} successfully.`);
      navigate("/companies-management");
    }
  };

  const updateField = (field, value) => {
    setProfileData((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <SideBarSkeleton>
      <Container fluid className={classes.page}>
        {isGetting ? (
          <Loader className={classes?.loader} />
        ) : (
          <Row
            className={["ar", "fa"]?.includes(language?.value) ? "rtl" : "ltr"}
          >
            <Col md={12} className={classes.mb30}>
              <Button
                leftIcon={<IoChevronBackOutline />}
                variant=""
                className={classes.backBtn}
                onClick={() => {
                  navigate("/companies-management");
                }}
              >
                Back to Companies Management
              </Button>
            </Col>

            <Col md={12}>
              <div className={classes.formBg}>
                <Container>
                  <Row className={classes.formRow}>
                    <Col md={12}>
                      <div className={classes?.spBtw}>
                        <h3 className={classes.headingGreen}>New Company</h3>
                        <FormLanguages value={language} setter={setLanguage} />
                      </div>
                    </Col>
                    <Col md={12}>
                      <Row className="d-flex justify-content-center align-items-center">
                        <Col md={12}>
                          <p className={`m-0 ${classes.tagline}`}>{_d}</p>
                        </Col>

                        <Col md={12}>
                          <UploadImageBox
                            setValue={(e) => updateField("photo", e)}
                            value={profileData?.photo}
                            label={"Company Logo"}
                            labelClass={classes.label}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className={classes.mb30}>
                      <h6 className={classes.heading}>INFORMATION</h6>
                    </Col>
                    <Col md={6}>
                      <Input
                        label={"Company Name"}
                        placeholder={"Company Name"}
                        variant="dashboard"
                        value={profileData?.companyName?.[language?.value]}
                        setValue={(e) =>
                          setProfileData((prev) => ({
                            ...prev,
                            companyName: {
                              ...prev?.companyName,
                              [language?.value]: e,
                            },
                          }))
                        }
                      />
                    </Col>
                    <Col md={6}>
                      <DropDown
                        label={"Company Type"}
                        indicatorColor="var(--dashboard-text-gray-clr)"
                        placeholder={"Select company type"}
                        variant="dashboard"
                        value={profileData?.companyType}
                        setValue={(e) => updateField("companyType", e)}
                        getOptionLabel={(e) => e?.[language?.value]}
                        getOptionValue={(e) => e?.[language?.value]}
                        options={companyTypeOptions}

                        // options={propertyStatusOptions}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        label={"Service Areas"}
                        placeholder={"Service Areas"}
                        variant="dashboard"
                        value={profileData?.serviceAreas?.[language?.value]}
                        setValue={(e) =>
                          setProfileData((prev) => ({
                            ...prev,
                            serviceAreas: {
                              ...prev?.serviceAreas,
                              [language?.value]: e,
                            },
                          }))
                        }
                      />
                    </Col>
                    <Col md={6}>
                      <DropDown
                        label={"Languages We Speak"}
                        indicatorColor="var(--dashboard-text-gray-clr)"
                        placeholder={"Select languages we speak"}
                        variant="dashboard"
                        value={profileData?.language}
                        isMulti={true}
                        options={allLanguagesArray}
                        optionLabel={[language?.value]}
                        optionValue={[language?.value]}
                        setValue={(e) => updateField("language", e)}
                      />
                    </Col>
                    {/* No of Agents Will be fetched from the created agents by company */}
                    {/* <Col md={6}>
                    <Input
                      label={'Number of Agents'}
                      placeholder={'number of agents'}
                      variant="dashboard"
                      value={profileData?.noOfAgents}
                      setValue={(e) => updateField('noOfAgents', e)}
                    />
                  </Col> */}
                    <Col md={6}>
                      <Input
                        label={"Registeration Number"}
                        placeholder={"registeration number"}
                        variant="dashboard"
                        value={profileData?.registrationNumber}
                        setValue={(e) => updateField("registrationNumber", e)}
                      />
                    </Col>
                    <Col md={12}>
                      <TextArea
                        label={"About Us"}
                        placeholder={"about us"}
                        variant="dashboard"
                        value={profileData?.description?.[language?.value]}
                        setValue={(e) =>
                          setProfileData((prev) => ({
                            ...prev,
                            description: {
                              ...prev?.description,
                              [language?.value]: e,
                            },
                          }))
                        }
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        label={"Email*"}
                        placeholder={"email"}
                        variant="dashboard"
                        value={profileData?.email}
                        setValue={(e) => updateField("email", e)}
                        // disabled={true}
                      />
                    </Col>
                    <Col md={6}>
                      <CustomPhoneInput
                        label={"Phone"}
                        placeholder={"Phone"}
                        variant="dashboard"
                        value={profileData?.phoneNumber}
                        setter={(e) => updateField("phoneNumber", e)}
                      />
                    </Col>
                    <Col md={6}>
                      <CustomPhoneInput
                        label={"Whatsapp Number"}
                        placeholder={"Whatsapp Number"}
                        variant="dashboard"
                        value={profileData?.whatsapp}
                        setter={(e) => updateField("whatsapp", e)}
                      />
                    </Col>
                    {!slug && (
                      <>
                        <Col md={12} className={classes.mb30}>
                          <h6 className={classes.heading}>
                            PACKAGE INFORMATION
                          </h6>
                        </Col>
                        <Col md={6}>
                          <DropDown
                            label={"Package*"}
                            indicatorColor="var(--primary-clr)"
                            placeholder={"Select Package"}
                            variant="dashboard"
                            value={profileData?.package}
                            options={packages}
                            optionValue={"_id"}
                            optionLabel={"name"}
                            setValue={(e) => updateField("package", e)}
                          />
                        </Col>
                        <Col md={6}>
                          <DropDown
                            label={"Duration*"}
                            indicatorColor="var(--primary-clr)"
                            placeholder={"Select Duration"}
                            variant="dashboard"
                            value={profileData?.duration}
                            options={packageDurationOptions}
                            setValue={(e) => updateField("duration", e)}
                          />
                        </Col>
                      </>
                    )}
                    <Col md={12}>
                      <LocationComponent
                        locationState={locationState}
                        setLocationState={setLocationState}
                        selectedLanguage={language}
                      />
                    </Col>
                    {/* <Col md={6}>
                    <DropDown
                      label={'Province*'}
                      indicatorColor="var(--primary-clr)"
                      placeholder={'Select Province'}
                      variant="dashboard"
                      value={profileData?.province}
                      setValue={(e) => updateField('province', e)}
                    />
                  </Col>
                  <Col md={6}>
                    <DropDown
                      indicatorColor="var(--primary-clr)"
                      label={'Town*'}
                      placeholder={'Select Town'}
                      variant="dashboard"
                      value={profileData?.town}
                      setValue={(e) => updateField('town', e)}
                    />
                  </Col>
                  <Col md={6}>
                    <DropDown
                      indicatorColor="var(--primary-clr)"
                      label={'Neighborhood*'}
                      placeholder={'Select Neighborhood'}
                      variant="dashboard"
                      value={profileData?.neighborhood}
                      setValue={(e) => updateField('neighborhood', e)}
                    />
                  </Col>
                  <Col md={6}>
                    <MapMain
                      type="places"
                      label={'Pin On Map*'}
                      placeholder={'Select your location'}
                      variant="dashboard"
                      address={profileData?.location}
                      setAddress={(e) => updateField('location', e)}
                    />
                  </Col> */}
                  </Row>
                </Container>
              </div>
            </Col>
            <Col md={12} className={classes.btnDiv}>
              <Button className={classes.btnCancel} disabled={isLoading}>
                Cancel
              </Button>
              <Button
                className={classes.btn}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save Changes"}
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </SideBarSkeleton>
  );
}

export default AddCompany;

const _d = `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.`;

const agentDesignationOptions = [
  {
    label: "Real Estate",
    value: "real_estate",
    _id: "real_estate",
  },
  {
    label: "Agent",
    value: "agent",
    _id: "agent",
  },
];

const languagesWeSpeakOptions = [
  {
    label: "English",
    value: "english",
    _id: "english",
  },
  {
    label: "French",
    value: "french",
    _id: "french",
  },
];
