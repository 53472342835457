import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { Patch, Post } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import { Input } from "../../Component/Input/Input";
import { apiHeader, BaseURL, validateEmail } from "../../config/apiUrl";
import ModalSkeleton from "../ModalSkeleton";
import { useSelector, useDispatch } from "react-redux";
import styles from "./UpdatePassword.module.css";
import { saveLoginUserData } from "../../store/auth/authSlice";
const UpdatePasswordModal = ({ show, setShow }) => {
  const dispatch = useDispatch();

  const inputStyle = {
    borderRadius: "46px",
    boxShadow: "21px 14px 24px #00000012",
  };
  const { accessToken } = useSelector((state) => state.authReducer);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdate = async () => {
    const params = {
      passwordCurrent: currentPassword,
      password: newPassword,
      passwordConfirm: confirmPassword,
    };
    // validate
    for (let key in params)
      if (!params[key]) return toast.error(`Fields can not be empty.`);

    // validate password
    if (currentPassword.length < 8)
      return toast.error(
        "Current Password must be at least 8 characters long."
      );
    if (newPassword.length < 8)
      return toast.error("New Password must be at least 8 characters long.");
    if (confirmPassword.length < 8)
      return toast.error(
        "Confirm Password must be at least 8 characters long."
      );

    if (currentPassword == newPassword)
      return toast.error(
        "Current Password and New Password must be different."
      );
    if (newPassword !== confirmPassword)
      return toast.error("New Password and Confirm Password are different.");

    // call api
    const url = BaseURL(`users/updateMyPassword`);
    setIsLoading(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    setIsLoading(false);

    if (response != undefined) {
      dispatch(saveLoginUserData(response?.data));
      toast.success("Your Password has been updated successfully");
      setShow(false);
    }
  };
  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"600px"}
      header={"Update Password"}
      borderRadius={"20px"}
      showCloseIcon
    >
      <Row className={styles.row}>
        <Col md={12}>
          <Input
            setValue={setCurrentPassword}
            value={currentPassword}
            placeholder={"Enter Current Password"}
            label={"Current Password"}
            type={"password"}
            customStyle={inputStyle}
          />
        </Col>
        <Col md={12}>
          <Input
            setValue={setNewPassword}
            value={newPassword}
            placeholder={"Enter New password"}
            label={"New Password"}
            type={"password"}
            customStyle={inputStyle}
          />
        </Col>
        <Col md={12}>
          <Input
            setValue={setConfirmPassword}
            value={confirmPassword}
            placeholder={"Enter Confirm password"}
            label={"Confirm Password"}
            type={"password"}
            customStyle={inputStyle}
          />
        </Col>

        <Col md={12} className={styles.signUpBtnWrap}>
          <Button
            label={isLoading ? "Updating..." : "Update Password"}
            className={styles.UpdateBtn}
            onClick={handleUpdate}
            disabled={isLoading}
          />
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default UpdatePasswordModal;
