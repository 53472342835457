import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import classes from "./CustomMaterialTable.module.css";
import CustomPopover, { IconButton } from "../CustomPopover";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Checkbox, Pagination, Skeleton } from "@mui/material";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import NoData from "../NoData/NoData";
import { recordsLimit } from "../../config/apiUrl";
import { useRef } from "react";

const TableSkeleton = ({ rowsCount = 10, colsCount = 5 }) => {
  const colArrary = Array(colsCount).fill(0);
  const rowArrary = Array(rowsCount).fill(0);
  return (
    <>
      {rowArrary?.map((_, index) => (
        <TableRow key={`skeleton-row-${index}`}>
          {colArrary?.map((_, i) => (
            <TableCell key={`skeleton-row-${i}`}>
              <Skeleton height={"70px"} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

function CustomMaterialTable({
  totalRecords = 10,
  page = 1,
  setPage,
  containerClass = classes.tableHeight,
  noDataText = "No Data Found",
  //   onSelectAllClick,
  headings = [],
  data = [],
  showOptionsColumn = true,
  options = [],
  allowMultipleSelection,
  selectedRows = [],
  setSelectedRows,
  isLoading = false,
  limit = recordsLimit,
}) {
  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [indexMap, setIndexMap] = useState(-1);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Paper
      sx={{ width: "100%", overflow: "hidden" }}
      className={classes.tableWrapper}
    >
      <TableContainer className={[containerClass].join(" ")}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.thead}>
            <TableRow>
              {allowMultipleSelection && (
                <TableCell padding="checkbox"></TableCell>
              )}
              {headings?.map((column) => (
                <TableCell
                  key={column?.id}
                  align={column?.align}
                  style={{ minWidth: column?.minWidth }}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableSkeleton
                rowsCount={limit}
                colsCount={headings?.length + (allowMultipleSelection ? 1 : 0)}
              />
            ) : data?.length == 0 ? (
              <TableRow>
                <TableCell
                  colSpan={headings?.length + (allowMultipleSelection ? 1 : 0)}
                  style={{ borderBottom: "none" }}
                >
                  <NoData text={noDataText} />
                </TableCell>
              </TableRow>
            ) : (
              data?.map((item, key) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={key}
                  className={classes.tableRow}
                >
                  {allowMultipleSelection && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={
                          // false
                          selectedRows?.find((e) => e?._id == item?._id) !==
                          undefined
                        }
                        // inputProps={{
                        //   'aria-labelledby': `enhanced-table-checkbox-${key}`
                        // }}
                        onChange={() => {
                          const newData = [...selectedRows];
                          if (newData?.find((e) => e?._id == item?._id)) {
                            newData?.splice(
                              newData?.findIndex((e) => e?._id == item?._id),
                              1
                            );
                          } else {
                            newData?.push(item);
                          }

                          setSelectedRows(newData);
                        }}
                      />
                    </TableCell>
                  )}
                  {headings
                    ?.slice(
                      0,
                      showOptionsColumn
                        ? headings?.length - 1
                        : headings?.length
                    )
                    ?.map((column, index) => {
                      return (
                        <TableCell key={index} align={column?.align}>
                          {headings[index]?.renderValue
                            ? headings[index]?.renderValue(item, column)
                            : item[column?.id]}
                        </TableCell>
                      );
                    })}
                  {showOptionsColumn && (
                    <TableCell
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div
                        ref={key == indexMap ? anchorRef : null}
                        id="composition-button"
                        aria-controls={
                          anchorEl ? "composition-menu" : undefined
                        }
                        aria-expanded={anchorEl ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={() => {
                          setSelectedItem(item);
                          setIndexMap(key);
                          setTimeout(() => setAnchorEl(true), 100);
                        }}
                      >
                        <IconButton
                          Icon={
                            <BsThreeDotsVertical
                              size={20}
                              className={[classes.threeDots]?.join(" ")}
                            />
                          }
                          btnClass={classes.iconBtn}
                        />
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPopover
        setOpen={setAnchorEl}
        open={anchorEl}
        anchorRef={anchorRef}
        Component={
          <ul className={classes.list}>
            {options?.map((op, index) => (
              <li
                className="c-p"
                onClick={() => {
                  op?.onClick(op?.label, selectedItem);
                  setAnchorEl(null);
                }}
              >
                {!op?.switch ? (
                  op?.icon
                ) : selectedItem?.isActive ? (
                  <FaToggleOn />
                ) : (
                  <FaToggleOff size={18} />
                )}{" "}
                {typeof op?.label == "function"
                  ? op?.label(selectedItem)
                  : op?.label}
              </li>
            ))}
          </ul>
        }
      />
      {!!data?.length && (
        <div className={classes?.paginationDiv}>
          <Pagination
            className="ltr"
            count={totalRecords}
            page={page}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Paper>
  );
}

export default CustomMaterialTable;
