/* eslint-disable */

import React, { useEffect, useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./BankViewModal.module.css";
import { Col, Row } from "react-bootstrap";
import { Input } from "../../Component/Input/Input";
import { Button } from "../../Component/Button/Button";
import { ProfileWithEditButton } from "../../Component/ProfileWithEditButton";
import { toast } from "react-toastify";
import { useAddOrEditBankMutation } from "../../store/apiSlice/apiSlice";
import {
  CreateFormData,
  capitalizeFirstLetter,
  formRegEx,
  formRegExReplacer,
  languagesKeysObject,
} from "../../config/apiUrl";
import validator from "validator";
import { languagesOptions } from "../../constant/commonData";
import FormLanguages from "../../Component/FormLanguages";
import { useSelector } from "react-redux";

const BankViewModal = ({ show, setShow, data }) => {
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const [language, setLanguage] = useState(languagesOptions[0]);
  const [bankImage, setBankImage] = useState(data?.image ?? null);
  const [bankName, setBankName] = useState(
    data?.bankName ?? languagesKeysObject
  );
  const [interestRate, setInterestRate] = useState(data?.interestRate ?? 0);
  const [financeAmountPercentage, setFinanceAmountPercentage] = useState(
    data?.financialAmountPercentage ?? 0
  );
  const [maximumAmount, setMaximumAmount] = useState(data?.maximumAmount ?? 0);
  const [maximumDuration, setMaximumDuration] = useState(
    data?.maximumDuration ?? 0
  );
  const [downPayment, setDownPayment] = useState(data?.downPayment ?? 0);
  const [finalPayment, setFinalPayment] = useState(data?.finalPayment ?? 0);
  const [bankInformationLink, setBankInformationLink] = useState(
    data?.bankInformationLink ?? ""
  );

  useEffect(() => {
    if (data) {
      setBankImage(data?.image);
      setBankName(data?.bankName);
      setInterestRate(data?.interestRate);
      setFinanceAmountPercentage(data?.financialAmountPercentage);
      setMaximumAmount(data?.maximumAmount);
      setMaximumDuration(data?.maximumDuration);
      setDownPayment(data?.downPayment);
      setFinalPayment(data?.finalPayment);
      setBankInformationLink(data?.bankInformationLink);
    }
  }, [data]);

  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      modalClass={classes.main}
      borderRadius={"0px"}
      width={"750px"}
      header={true}
      showCloseIcon={false}
      headerClass={classes.header}
    >
      <div className={classes.main}>
        <Row
          className={[
            classes.row,
            classes?.paddingContainer,
            classes?.previewContainer,
            ["ar", "fa"]?.includes(language?.value) ? "rtl" : "ltr",
          ].join(" ")}
        >
          <Row className="mb-3">
            <Col md={12}>
              <div className="d-flex justify-content-center">
                <FormLanguages setter={setLanguage} value={language} />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} className={classes.col1}>
              <h4>BANK VIEW</h4>
            </Col>
            <Col md={12} className="mb-3">
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
            </Col>

            <Col md={12}>
              <div className="d-flex align-items-center justify-content-center">
                <ProfileWithEditButton
                  updateImage={bankImage}
                  setUpdateImage={setBankImage}
                  className={classes?.profile}
                />
              </div>
            </Col>
          </Row>

          <Row className={`${classes?.row}`}>
            <Col md={12} className={classes?.col1}>
              <span className={classes.labelText}>
                Bank Name:{" "}
                <span className={classes.labelValue}>
                  {bankName?.[language.value]}{" "}
                </span>
              </span>
            </Col>
          </Row>

          <Row className={`${classes?.row}`}>
            <Col md={6} className={classes?.col1}>
              <span className={classes.labelText}>
                Interest Rate :{" "}
                <span className={classes.labelValue}>{interestRate}</span>
              </span>
            </Col>

            <Col md={6} className={classes?.col1}>
              <span className={classes.labelText}>
                Finance Amount Percentage:{" "}
                <span className={classes.labelValue}>
                  {financeAmountPercentage}
                </span>
              </span>
            </Col>
          </Row>

          <Row className={`${classes?.row}`}>
            <Col md={6} className={classes?.col1}>
              <span className={classes.labelText}>
                Maximum Amount:{" "}
                <span className={classes.labelValue}>{maximumAmount}</span>
              </span>
            </Col>

            <Col md={6} className={classes?.col1}>
              <span className={classes.labelText}>
                Maximum Duration :{" "}
                <span className={classes.labelValue}>{maximumDuration}</span>
              </span>
            </Col>
          </Row>

          <Row className={`${classes?.row}`}>
            <Col md={6} className={classes?.col1}>
              <span className={classes.labelText}>
                Down Payment:{" "}
                <span className={classes.labelValue}>{downPayment}</span>
              </span>
            </Col>

            <Col md={6} className={classes?.col1}>
              <span className={classes.labelText}>
                Final Payment:{" "}
                <span className={classes.labelValue}>{finalPayment}</span>
              </span>
            </Col>
          </Row>

          <Row className={`${classes?.row}`}>
            <Col md={12} className={classes?.col1}>
              <span className={classes.labelText}>
                Bank Information Link:{" "}
                <a
                  className={[classes.labelValue, "text-info"].join(" ")}
                  href={bankInformationLink}
                >
                  {bankInformationLink}
                </a>
              </span>
            </Col>
          </Row>
        </Row>
      </div>
    </ModalSkeleton>
  );
};

export default BankViewModal;
