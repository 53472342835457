/* eslint-disable */

import React, { useState } from "react";
import classes from "./FAQS.module.css";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import CustomMaterialTable from "../../Component/CustomMaterialTable";
import { AiFillEye } from "react-icons/ai";
import { Button } from "../../Component/Button/Button";
import SearchInput from "../../Component/SearchInput";
import { useNavigate } from "react-router-dom";
import DropDown from "../../Component/DropDown/DropDown";
import { dateFilterOptions } from "../../constant/commonData";
import {
  useActiveDeactiveFaqMutation,
  useDeleteFaqsMutation,
  useGetAllFaqsQuery,
} from "../../store/apiSlice/apiSlice";
import useDebounce from "../../CustomHooks/useDebounce";
import { recordsLimit } from "../../config/apiUrl";
import moment from "moment";
import AreYouSureModal from "../../modals/AreYouSureModal";
import { toast } from "react-toastify";
import AddOrEditFAQModal from "../../modals/AddOrEditFAQModal";
import { FiEdit } from "react-icons/fi";
import ViewFAQModal from "../../modals/ViewFAQModal";
import { useSelector } from "react-redux";

function FAQS() {
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState("");
  const [page, setPage] = useState(1);

  const [selectedItem, setSelectedItem] = useState(null);
  const [dateFilter, setDateFilter] = useState(dateFilterOptions[0]);
  const debounceSearch = useDebounce(searchText, 500);
  const { data, isLoading, isFetching } = useGetAllFaqsQuery({
    limit: recordsLimit,
    page: page,
    search: debounceSearch,
    sortBy: dateFilter?.value,
    lan:selectedLanguage
  });

  const [deleteFaqs, { isLoading: isDeleting }] = useDeleteFaqsMutation();
  const [activeDeactiveApi, { isLoading: isStatusLoading }] =
    useActiveDeactiveFaqMutation();

  const deleteMultiple = async () => {
    const res = await deleteFaqs(selectedRows?.map((item) => item._id));
    if (res?.data) {
      toast.success(
        `${selectedRows?.length > 1 ? "FAQs" : "FAQ"} deleted successfully`
      );
      setSelectedRows([]);
    }
  };

  const headings = [
    // { id: "listingId", label: "ID" },
    {
      id: "createdAt",
      label: "CREATION DATE",
      renderValue: (item, col) => {
        return moment(item[col?.id]).format("DD/MM/YYYY hh:mm A");
      },
    },
    {
      id: "question",
      label: "Question",
      renderValue: (item, col) => {
        return item[col?.id]?.[selectedLanguage];
      },
    },
    {
      id: "answer",
      label: "Answer",
      renderValue: (item, col) => {
        return (
          <p className="text1Line" style={{ width: "350px" }}>
            {item[col?.id]?.[selectedLanguage]}
          </p>
        );
      },
    },
    {
      id: "isActive",
      label: "STATUS",
      renderValue: (item, col) => (item[col?.id] ? "Active" : "Deactive"),
    },

    { id: "options", label: "OPTIONS", align: "center" },
  ];

  return (
    <SideBarSkeleton>
      <Container fluid className={classes.page}>
        <Row>
          <Col md={12} className={classes.mb58}>
            <div className={classes.header}>
              <div className={classes.left}>
                <SearchInput
                  placeholder="Search by question or answer"
                  setter={setSearchText}
                  value={searchText}
                  variant="dashboard"
                  containerStyle={{ width: "300px" }}
                />
                <DropDown
                  label={"Sort By Date"}
                  containerClassName={classes.dropDownContainer}
                  customStyle={{
                    padding: "0px 6px",
                    minHeight: "40px",
                    minWidth: "200px",
                  }}
                  options={dateFilterOptions}
                  setValue={(e) => {
                    setDateFilter(e);
                  }}
                  value={dateFilter}
                  placeholder={"Select Date"}
                  // isClearable={true}
                />
              </div>
              <div className={classes.headerRight}>
                <Button
                  borderRadius={"8px"}
                  className={classes.deleteBtn}
                  disabled={selectedRows?.length == 0}
                  onClick={deleteMultiple}
                >
                  {isDeleting ? "Deleting..." : "Delete"}
                </Button>
                <Button
                  borderRadius={"8px"}
                  className={classes.headerBtn}
                  onClick={() => {
                    setSelectedItem(null);
                    setIsModalOpen("add-edit");
                  }}
                >
                  Create
                </Button>
              </div>
            </div>
          </Col>
          <Col md={12}>
            <h5 className={classes.heading}>FAQs</h5>
          </Col>
          <Col md={12}>
            <CustomMaterialTable
              headings={headings}
              data={data?.data}
              showOptionsColumn
              isLoading={isLoading || isFetching}
              totalRecords={Math.ceil(data?.totalRecords / recordsLimit)}
              noDataText="No FAQs Found"
              page={page}
              setPage={setPage}
              options={[
                {
                  label: "View",
                  icon: <AiFillEye size={18} />,
                  onClick: (_, item) => {
                    setSelectedItem(item);
                    setIsModalOpen("view");
                  },
                },
                {
                  label: "Edit",
                  icon: <FiEdit size={18} />,
                  onClick: (_, item) => {
                    setSelectedItem(item);
                    setIsModalOpen("add-edit");
                  },
                },
                {
                  label: (e) => (e?.isActive ? "Deactivate" : "Activate"),
                  onClick: (_, e) => {
                    setSelectedItem(e);
                    setIsModalOpen("active-deactive");
                  },
                  switch: true,
                },
              ]}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              allowMultipleSelection
            />
          </Col>
        </Row>
      </Container>

      {isModalOpen == "add-edit" && (
        <AddOrEditFAQModal
          show={isModalOpen == "add-edit"}
          setShow={setIsModalOpen}
          data={selectedItem}
        />
      )}
      {isModalOpen == "view" && (
        <ViewFAQModal
          show={isModalOpen == "view"}
          setShow={setIsModalOpen}
          data={selectedItem}
        />
      )}
      {isModalOpen == "active-deactive" && (
        <AreYouSureModal
          show={isModalOpen == "active-deactive"}
          setShow={setIsModalOpen}
          subTitle={`Do you want to ${
            selectedItem?.isActive ? "deactivate" : "activate"
          } this FAQ?`}
          isApiCall={isStatusLoading}
          onClick={async () => {
            const res = await activeDeactiveApi({
              _id: selectedItem?._id,
              status: !selectedItem?.isActive,
            });
            if (res?.data) {
              toast.success(
                `FAQ ${
                  res?.data?.data?.isActive ? "activated" : "deactivated"
                } successfully`
              );
              setIsModalOpen("");
            }
          }}
        />
      )}
    </SideBarSkeleton>
  );
}

export default FAQS;
