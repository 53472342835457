/* eslint-disable */

import React, { useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./AddOrEditAmenityModal.module.css";
import { Col, Row } from "react-bootstrap";
import { Input } from "../../Component/Input/Input";
import { Button } from "../../Component/Button/Button";
import { toast } from "react-toastify";
import { useAddOrEditAmenityMutation } from "../../store/apiSlice/apiSlice";
import { languagesOptions } from "../../constant/commonData";
import { languagesKeysObject } from "../../config/apiUrl";
import FormLanguages from "../../Component/FormLanguages";

const AddOrEditAmenityModal = ({ show, setShow, data, type = "" }) => {
  const [language, setLanguage] = useState(languagesOptions[0]);
  const [title, setTitle] = useState(data?.title ?? languagesKeysObject);

  const [addEditAmenity, { isLoading }] = useAddOrEditAmenityMutation({});

  function onClear() {
    setTitle(data?.title);
  }


  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      modalClass={classes.main}
      borderRadius={"0px"}
      width={"1150px"}
      header={true}
      showCloseIcon={false}
      headerClass={classes.header}
    >
      <div className={classes.main}>
        <Row
          className={[
            classes.row,
            classes?.paddingContainer,
            classes?.previewContainer,
          ].join(" ")}
        >
          <Row className={`${classes?.row}`}>
            <Col md={6} className={classes.col1}>
              <h4>
                {data ? "Edit" : "Create"} {type?.label} Amenity
              </h4>
            </Col>
            <Col md={6} className={classes.col1}>
              <FormLanguages value={language} setter={setLanguage} />
            </Col>
            <Col md={12} className={classes?.col1}>
              <Input
                variant="dashboard"
                label={"Title"}
                placeholder={"Enter title"}
                value={title?.[language?.value]}
                setValue={(e) => setTitle({ ...title, [language?.value]: e })}
                type={"text"}
              />
            </Col>
          </Row>

          <div className={classes?.buttonsContainer}>
            <Button
              label={"Clear"}
              onClick={() => {
                onClear();
              }}
              disabled={isLoading}
            />
            <Button
              label={isLoading ? "Wait..." : data ? "Edit" : "Create"}
              onClick={async () => {
                let body = {
                  title,
                  amenityType: type?.value,
                };

                for (let key in languagesKeysObject) {
                  body = {
                    ...body,
                    title: { ...body.title, [key]: body.title?.[key]?.trim() },
                  };
                  if (!body?.title[key]) {
                    return toast.error(
                      `Please fill the Title field in language "${key}"`
                    );
                  }
                }
                const res = await addEditAmenity({
                  data: body,
                  ...(data ? { slug: data?.slug } : {}),
                });

                if (res?.data) {
                  toast.success(
                    `${type?.label} Amenity ${
                      data ? "edited" : "created"
                    } successfully`
                  );
                  setShow(false);
                }
              }}
              disabled={isLoading}
            />
          </div>
        </Row>
      </div>
    </ModalSkeleton>
  );
};

export default AddOrEditAmenityModal;

const bannerTypeOptions = [
  { label: "Vertical", value: "vertical" },
  { label: "Horizontal", value: "horizontal" },
];
