import React, { useEffect, useState } from "react";
import classes from "./OtpScreen.module.css";
import { Container, Row, Col } from "react-bootstrap";
import { Logo } from "../../constant/imagePath";
import { resetIcon } from "../../constant/imagePath";
import { Button } from "../../Component/Button/Button";
import {  useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { useVerifyOTPMutation } from "../../store/apiSlice/apiSlice";
import { IoArrowBackSharp } from "react-icons/io5";

const OtpScreen = () => {
  const navigate = useNavigate();
  const email = useLocation()?.state?.email;
  const [submit, { isLoading }] = useVerifyOTPMutation();

  const [otp, setOtp] = useState("");

  const submitHandler = async () => {
    if (otp?.length < 6) {
      return toast.error("Please input the code");
    }

    const response = await submit({ email, otp });
    if (response?.data) {
      toast.success("OTP verified successfully!");
      navigate("/reset-password", { state: { email } });
    }
  };
  useEffect(() => {
    if (!email) {
      navigate(-1);
    }
  }, []);

  return (
    <div className={classes.resetPassword}>
      <div className={classes.header}>
        <Container>
          <Row>
            <Col>
              <div className={classes.imgWrapper}>
                {/* <img src={Logo} /> */}
                <h3>Demo</h3>
              </div>
            </Col>
            {/* <Col>
              <div className={classes.language}>
                <DropDown
                  value={dropdown}
                  setter={setDropdown}
                  options={options}
                  isSearchable={false}
                  placeholder={"Select Language"}
                />
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
      <div className={classes.formWrapper}>
        <div className={classes.form}>
        <Button
            leftIcon={<IoArrowBackSharp />}
            variant=""
            onClick={() => navigate("/")}
            className={classes.backBtn}
          >
            {" "}
            Back to Login
          </Button>
          <div className={classes.iconWrapper}>
            <img src={resetIcon} />
          </div>
          <>
            <div className={classes.content}>
              <h5> OTP Code </h5>
              <p className={"muted"}>Enter your otp code</p>
            </div>
            <div className={classes.otpMain}>
              <OtpInput
                value={otp}
                onChange={setOtp}
                inputStyle={classes.OtpInput_style}
                numInputs={6}
                inputType="tel"
                shouldAutoFocus={true}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <Button
              onClick={submitHandler}
              className={classes.btn}
              disabled={isLoading}
            >
              {isLoading ? "Verifing..." : "Verify"}
            </Button>
          </>
        </div>
      </div>
    </div>
  );
};

export default OtpScreen;
