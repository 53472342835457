/*eslint-disable*/

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DropDown from '../DropDown/DropDown';
import MapMain from '../maps/MapMain';
import classes from './LocationComponent.module.css';
import {
  useGetAllNeighborhoodsQuery,
  useGetAllProvincesQuery,
  useGetAllTownsQuery
} from '../../store/apiSlice/apiSlice';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';



const LocationComponent = ({ locationState, setLocationState, direction, selectedLanguage,  }) => {
  const {t}=useTranslation('locationComp')
  const isGettingProvinces = false;
  const isGettingTowns = false;
  const isGettingNeighborhoods = false;
  const {provinces,towns,neighborhoods}=useSelector(state=>state?.commonReducer)
  // const t=(e)=>e
  // const { data: provinces, isLoading: isGettingProvinces } = useGetAllProvincesQuery();
  // const { data: towns, isLoading: isGettingTowns } = useGetAllTownsQuery(
  //   {
  //     ref: locationState.province?._id
  //   },
  //   { skip: !locationState.province?._id }
  // );
  // const { data: neighborhoods, isLoading: isGettingNeighborhoods } = useGetAllNeighborhoodsQuery(
  //   {
  //     ref: locationState.town?._id
  //   },
  //   { skip: !locationState.town?._id }
  // );

  console.log({ provinces,towns,neighborhoods });
  return (
    <Row className={classes.row_main}>
      <Col md={12}>
        <h4>{t('location')}</h4>
      </Col>
      <Col md={6}>
        <DropDown
          label={t('provinceLabel')}
          placeholder={t('provincePlaceholder')}
          options={provinces}
          indicatorColor="var(--dashboard-text-gray-clr)"
          variant="dashboard"
          getOptionLabel={(e) => e?.name?.[selectedLanguage?.value]}
          optionValue={'_id'}
          value={locationState.province}
          setValue={(e) => {
            setLocationState({ ...locationState, province: e,town:null,neighbourhood:null });
          }}
          disabled={isGettingProvinces}
        />
      </Col>
      <Col md={6}>
        <DropDown
          label={t('townLabel')}
          placeholder={t('townPlaceholder')}
          //   options={eventTypes}
          indicatorColor="var(--dashboard-text-gray-clr)"
          variant="dashboard"
          getOptionLabel={(e) => e?.name?.[selectedLanguage?.value]}
          options={towns?.filter(e=>e?.ref?._id == locationState?.province?._id)}
          optionValue={'_id'}
          value={locationState.town}
          setValue={(e) => {
            setLocationState({ ...locationState, town: e ,neighbourhood:null });
          }}
          disabled={isGettingTowns}
        />
      </Col>
      <Col md={6}>
        <DropDown
          label={t('neighbourhoodLabel')}
          placeholder={t('neighbourhoodPlaceholder')}
          //   options={eventTypes}
          indicatorColor="var(--dashboard-text-gray-clr)"
          variant="dashboard"
          options={neighborhoods?.filter(e=>e?.ref?._id == locationState?.town?._id)}
          getOptionLabel={(e) => e?.name?.[selectedLanguage?.value]}
          optionValue={'_id'}
          value={locationState.neighbourhood}
          setValue={(e) => {
            setLocationState({ ...locationState, neighbourhood: e });
          }}
          disabled={isGettingNeighborhoods}
        />
      </Col>

      <Col md={6}>
        <MapMain
          label={t('pinLocationLabel')}
          placeholder={t('pinLocationPlaceholder')}
          variant="dashboard"
          type="places"
          address={locationState.address}
          setAddress={null}
          comboRootClassName={direction === 'rtl' ? classes.comboRootClassName : null}
          //   setAddress={(e) => {
          //     setLocationState({ ...locationState, location: e });
          //   }}
          setCoordinates={(e) => {
            setLocationState({
              ...locationState,

              lat: e?.lat,
              lng: e?.lng,
              address: e?.address
            });
          }}
        />
      </Col>
    </Row>
  );
};

export default LocationComponent;
