import React, { useEffect, useState } from "react";
import classes from "./ResetPassword.module.css";
import { Container, Row, Col } from "react-bootstrap";
import { Logo } from "../../constant/imagePath";
import { resetIcon } from "../../constant/imagePath";
import { Input } from "../../Component/Input/Input";
import { Button } from "../../Component/Button/Button";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useResetPasswordMutation } from "../../store/apiSlice/apiSlice";
import { IoArrowBackSharp } from "react-icons/io5";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const email = useLocation()?.state?.email;

  const [submit, { isLoading }] = useResetPasswordMutation();
  const submitHandler = async () => {
    const body = {
      email,
      password: newPassword,
      confirmPassword: confirmNewPassword,
    };
    for (let key in body) {
      if (!body[key]) {
        return toast.error("Please fill all the fields");
      }
    }
    if (body?.password?.length < 8) {
      return toast.error("New Password must be 8 characters or more");
    }
    if (body?.confirmPassword?.length < 8) {
      return toast.error("Confirm Password must be 8 characters or more");
    }
    if (body?.confirmPassword !== body.password) {
      return toast.error("Passwords must match");
    }

    const response = await submit(body);
    if (response?.data) {
      toast.success("Your password has been reset successfully!");
      navigate("/");
    }
  };

  useEffect(() => {
    if (!email) {
      navigate(-1);
    }
  }, []);
  return (
    <div className={classes.resetPassword}>
      <div className={classes.header}>
        <Container>
          <Row>
            <Col>
              <div className={classes.imgWrapper}>
                {/* <img src={Logo} /> */}
                <h3>Demo</h3>
              </div>
            </Col>
            {/* <Col>
              <div className={classes.language}>
                <DropDown
                  value={dropdown}
                  setter={setDropdown}
                  options={options}
                  isSearchable={false}
                  placeholder={"Select Language"}
                />
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
      <div className={classes.formWrapper}>
        <div className={classes.form}>
          <Button
            leftIcon={<IoArrowBackSharp />}
            variant=""
            onClick={() => navigate("/")}
            className={classes.backBtn}
          >
            {" "}
            Back to Login
          </Button>
          <div className={classes.iconWrapper}>
            <img src={resetIcon} />
          </div>
          <>
            <div className={classes.content}>
              <h5> Reset Password </h5>
              <p className={"muted"}>Enter your new password</p>
            </div>
            <div className={classes.inputWrapper}>
              <Input
                value={newPassword}
                setValue={setNewPassword}
                placeholder={"New password"}
                type={"password"}
              />
              <Input
                value={confirmNewPassword}
                setValue={setConfirmNewPassword}
                placeholder={"Confirm Password"}
                type={"password"}
              />
              <Button
                onClick={submitHandler}
                className={classes.btn}
                disabled={isLoading}
              >
                {isLoading ? "Resetting..." : "Reset Password"}
              </Button>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
