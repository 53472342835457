/* eslint-disable */

import React, { useState, useEffect } from "react";
import classes from "./PrivacyPolicy.module.css";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "../../Component/Button/Button";
import { BaseURL, apiHeader, languagesKeysObject } from "../../config/apiUrl";
import { Get, Patch } from "../../Axios/AxiosFunctions";
import { toast } from "react-toastify";
import { Loader } from "../../Component/Loader";
import QuillInput from "../../Component/QuillInput";
import { languagesOptions } from "../../constant/commonData";
import FormLanguages from "../../Component/FormLanguages";

const PrivacyPolicy = () => {
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const [language, setLanguage] = useState(languagesOptions?.find((e) => e?.value == selectedLanguage));
  const { accessToken } = useSelector((state) => state.authReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [data, setData] = useState(null);

  const getData = async () => {
    const url = BaseURL("privacy-policy");
    setIsLoading(true);
    const response = await Get(url, accessToken);
    setIsLoading(false);

    if (response) {
      setData(response?.data?.data);
    }
  };
  useEffect(() => {
    getData();
  }, []);

console.log(data)

  const handleSubmit = async () => {
    const url = BaseURL(`privacy-policy/${data?._id}`);
    const params = {
      content: data?.content,
    };

    for (let lang in languagesKeysObject) {
      for (let key in params) {
        if (!params[key]?.[lang] || params[key]?.[lang] == "<p><br></p>") {
          return toast.error(
            `Please fill the ${key} in language: ${capitalizeFirstLetter(lang)}`
          );
        }
      }
    }

    setBtnLoading(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    if (response) {
      toast.success("Privacy Policy updated successfully");
    }
    setBtnLoading(false);
  };

  return (
    <SideBarSkeleton>
      <Container className={classes.main}>
        {isLoading ? (
          <Loader />
        ) : (
          <Row className={classes.row}>
            <Col md={12} className={classes?.spBtw}>
              <h5>Privacy Policy</h5>
              <FormLanguages
                value={language}
                setter={setLanguage}
              />
            </Col>
            <Col md={12}>
              <QuillInput
                setter={(e) =>
                  setData((prev) => ({
                    ...prev,
                    content: { ...prev?.content, [language?.value]: e },
                  }))
                }
                value={data?.content?.[language?.value]}
                quillClass={classes.quillInput}
              />
            </Col>

            <Col md={12}>
              <Button
                label={btnLoading ? "Wait..." : "Submit"}
                onClick={handleSubmit}
              />
            </Col>
          </Row>
        )}
      </Container>
    </SideBarSkeleton>
  );
};

export default PrivacyPolicy;
